import {
  faCircleMinus,
  faCirclePlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Button, { ButtonTypes } from '../../components/button';
import Dropdown from '../../components/dropdown';
import Input from '../../components/input';
import InputAutoFill from '../../components/inputAutoFill';
import { EVALUATING_ROLES } from '../../config/permissions/roles';
import JurorList from '../../models/jurorList';
import Session from '../../models/session';
import SubmissionGroup from '../../models/submissionGroup';
import { matchRoles } from '../../helpers/roles';
import styles from './styles.module.scss';

const MAX_JURORS_PER_GROUP = 4;

const INITIAL_JUROR = {
  name: '',
  jurorId: '',
  role: '',
};

const GroupsForm = ({ onSubmit, onCancel, type, group, sessionId }) => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const [sessions, setSessions] = useState([]);
  const [submissionGroups, setSubmissiongroups] = useState([]);
  const [jurorSuggestions, setJurorSuggestions] = useState({
    field: '',
    jurors: [],
  });
  const [jurorSuggestionsLoading, setJurorSuggestionsLoading] = useState(true);

  const roles = useMemo(
    () =>
      Object.values(EVALUATING_ROLES).map((role) => ({
        value: role,
        text: t(`roles.${role}`),
      })),
    []
  );

  const fetchJurors = async (query, field = '') => {
    setJurorSuggestionsLoading(true);
    const { jurors } = await JurorList.fetchQuery(query);
    setJurorSuggestions({
      field,
      jurors: jurors.map((j) => ({
        id: j.jurorId,
        value: `${j.firstname} ${j.lastname}`,
      })),
    });
    setJurorSuggestionsLoading(false);
  };

  useEffect(() => {
    SubmissionGroup.fetchAll({ assigned: false }).then((submissionGroups) =>
      setSubmissiongroups(
        submissionGroups.map((group) => ({
          id: group.id,
          value: group.name,
        }))
      )
    );
    Session.fetchAll({ archived: false }).then((sessions) => {
      setSessions(
        sessions.map((session) => ({
          value: session.sessionId,
          text: session.sessionName,
        }))
      );
    });
  }, [setSubmissiongroups, setSessions]);

  const initialJurorValues = useMemo(() => {
    let jurors =
      group?.jurors.filter((juror) =>
        matchRoles(juror.role, Object.values(EVALUATING_ROLES))
      ) || [];
    jurors = jurors.map((juror) => ({
      name: `${juror.firstname} ${juror.lastname}`,
      jurorId: juror.jurorId,
      role: juror.role,
    }));

    return jurors.length ? jurors : [INITIAL_JUROR];
  }, [group]);

  const formik = useFormik({
    initialValues: {
      jurorGroupName: group?.jurorGroupName || '',
      submissionGroup: {
        name: group?.submissionGroupName || '',
        id: group?.submissionGroupId || '',
      },
      jurors: initialJurorValues,
      sessionId: sessionId || state?.sessionId || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const submitData = {
        jurorGroupName: values.jurorGroupName,
        submissionGroupId: values.submissionGroup.id,
        jurors: values.jurors.map((juror) => ({
          jurorId: juror.jurorId,
          role: juror.role,
        })),
      };
      onSubmit(submitData, values.sessionId);
    },
  });

  const handleEntryPackageChange = (value) => {
    const submissionGroup = submissionGroups.find(
      (group) => group.value === value
    );
    formik.setFieldValue('submissionGroup', {
      name: value,
      id: submissionGroup ? submissionGroup.id : '',
    });
  };

  const handleJurorChange = (value, index) => {
    const juror = jurorSuggestions.jurors.find((j) => j.value === value);
    formik.setFieldValue(`jurors[${index}]`, {
      ...formik.values.jurors[index],
      name: value,
      jurorId: juror ? juror.id : '',
    });
    fetchJurors(value, index);
  };

  const handleJurorRoleChange = (value, juror, index) =>
    formik.setFieldValue(`jurors[${index}]`, {
      ...juror,
      role: value,
    });

  const handleJurorFieldClick = (index) => {
    fetchJurors(formik.values.jurors[index].name);
  };

  const handleAddJuror = () => {
    formik.setFieldValue(`jurors`, [...formik.values.jurors, INITIAL_JUROR]);
  };

  const handleRemoveJuror = (index) => {
    const newJurors = [...formik.values.jurors];
    newJurors.splice(index, 1);
    formik.setFieldValue('jurors', newJurors);
  };

  return (
    <div className={styles.groupForm}>
      <form onSubmit={formik.handleSubmit}>
        {type !== 'edit' && (
          <>
            <Row className="justify-content-md-center">
              <Col>
                <Input
                  name="jurorGroupName"
                  label={t('jurors.form.groupTitle')}
                  required
                  value={formik.values.jurorGroupName}
                  onChange={formik.handleChange}
                  readOnly={type === 'edit'}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col>
                <Dropdown
                  name="sessionId"
                  label={t('jurors.form.selectSession')}
                  options={sessions}
                  value={formik.values.sessionId}
                  onRawChange={formik.handleChange}
                  required
                  readOnly={type === 'edit' || !!state?.sessionId}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col>
                <InputAutoFill
                  label={t('jurors.form.selectEntryPackage')}
                  options={submissionGroups}
                  optionsLoading={submissionGroups.length === 0}
                  value={formik.values.submissionGroup.name}
                  onChange={handleEntryPackageChange}
                  required
                  handleFilter
                  readOnly={type === 'edit'}
                />
              </Col>
            </Row>
          </>
        )}
        {formik.values.jurors.map((juror, index) => (
          <Row key={index} className="justify-content-md-center">
            <Col>
              <InputAutoFill
                label={t('jurors.form.juror')}
                options={
                  jurorSuggestions.field === index
                    ? jurorSuggestions.jurors
                    : []
                }
                optionsLoading={jurorSuggestionsLoading}
                value={juror.name}
                onChange={(v) => {
                  handleJurorChange(v, index);
                }}
                onClick={() => {
                  handleJurorFieldClick(index);
                }}
                required
              />
            </Col>
            <Col>
              <Dropdown
                name="jurorRole"
                label={t('jurors.form.jurorRole')}
                value={juror.role}
                options={roles}
                onChange={(value) => {
                  handleJurorRoleChange(value, juror, index);
                }}
                reuired
              />
            </Col>
            <Col className={styles.deleteJurorColumn}>
              <button
                className={styles.deleteButton}
                type="button"
                disabled={formik.values.jurors.length === 1}
                onClick={() => {
                  handleRemoveJuror(index);
                }}
              >
                <FontAwesomeIcon icon={faCircleMinus} className={styles.icon} />
              </button>
            </Col>
          </Row>
        ))}
        <Row className={styles.addJurorRow}>
          <Col>
            {formik.values.jurors.length >= MAX_JURORS_PER_GROUP ? (
              <span className={styles.maxJurorsMessage}>
                {t('jurors.form.maxNumReached')}
              </span>
            ) : (
              <button
                className={styles.addButton}
                type="button"
                onClick={() => {
                  handleAddJuror();
                }}
              >
                <FontAwesomeIcon icon={faCirclePlus} className={styles.icon} />
              </button>
            )}
          </Col>
        </Row>
        <Row>
          <Col className={styles.buttonRow}>
            <Button
              text={t('sessions.meetings.form.buttons.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={onCancel}
              htmlType="button"
            />
            <Button
              text={t('sessions.meetings.form.buttons.submit')}
              type={ButtonTypes.PRIMARY}
              htmlType="submit"
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default GroupsForm;

GroupsForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.string,
  group: PropTypes.object,
  sessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GroupsForm.defaultProps = {
  onSubmit: () => null,
  onCancel: () => null,
  type: '',
  group: null,
  sessionId: '',
};
