import { icon, toHtml } from '@fortawesome/fontawesome-svg-core';
import {
  faThumbTack,
  faPencil,
  faCreditCard,
  faBlender,
  faSuitcase,
  faBabyCarriage,
  faCar,
  faHouse,
  faRocket,
} from '@fortawesome/pro-light-svg-icons';

export const getSVGURI = (faIcon, color) => {
  const abstract = icon(faIcon).abstract[0];
  if (color) abstract.children[0].attributes.fill = color;
  return `data:image/svg+xml;base64,${btoa(toHtml(abstract))}`;
};

export const SIZING_MATRIX_ICONS = {
  'thumbtack': faThumbTack,
  'pencil': faPencil,
  'credit-card': faCreditCard,
  'blender': faBlender,
  'suitcase': faSuitcase,
  'baby-carriage': faBabyCarriage,
  'car': faCar,
  'house': faHouse,
  'rocket': faRocket,
};
