import { faEmptySet } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { combine } from '../../helpers/styles';
import { useJuror } from '../../hooks/useAuth';
import Submission from '../../models/submission';
import Avatar from '../avatar';
import Loading from '../loading';
import styles from './styles.module.scss';
import { EVALUATING_ROLES, ROLES } from '../../config/permissions/roles';

const ScoreTable = ({ submissionId }) => {
  const { t } = useTranslation();
  const [submission, setSubmission] = useState([]);
  const [loading, setLoading] = useState(true);
  const { jurorGroup } = useJuror();

  useEffect(() => {
    if (submissionId && jurorGroup) {
      const fetchPoints = async () => {
        const data = await Submission.getPoints(
          jurorGroup.jurorGroupId,
          submissionId
        );
        setSubmission(data);
        setLoading(false);
      };

      fetchPoints();
    }
  }, [submissionId]);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th></th>
          <th>
            <FontAwesomeIcon icon={faEmptySet} className={styles.averageIcon} />{' '}
            {t('evaluation.criteria.SCORE')}
          </th>
          <th>{t('evaluation.criteria.IDEA.title')}</th>
          <th>{t('evaluation.criteria.FORM.title')}</th>
          <th>{t('evaluation.criteria.FUNCTION.title')}</th>
          <th>{t('evaluation.criteria.DIFFERENCLASTION.title')}</th>
          <th>{t('evaluation.criteria.IMPACT.title')}</th>
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr className={combine(styles.tr, styles.loading)}>
            <td colSpan={7}>
              <Loading />
            </td>
          </tr>
        )}
        {submission?.jurors
          ?.filter((juror) =>
            [ROLES.juror, ROLES.groupLeader].includes(juror.role)
          )
          ?.map((juror) => (
            <tr key={juror.jurorId} className={styles.tr}>
              <td>
                <Avatar
                  name={`${juror.firstname} ${juror.lastname}`}
                  img={juror.profilePictureUrl}
                  role={juror.role}
                />
              </td>
              <td>
                {juror?.points.reduce((a, b) => a + b.points, 0).toFixed() || 0}
              </td>
              <td>{juror?.points?.[0]?.points || 0}</td>
              <td>{juror?.points?.[1]?.points || 0}</td>
              <td>{juror?.points?.[2]?.points || 0}</td>
              <td>{juror?.points?.[3]?.points || 0}</td>
              <td>{juror?.points?.[4]?.points || 0}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

ScoreTable.propTypes = {
  submissionId: PropTypes.number.isRequired,
};

ScoreTable.defaultProps = {
  submissionId: null,
};

export default ScoreTable;
