import { AWARD_TYPES } from '../../models/award';
import IFDABadge from './ifda';
import IFDTABadge from './ifdta';
import SIPBadge from './sip';
import pt from 'prop-types';

const AwardBadge = ({ awardType, year, className }) => {
  switch (awardType) {
    case AWARD_TYPES.IF_SIP:
      return <SIPBadge year={year} className={className} />;
    case AWARD_TYPES.IF_DA:
      return <IFDABadge year={year} className={className} />;
    case AWARD_TYPES.IF_DTA:
      return <IFDTABadge year={year} className={className} />;
    default:
      return <IFDABadge year={year} className={className} />;
  }
};

AwardBadge.propTypes = {
  awardType: pt.oneOf(Object.values(AWARD_TYPES)).isRequired,
  year: pt.number,
  className: pt.string,
};

AwardBadge.defaultProps = {
  className: '',
  year: 2023,
};

export default AwardBadge;
