import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { getAverageScore } from '../../../../helpers/statistic';
import { useJuror } from '../../../../hooks/useAuth';
import { ROLES } from '../../../../config/permissions/roles';
import { AWARD_ROLE, GROUP_ROLE } from '../../../../models/evaluationStatistic';
import styles from './styles.module.scss';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

const getOptions = ({ displayTicks }) => ({
  maintainAspectRatio: false,
  responsive: true,
  animation: false,
  events: [],
  layout: {
    padding: {
      top: 20,
    },
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
      clip: false,
      color: '#000000',
      font: {
        size: '16px',
        lineHeight: '24px',
        weight: 'bold',
      },
      offset: -4,
    },
  },
  scales: {
    y: {
      stacked: true,
      display: true,
      position: 'right',
      grace: '20%',
      min: 0,
      max: 500,
      grid: {
        drawBorder: false,
        drawTicks: false,
        color: '#f5f5f5',
      },
      ticks: {
        display: true,
        count: 5,
        padding: 10,
        color: displayTicks ? '#000000' : 'transparent',
      },
    },
    x: {
      stacked: true,
      categoryPercentage: 1.0,
      barPercentage: 1.0,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
});

const MAX_SCORE = 500;

const ScoreStatistic = ({ statistic }) => {
  const { t } = useTranslation();
  const { jurorGroup, session } = useJuror();

  const jurorStats = useMemo(() => {
    if (!statistic) return [];

    return statistic.jurors
      .filter(
        (stats) =>
          stats.role === ROLES.juror || stats.role === ROLES.groupLeader
      )
      .map((stats) => ({
        ...stats,
        averageScore: getAverageScore(
          stats.decidedEvaluationCount,
          stats.awardedPoints
        ),
      }));
  }, [statistic]);

  const groupScore = useMemo(() => {
    if (!statistic) return 0;

    const groupStats = statistic.jurors.find(
      (stats) => stats.role === GROUP_ROLE
    );
    return getAverageScore(
      groupStats.decidedEvaluationCount,
      groupStats.awardedPoints
    );
  }, [statistic]);

  const awardScore = useMemo(() => {
    if (!statistic) return 0;

    const awardStats = statistic.jurors.find(
      (stats) => stats.role === AWARD_ROLE
    );

    return getAverageScore(
      awardStats.decidedEvaluationCount,
      awardStats.awardedPoints
    );
  }, [statistic]);

  const jurorData = {
    labels: jurorStats.map((stats) => stats.jurorId),
    datasets: [
      {
        label: 'Juror Scores',
        data: jurorStats.map((stats) => stats.averageScore),
        backgroundColor: '#5a697b',
        barThickness: 10,
      },
      {
        label: 'Juror Scores Inverted',
        data: jurorStats.map((stats) => MAX_SCORE - stats.averageScore),
        backgroundColor: '#efefef',
        barThickness: 10,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const groupData = {
    labels: ['group', 'award'],
    datasets: [
      {
        label: 'Group Score',
        data: [groupScore, awardScore],
        backgroundColor: '#5a697b',
        barThickness: 10,
      },
      {
        label: 'Group Score inverted',
        data: [MAX_SCORE - groupScore, MAX_SCORE - awardScore],
        backgroundColor: '#efefef',
        barThickness: 10,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  return (
    <div className={styles.container}>
      <div className={styles.chartsContainer}>
        <div className={styles.jurorChart}>
          <Bar
            options={getOptions({
              displayTicks: false,
            })}
            data={jurorData}
          />
        </div>
        <div className={styles.groupChart}>
          <Bar
            options={getOptions({
              displayTicks: true,
            })}
            data={groupData}
          />
        </div>
      </div>
      <div className={styles.labelContainer}>
        <ul className={styles.jurorLabels}>
          {jurorStats.map((stat) => (
            <li key={stat.jurorId} className={styles.label}>
              <div className={styles.imageContainer}>
                {stat.profilePictureUrl ? (
                  <img
                    className={styles.image}
                    src={stat.profilePictureUrl}
                    alt="juror-profile-picture"
                  />
                ) : (
                  <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faUser} className={styles.icon} />
                  </div>
                )}
              </div>
              <span
                className={styles.name}
              >{`${stat.firstname} ${stat.lastname}`}</span>
              <span className={styles.role}>{t(`roles.${stat.role}`)}</span>
            </li>
          ))}
        </ul>
        <div className={styles.groupLabels}>
          <div className={styles.groupLabel}>
            <div className={styles.imageContainer}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon={faUserGroup} className={styles.icon} />
              </div>
            </div>
            <span className={styles.name}>{jurorGroup?.jurorGroupName}</span>
          </div>
          <div className={styles.groupLabel}>
            <div className={styles.imageContainer}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon={faUserGroup} className={styles.icon} />
              </div>
            </div>
            <span className={styles.name}>{session?.awardName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreStatistic;
