export const CONTACTS = {
  gabriele: {
    name: 'Gabriele Bertemann',
    email: 'gabriele.bertemann@ifdesign.com',
    phone: '+49 511 54224-202',
    website: 'www.ifdesign.com',
  },
  franziska: {
    name: 'Franziska Schäffner',
    email: 'franziska.schaeffner@ifdesign.com',
    phone: '+49 511 54224-221',
  },
  frank: {
    name: 'Frank Zierenberg',
    email: 'frank.zierenberg@ifdesign.com',
    phone: '+49 511 54224-216',
  },
  andrea: {
    name: 'Andrea van Velzen',
    email: 'andrea.van-velzen@ifdesign.com',
    phone: '+49 511 54224-217',
  },
  heike: {
    name: 'Heike Meier',
    email: 'heike.meier@ifdesign.com',
    phone: '+49 511 54224-203',
  },
};
