import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ProgressBar = ({
  color,
  value,
  withMarker,
  backgroundColor,
  markerColor,
  markerWidth,
}) => {
  const progress =
    typeof value === 'string'
      ? `${Number(value.replace(/\D/g, ''))}%`
      : `${value}%`;
  const progressStyles = {};
  const progressBarStyles = {};
  const markerStyles = {};

  if (backgroundColor) {
    progressBarStyles['--bgColor'] = backgroundColor;
  }

  if (progress) {
    progressStyles['--progress'] = progress;
  }

  if (color) {
    progressStyles['--color'] = color;
  }

  if (withMarker && markerColor) {
    markerStyles['--color'] = markerColor;
  }

  if (withMarker && markerWidth) {
    markerStyles['width'] = markerWidth;
  }

  return (
    <div style={progressBarStyles} className={styles.progressBar}>
      <div style={progressStyles} className={styles.progress}></div>
      {withMarker && <div style={markerStyles} className={styles.marker}></div>}
    </div>
  );
};

ProgressBar.propTypes = {
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withMarker: PropTypes.bool,
  backgroundColor: PropTypes.string,
  markerColor: PropTypes.string,
  markerWidth: PropTypes.string,
};

export default ProgressBar;
