import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { ROLES } from '../../../config/permissions/roles';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import { matchRoles } from '../../../helpers/roles';
import styles from './styles.module.scss';

const EntryNavigation = ({ submission, entryLoading, submissionsList }) => {
  const { jurorId: impersonatingJurorId } = useParams();
  const [nextEntry, setNextEntry] = useState(null);
  const [prevEntry, setPrevEntry] = useState(null);
  const [prevEntryLink, setPrevEntryLink] = useState(null);
  const [nextEntryLink, setNextEntryLink] = useState(null);
  const totalEntryCount = submissionsList?.length;
  const { role } = useJuror();
  const [searchParams, setSearchParams] = useSearchParams();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!submission || !submissionsList || submissionsList.length === 0) return;

    const currentIndex = submissionsList.findIndex(
      (s) => s.submissionId === submission.submissionId
    );

    setCurrent(currentIndex + 1);
    if (currentIndex > 0) {
      setPrevEntry(submissionsList[currentIndex - 1]);
    } else {
      setPrevEntry(null);
    }

    if (currentIndex < submissionsList.length - 1) {
      setNextEntry(submissionsList[currentIndex + 1]);
    } else {
      setNextEntry(null);
    }
  }, [setNextEntry, setPrevEntry, submission, submissionsList]);

  useEffect(() => {
    if (
      matchRoles(role, [ROLES.chairPerson, ROLES.staff]) &&
      impersonatingJurorId
    ) {
      setPrevEntryLink(
        `../../${prevEntry?.submissionId}/${impersonatingJurorId}`
      );
      setNextEntryLink(
        `../../${nextEntry?.submissionId}/${impersonatingJurorId}`
      );
    } else {
      setPrevEntryLink(`../${prevEntry?.submissionId}`);
      setNextEntryLink(`../${nextEntry?.submissionId}`);
    }
  }, [nextEntry, prevEntry]);

  return (
    <Container fluid className={styles.topNavigationWrapper}>
      <Container className={styles.topNavigation}>
        <Link
          className={combine(styles.left, !prevEntry && styles.hidden)}
          disabled={entryLoading}
          to={{
            pathname: prevEntryLink,
            search: `?${searchParams}`,
          }}
          state={{
            submissionsList,
            jurorGroupId: prevEntry?.evaluations?.[0]?.jurorGroupId,
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} className={styles.topNavIcon} />
          {t('evaluation.navigation.previous')}
        </Link>
        <div className={styles.center}>
          <Link
            className={styles.overviewLink}
            to={{
              pathname: `/juror/entries`,
              search: `?${searchParams}`,
            }}
            state={{ submissionId: submission?.submissionId }}
          >
            {t('evaluation.navigation.back')}{' '}
            {totalEntryCount ? `(${current}  / ${totalEntryCount})` : ''}
          </Link>
        </div>
        <Link
          className={combine(styles.right, !nextEntry && styles.hidden)}
          disabled={entryLoading}
          to={{
            pathname: nextEntryLink,
            search: `?${searchParams}`,
          }}
          state={{
            submissionsList,
            jurorGroupId: nextEntry?.evaluations?.[0]?.jurorGroupId,
          }}
        >
          {t('evaluation.navigation.next')}
          <FontAwesomeIcon icon={faAngleRight} className={styles.topNavIcon} />
        </Link>
      </Container>
    </Container>
  );
};

EntryNavigation.propTypes = {
  submission: PropTypes.object,
  submissionsList: PropTypes.array,
  entryLoading: PropTypes.bool,
};

EntryNavigation.defaultProps = {
  submission: {},
  submission: [],
  entryLoading: false,
};

export default EntryNavigation;
