import moment from 'moment/moment';

export const getFormattedTime = (date) => moment(date).format('HH:mm');

export const getFormattedDate = (date) => moment(date).format('DD. MMM YY');

export const isToday = (date) => moment().isSame(moment(date), 'date');

export const isSessionClosed = (endDate) => {
  if (!endDate) return false;

  const sessionEndDateWithPadding = moment(endDate).add(1, 'd').set('h', 0);

  return moment().isAfter(sessionEndDateWithPadding);
};
