import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import CompanyCard from './companyCard';
import styles from './styles.module.scss';
import TableRow from './tableRow';

const EntryDetailFooter = ({ submission }) => (
  <Container>
    <Row className={styles.additionalInformation}>
      <Col xs={12} sm={6}>
        <h3>{t('Details')}</h3>
        {submission?.fields?.client_manufacturer?.length > 0 && (
          <span className={styles.companyGroupTitle}>
            Client / Manufacturer
          </span>
        )}
        {submission?.fields?.client_manufacturer?.map((company) => (
          <CompanyCard
            key={company.companyName}
            name={company.companyName}
            city={company.city}
            countryCode={company?.countryIsoCode}
            image={company.companyLogoUrl}
            profileId={company.profileId}
          />
        )) ||
          submission?.fields?.submittingOrganization?.map((company) => (
            <CompanyCard
              key={company.companyName}
              name={company.companyName}
              city={company.city}
              image={company.companyLogoUrl}
              profileId={company.profileId}
            />
          ))}
        {submission?.fields?.designer?.length > 0 && (
          <span className={styles.companyGroupTitle}>Designer</span>
        )}
        {submission?.fields?.designer?.map((designer) => (
          <CompanyCard
            key={designer.companyName}
            name={designer.companyName}
            city={designer.city}
            countryCode={designer?.countryIsoCode}
            image={designer.companyLogoUrl}
            profileId={designer.profileId}
            freeText={designer?.freeText}
          />
        ))}
      </Col>
      <Col xs={12} sm={6} className={styles.tagContainer}>
        {submission?.fields?.projectLaunch && (
          <TableRow
            label="Date of Launch"
            text={submission?.fields?.projectLaunch}
          />
        )}
        {submission?.fields?.projectDevelopmentTime && (
          <TableRow
            label="Development Time"
            text={submission?.fields?.projectDevelopmentTime}
          />
        )}
        {submission?.fields?.marketRegion && (
          <TableRow
            label="Target Regions"
            text={submission?.fields?.marketRegion?.join(', ')}
          />
        )}
        {submission?.fields?.targetGroup && (
          <TableRow
            label="Target Groups"
            text={submission?.fields?.targetGroup?.join(', ')}
          />
        )}
        {submission?.fields?.retailPrice && (
          <TableRow
            label="Retail Price (EUR)"
            text={submission?.fields?.retailPrice}
          />
        )}
        {submission?.fields?.realizationDegree && (
          <TableRow
            label="Degree of Realization"
            text={submission?.fields?.realizationDegree}
          />
        )}
        {submission?.fields?.moreEnergyEfficient && (
          <TableRow
            label="Improved Energy Efficiency"
            text={submission?.fields?.moreEnergyEfficient}
          />
        )}
        {submission?.fields?.fairWages && (
          <TableRow
            label="Social Standards in Production"
            text={submission?.fields?.fairWages}
          />
        )}
        {submission?.fields?.recyclingPercentage && (
          <TableRow
            label="Recycled Materials"
            text={submission?.fields?.recyclingPercentage}
          />
        )}
        {submission?.fields?.ecoLabel?.length && (
          <TableRow
            label="Eco Label"
            text={submission.fields.ecoLabel?.reduce(
              (string, label) =>
                string === '' ? label.name : `${string}, ${label.name}`,
              ''
            )}
          />
        )}
      </Col>
    </Row>
  </Container>
);

EntryDetailFooter.propTypes = {
  submission: PropTypes.object.isRequired,
};

export default EntryDetailFooter;
