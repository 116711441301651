import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styles from './styles.module.scss';

const TableRow = ({ label, text }) => (
  <Row className={styles.tagRow}>
    <Col xs={4} className={styles.tagKey}>
      {label}
    </Col>
    <Col xs={8} className={styles.tagValue}>
      {text}
    </Col>
  </Row>
);

TableRow.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TableRow;
