import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { combine } from '../../helpers/styles';
import ControlSection from './controlSection';
import styles from './styles.module.scss';

const Hero = ({ title, headline, bigTitle, multiLineTitle, rightSection }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Container
      fluid
      className={combine(styles.hero, multiLineTitle && styles.multiLine)}
    >
      <Container>
        <Row className={styles.heroContainer}>
          <Col xs={12} md={8} sm={6} className={styles.wrapper}>
            <div className={styles.heroLeft}>
              <span className={styles.headline}>{headline}</span>
              {!multiLineTitle ? (
                <h1
                  className={combine(styles.title, bigTitle && styles.bigTitle)}
                >
                  {title}
                </h1>
              ) : (
                <h1
                  className={combine(styles.title, bigTitle && styles.bigTitle)}
                >
                  {title.firstRow}
                  {!isMobile && (
                    <>
                      <br />
                      {title.secondRow}
                    </>
                  )}
                </h1>
              )}
            </div>
            <div className={styles.rightSection}>{rightSection}</div>
          </Col>
          <Col xs={12} md={4} sm={6} className={styles.heroRight}>
            <ControlSection />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

Hero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headline: PropTypes.string,
  bigTitle: PropTypes.bool,
  multiLineTitle: PropTypes.bool,
  rightSection: PropTypes.node,
};

Hero.defaultProps = {
  title: '',
  headline: '',
  bigTitle: false,
  multiLineTitle: false,
  rightSection: null,
};

export default Hero;
