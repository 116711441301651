import {
  faCaretLeft,
  faCaretRight,
  faClose
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal } from 'react-bootstrap';
import styles from './styles.module.scss';

const FullScreenImage = ({ image, onClose, carousel }) => {
  const [active, setActive] = useState(-1);
  const imageRef = useRef(null);

  useEffect(() => {
    if (!!carousel) {
      setActive(carousel.findIndex((item) => item?.url === image));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key) {
        onClose();
      }
    });
  }, [imageRef]);
  const handleBackdropClick = (e) => {
    if (e.target !== e.currentTarget) {
      return false;
    }

    return onClose();
  };

  const handlePrev = () => {
    if (imageRef?.current) {
      imageRef.current.src = '';
    }
    if (carousel[active - 1]) {
      return setActive(Number(active - 1));
    }
    return setActive(Number(carousel?.length - 1));
  };

  const handleNext = () => {
    if (imageRef?.current) {
      imageRef.current.src = '';
    }
    if (carousel[active + 1]) {
      return setActive(Number(active + 1));
    }
    return setActive(Number(0));
  };

  return (
    <Container
      fluid
      className={styles.fullScreenImage}
      onClick={handleBackdropClick}
    >
      <Col xs={12} sm={10}>
        <Modal.Dialog
          className={styles.dialog}
          contentClassName={styles.dialogWrapper}
        >
          <div className={styles.closeButton} onClick={() => onClose()}>
            <FontAwesomeIcon className={styles.closeIcon} icon={faClose} />
          </div>
          {carousel && (
            <>
              <div className={styles.prev} onClick={handlePrev}>
                <FontAwesomeIcon
                  className={styles.closeIcon}
                  icon={faCaretLeft}
                />
              </div>
              <div className={styles.next} onClick={handleNext}>
                <FontAwesomeIcon
                  className={styles.closeIcon}
                  icon={faCaretRight}
                />
              </div>
            </>
          )}
          <Col xs={12} className={styles.imageContainer}>
            <img
              ref={imageRef}
              className={styles.imageElement}
              src={carousel?.[active]?.url || image}
              style={{
                backgroundImage: `url(${
                  carousel?.[active]?.thumbnailUrl || image
                })`,
              }}
            />
          </Col>
        </Modal.Dialog>
      </Col>
    </Container>
  );
};

FullScreenImage.propTypes = {
  image: PropTypes.string,
  carousel: PropTypes.array,
  onClose: PropTypes.func,
};

FullScreenImage.defaultProps = {
  image: 'https://picsum.photos/1920/1080',
  carousel: null,
  onClose: () => null,
};

export default FullScreenImage;
