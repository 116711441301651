import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../../config/permissions/roles';
import { useAuth, useJuror } from '../../hooks/useAuth';
import styles from './styles.module.scss';

const DebugToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div className={styles.debugToggle}>
    <FontAwesomeIcon
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      size="xl"
      className={styles.debugIcon}
      icon={faRobot}
    />
  </div>
));

const debug = process.env.REACT_APP_DEBUG;

const Debug = () => {
  const navigate = useNavigate();
  const { signout } = useAuth();
  const { updateRole, clearJurorAuth } = useJuror();
  const [cookies, setCookie, removeCookie] = useCookies(['no-rerender']);

  const handleStaff = async () => {
    removeCookie('rememberMe');
    removeCookie('JSESSIONID', { path: '/' });

    clearJurorAuth();
    navigate('/admin/dashboard');
  };

  const handleJuror1 = () => {
    signout(() => {
      navigate('/juror/9fa1738a-6aec-465e-aab2-5ee93b4146a5');
      navigate(0);
    });
  };

  const handleJuror2 = () => {
    signout(() => {
      navigate('/juror/7af615d1-7e5d-4a69-b09e-828242a9afdb');
      navigate(0);
    });
  };

  const handleGroupLeader = () => {
    signout(() => {
      navigate('/juror/0896e474-0309-499d-8466-cf5b0312980a');
      navigate(0);
    });
  };

  const handleChairPerson = () => {
    signout(() => {
      navigate('/juror/0076ec49-4ec2-493f-bc24-b8e707c0677b');
      navigate(0);
    });
  };

  const changeRole = (role) => {
    return updateRole(role);
  };

  return (
    <>
      {debug && (
        <Dropdown className={styles.debugContainer} align={'end'} flip={'true'}>
          <Dropdown.Toggle as={DebugToggle} />

          <Dropdown.Menu className={styles.debugMenu}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  as={Dropdown.Header}
                  className={styles.debugMenuAccordionHeader}
                >
                  POV
                </Accordion.Header>
                <Accordion.Body className={styles.debugMenuAccordionBody}>
                  <Dropdown.Item onClick={handleStaff}>Staff</Dropdown.Item>
                  <Dropdown.Item onClick={handleJuror1}>Juror 1</Dropdown.Item>
                  <Dropdown.Item onClick={handleJuror2}>Juror 2</Dropdown.Item>
                  <Dropdown.Item onClick={handleGroupLeader}>
                    Group Leader
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleChairPerson}>
                    Chair Person
                  </Dropdown.Item>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header
                  as={Dropdown.Header}
                  className={styles.debugMenuAccordionHeader}
                >
                  Roles
                </Accordion.Header>
                <Accordion.Body className={styles.debugMenuAccordionBody}>
                  {Object.keys(ROLES).map((role) => (
                    <Dropdown.Item
                      key={ROLES[role]}
                      onClick={() => changeRole(ROLES[role])}
                    >
                      {ROLES[role]}
                    </Dropdown.Item>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default Debug;
