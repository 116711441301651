class GroupMember {
  constructor(
    jurorId,
    firstname,
    lastname,
    profilePictureUrl,
    role,
    totalEvaluationCount,
    decidedEvaluationCount,
    totalPointsFromAllDecidedEvaluations
  ) {
    this.jurorId = jurorId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.profilePictureUrl = profilePictureUrl;
    this.role = role;
    this.totalEvaluationCount = totalEvaluationCount;
    this.decidedEvaluationCount = decidedEvaluationCount;
    this.totalPointsFromAllDecidedEvaluations =
      totalPointsFromAllDecidedEvaluations;
  }

  static from = (groupMember) => {
    return new GroupMember(
      groupMember.jurorId,
      groupMember.firstname,
      groupMember.lastname,
      groupMember.profilePictureUrl,
      groupMember.role,
      groupMember.totalEvaluationCount,
      groupMember.decidedEvaluationCount,
      groupMember.totalPointsFromAllDecidedEvaluations
    );
  };

  static of = (groupMembers) => {
    return groupMembers?.map((groupMember) => GroupMember.from(groupMember));
  };

  static fetchForJurorGroup = async (jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/jurors`)
      .then((response) => response.json())
      .then((groupMembers) => GroupMember.of(groupMembers))
      .catch((err) => console.error(err));
  };
}

export default GroupMember;
