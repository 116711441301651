import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight as faArrowRightBold } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getFormattedDate,
  getFormattedTime,
  isToday,
} from '../../helpers/date';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

const MeetingsList = ({ isLoading, meetings, className }) => {
  const [hoverItem, setHoverItem] = useState(null);
  const { t } = useTranslation();

  const renderPlaceholderItem = (key) => (
    <li
      key={key}
      className={combine(
        styles.placeholderContainer,
        isLoading && 'placeholder-glow'
      )}
    >
      <span
        className={combine(
          styles.placeholderItem,
          isLoading ? 'placeholder col-8' : styles.meetingItem
        )}
      ></span>
    </li>
  );

  return (
    <ul className={combine(styles.meetingsList, className)}>
      {meetings.map((meeting, index) => {
        if (!meeting) {
          return renderPlaceholderItem(index);
        }

        return (
          <li key={meeting.id}>
            <a
              className={styles.meetingItem}
              href={meeting?.url}
              target={'_blank'}
              onMouseEnter={() => {
                setHoverItem(meeting.id);
              }}
              onMouseLeave={() => {
                setHoverItem(null);
              }}
            >
              <div
                className={combine(
                  styles.timeContainer,
                  index === 0 && styles.highlight
                )}
              >
                <span className={styles.date}>
                  {isToday(meeting.startDate)
                    ? t('jurorDashboard.meetings.today')
                    : getFormattedDate(meeting.startDate)}
                </span>
                <span className={styles.time}>
                  {getFormattedTime(meeting.startDate)}
                </span>
              </div>
              <div className={styles.descriptionContainer}>
                <h5 className={styles.name}>{meeting.name}</h5>
                <span className={styles.info}>
                  {t(
                    `jurorDashboard.meetings.${
                      meeting.mandatory ? 'mandatory' : 'optional'
                    }`
                  )}
                </span>
              </div>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  icon={
                    hoverItem === meeting.id ? faArrowRightBold : faArrowRight
                  }
                  className={styles.icon}
                />
              </div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

MeetingsList.propTypes = {
  meetings: PropTypes.array,
  className: PropTypes.string,
};

MeetingsList.defaultProps = {
  meetings: [],
  className: '',
};

export default MeetingsList;
