import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight as faArrowRightBold } from '@fortawesome/pro-regular-svg-icons';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonSizes, ButtonTypes } from '../../../components/button';
import Input from '../../../components/input';
import { clearSessionCookie } from '../../../helpers/cookie';
import { useAuth } from '../../../hooks/useAuth';
import styles from './styles.module.scss';

const Login = () => {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || '/admin/dashboard';



  let auth = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    clearSessionCookie('JSESSIONID');
    if (auth.user) {
      return (
        <Navigate to="/admin/dashboard" state={{ from: location }} replace />
      );
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const { email, password } = values;
      const res = await auth.signin(email, password);
      if (res instanceof Error) {
        setShowErrorAlert(true);
        return;
      }
      navigate(from, { replace: true });
    },
  });

  return (
    <div className={styles.container}>
      {showErrorAlert && (
        <Alert
          className={styles.loginError}
          variant="danger"
          onClose={() => setShow(false)}
        >
          <Alert.Heading>{t('login.error.title')}</Alert.Heading>
          <p>{t('login.error.message')}</p>
        </Alert>
      )}
      <Container className={styles.login}>
        <Col xs={12} md={4}>
          <form onSubmit={formik.handleSubmit} className={styles.loginForm}>
            <h1 className={styles.loginHeadline}>{t('login.title')}</h1>
            <Input
              label={t('login.form.username')}
              type="email"
              name="email"
              required
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <Input
              label={t('login.form.password')}
              type="password"
              name="password"
              required
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <div className={styles.loginButtonContainer}>
              <Button
                type={ButtonTypes.PRIMARY}
                size={ButtonSizes.SMALL}
                icon={faArrowRight}
                iconBold={faArrowRightBold}
                text={t('login.form.button')}
              />
            </div>
          </form>
        </Col>
      </Container>
    </div>
  );
};

export default Login;
