class InformationMedia {
  constructor(
    id,
    title,
    infoText,
    visibleForRole,
    published,
    order,
    pdfs,
    videos,
    sessionId
  ) {
    this.id = id;
    this.title = title;
    this.infoText = infoText;
    this.visibleForRole = visibleForRole;
    this.published = published;
    this.order = order;
    this.pdfs = pdfs;
    this.videos = videos;
    this.sessionId = sessionId;
  }

  static from = (informationMedia) => {
    return new InformationMedia(
      informationMedia.id,
      informationMedia.title,
      informationMedia.infoText || '',
      informationMedia.visibleForRole,
      informationMedia.published,
      informationMedia.order,
      informationMedia.pdfs,
      informationMedia.videos,
      informationMedia.sessionId
    );
  };

  static of = (informationMedia) => {
    return informationMedia?.map((informationMedia) =>
      InformationMedia.from(informationMedia)
    );
  };

  static transformForAPI = (informationMedia) => ({
    title: informationMedia.title,
    infoText: informationMedia.infoText,
    published: informationMedia.published,
    order: informationMedia.order,
    pdfs: informationMedia.pdfs,
    videos: informationMedia.videos,
  });

  static adminFetchItem = async (sessionId, informationMediaId) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/informationMedia/forAdmin/${informationMediaId}`
    )
      .then((response) => response.json())
      .then((informationMedia) =>
        InformationMedia.from({ ...informationMedia, sessionId })
      )
      .catch((err) => console.error(err));
  };

  static create = async (sessionId, informationMedia) => {
    const newInformationMedia =
      InformationMedia.transformForAPI(informationMedia);

    return fetch(
      `/if-api/jury-v2/session/${sessionId}/informationMedia/forAdmin`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newInformationMedia),
      }
    )
      .then((response) => response.json())
      .then((informationMedia) =>
        InformationMedia.from({ ...informationMedia, sessionId })
      )
      .catch((err) => console.error(err));
  };

  static update = async (sessionId, informationMedia) => {
    const updatedInformationMedia =
      InformationMedia.transformForAPI(informationMedia);

    return fetch(
      `/if-api/jury-v2/session/${sessionId}/informationMedia/forAdmin/${informationMedia.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedInformationMedia),
      }
    )
      .then((response) => response.json())
      .then((informationMedia) =>
        InformationMedia.from({ ...informationMedia, sessionId })
      )
      .catch((err) => console.error(err));
  };

  static delete = async (sessionId, informationMediaId) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/informationMedia/forAdmin/${informationMediaId}`,
      {
        method: 'DELETE',
      }
    ).catch((err) => console.error(err));
  };

  static jurorFetchAllForGroup = async (jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/informationMedia`)
      .then((response) => response.json())
      .then((informationMedia) => InformationMedia.of(informationMedia))
      .catch((err) => console.error(err));
  };

  static adminFetchAllForSession = async (sessionId) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/informationMedia/forAdmin`
    )
      .then((response) => response.json())
      .then((informationMedia) =>
        InformationMedia.of(informationMedia.map((i) => ({ ...i, sessionId })))
      )
      .catch((err) => console.error(err));
  };

  static reorder = async (sessionId, informationMedia) => {
    const order = informationMedia.map((item) => ({ id: item.id }));

    return fetch(
      `/if-api/jury-v2/session/${sessionId}/informationMedia/order`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order),
      }
    )
      .then((response) => response.json())
      .then((informationMedia) =>
        InformationMedia.of(informationMedia.map((i) => ({ ...i, sessionId })))
      )
      .catch((err) => console.error(err));
  };
}

export default InformationMedia;
