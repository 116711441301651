import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Checkbox from '../../components/checkbox';
import Button from '../../components/button';
import Session from '../../models/session';
import styles from './styles.module.scss';
import { isAwardType } from '../../helpers/awardTypes';
import { AWARD_TYPES } from '../../models/award';
import { t } from 'i18next';

const SessionActions = ({ session, refreshSession }) => {
  const isSessionOpen = session
    ? moment(session.startDate).isBefore(moment()) &&
      moment(session.endDate).isAfter(moment())
    : false;

  const handleExpoModeChange = async (e) => {
    if (e.target.checked) {
      await Session.activateExpoMode(session.sessionId);
    } else {
      await Session.deactivateExpoMode(session.sessionId);
    }

    refreshSession();
  };

  const handlePrizeMoneyModeChange = async (e) => {
    if (e.target.checked) {
      await Session.activatePrizeMoneyMode(session.sessionId);
    } else {
      await Session.deactivatePrizeMoneyMode(session.sessionId);
    }

    refreshSession();
  };

  const handleOpenSession = async () => {
    const newSession = { ...session };

    newSession.startDate = moment().subtract(2, 'days').toISOString();

    if (moment(newSession.endDate).isBefore(moment().add(7, 'days'))) {
      newSession.endDate = moment().add(7, 'days').toISOString();
    }

    await Session.update(newSession);

    return refreshSession();
  };

  const handleCloseSession = async () => {
    const newSession = { ...session };

    newSession.endDate = moment().subtract(2, 'days').toISOString();

    if (moment(newSession.startDate).isAfter(moment().subtract(3, 'days'))) {
      newSession.startDate = moment().subtract(3, 'days').toISOString();
    }

    await Session.update(newSession);

    return refreshSession();
  };

  return (
    <div className={styles.sessionActions}>
      <h3 className={styles.heading}>{t('session.actions.heading')}</h3>
      <Row>
        <Col>
          <Checkbox
            label={t('session.actions.expoModeEnabled')}
            value={session?.expoModeEnabled}
            onChange={handleExpoModeChange}
            name="expoModeEnable"
          />
        </Col>
      </Row>
      {isAwardType(AWARD_TYPES.IF_SIP, session) && (
        <Row>
          <Col>
            <Checkbox
              onChange={handlePrizeMoneyModeChange}
              value={session?.prizeMoneyModeEnabled}
              label={t('session.actions.prizeMoneyModeEnabled')}
              name="prizeMoneyModeEnabled"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className={styles.buttonContainer}>
          <Button
            text={t('session.actions.openSession')}
            disabled={isSessionOpen}
            onClick={handleOpenSession}
          />
          <Button
            text={t('session.actions.closeSession')}
            disabled={!isSessionOpen}
            onClick={handleCloseSession}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SessionActions;
