import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Meeting from '../../../../../models/meeting';
import MeetingDialog from '../../../../../modules/meetingDialog';

const CreateMeeting = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.sessionId) {
      navigate('/admin/sessions/meetings');
    }
  }, []);

  const handleCancel = () => {
    navigate('/admin/sessions/meetings');
  };

  const handleSubmit = (meeting) => {
    Meeting.create(state.sessionId, meeting).then((newMeeting) => {
      navigate('/admin/sessions/meetings', {
        state: {
          triggerReload: newMeeting.id,
        },
      });
    });
  };

  return (
    <MeetingDialog
      onSubmit={handleSubmit}
      onClose={handleCancel}
      sessionId={state?.sessionId}
    />
  );
};

export default CreateMeeting;
