class Chat {
  constructor(
    jurorId,
    firstname,
    lastname,
    profilePictureUrl,
    message,
    created,
    updated,
  ) {
    this.jurorId = jurorId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.profilePictureUrl = profilePictureUrl;
    this.message = message;
    this.created = created;
    this.updated = updated;
  }

  static from = (message) => {
    return new Chat(
      message.jurorId,
      message.firstname,
      message.lastname,
      message.profilePictureUrl,
      message.message,
      message.created,
      message.updated
    );
  };

  static of = (messages) => {
    return messages?.messages?.map((message) => Chat.from(message));
  };

  static fetchMessages = async (jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/chat`)
      .then((response) => response.json())
      .then((messages) => Chat.of(messages))
      .catch((err) => console.error(err));
  };

  static sendMessage = async (jurorGroupId, message) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({message: message}),
    })
      .then((response) => response.json())
      .then((message) => Chat.from(message))
      .catch((err) => console.error(err));
  };
}

export default Chat;
