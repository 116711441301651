export const GROUP_ROLE = 'AWARDED_GROUP_AVERAGE';
export const AWARD_ROLE = 'AWARDED_TOTAL_AVERAGE';
class JurorStatistic {
  constructor(
    role,
    jurorId,
    firstname,
    lastname,
    profilePictureUrl,
    awardedPoints,
    decidedEvaluationCount,
    totalEvaluationCount
  ) {
    this.role = role;
    this.jurorId = jurorId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.profilePictureUrl = profilePictureUrl;
    this.awardedPoints = awardedPoints;
    this.decidedEvaluationCount = decidedEvaluationCount;
    this.totalEvaluationCount = totalEvaluationCount;
  }

  static from = (jurorStatistic) => {
    return new JurorStatistic(
      jurorStatistic.role,
      jurorStatistic.jurorId,
      jurorStatistic.firstname,
      jurorStatistic.lastname,
      jurorStatistic.profilePictureUrl,
      jurorStatistic.awardedPoints,
      jurorStatistic.decidedEvaluationCount,
      jurorStatistic.totalEvaluationCount
    );
  };

  static of = (jurorStatistics) => {
    return jurorStatistics?.map((statistic) => JurorStatistic.from(statistic));
  };
}

class EvaluationStatistic {
  constructor(
    jurors,
    numScoreAlerts,
    numSubmissionsWithSuggestedForNomination,
    numSubmissionsWithNomination,
    numEvaluationsWithComment,
    numEvaluationsWithNotDiscussedComment,
    numEntriesWithNotDiscussedComment
  ) {
    this.jurors = jurors;
    this.numScoreAlerts = numScoreAlerts;
    this.numSubmissionsWithSuggestedForNomination =
      numSubmissionsWithSuggestedForNomination;
    this.numSubmissionsWithNomination = numSubmissionsWithNomination;
    this.numEvaluationsWithComment = numEvaluationsWithComment;
    this.numEvaluationsWithNotDiscussedComment =
      numEvaluationsWithNotDiscussedComment;
    this.numEntriesWithNotDiscussedComment = numEntriesWithNotDiscussedComment;
  }

  static from = (evaluationStatistic) => {
    return new EvaluationStatistic(
      JurorStatistic.of(evaluationStatistic.jurors),
      evaluationStatistic.numScoreAlerts,
      evaluationStatistic.numSubmissionsWithSuggestedForNomination,
      evaluationStatistic.numSubmissionsWithNomination,
      evaluationStatistic.numEvaluationsWithComment,
      evaluationStatistic.numEvaluationsWithNotDiscussedComment,
      evaluationStatistic.numEntriesWithNotDiscussedComment
    );
  };

  static of = (evaluationStatistics) => {
    return evaluationStatistics?.map((statistic) =>
      EvaluationStatistic.from(statistic)
    );
  };

  static fetchForGroup = async (jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/awarded`)
      .then((response) => response.json())
      .then((statistics) => EvaluationStatistic.from(statistics))
      .catch((err) => console.error(err));
  };
}

export default EvaluationStatistic;
