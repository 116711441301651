class SubmissionPreview {
  constructor(
    submissionId,
    submissionName,
    submissionPrimaryMediaUrl,
    submissionPrimaryMediaThumbnailUrl
  ) {
    this.submissionId = submissionId;
    this.submissionName = submissionName;
    this.submissionPrimaryMediaUrl = submissionPrimaryMediaUrl;
    this.submissionPrimaryMediaThumbnailUrl =
      submissionPrimaryMediaThumbnailUrl;
  }

  static from = (submissionPreview) => {
    return new SubmissionPreview(
      submissionPreview.submissionId,
      submissionPreview.submissionName,
      submissionPreview.submissionPrimaryMediaUrl,
      submissionPreview.submissionPrimaryMediaThumbnailUrl
    );
  };

  static of = (submissionPreviews) => {
    return submissionPreviews?.map((preview) =>
      SubmissionPreview.from(preview)
    );
  };
}

export default SubmissionPreview;
