import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const SearchField = ({ onChange, value }) => (
  <div className={styles.search}>
    <input
      type="search"
      value={value}
      className={styles.input}
      onChange={(e) => onChange(e.target.value)}
    />
    <FontAwesomeIcon className={styles.icon} icon={faSearch} />
  </div>
);

SearchField.propTypes = {
  onChange: PropTypes.func,
  value: '',
};

SearchField.defaultProps = {
  onChange: () => null,
  value: '',
};

export default SearchField;
