import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useTranslation } from 'react-i18next';
import Dialog from '../../components/dialog';
import { ROLES, SCOPES, hasPermission } from '../../config/permissions/roles';
import { ADMIN_CONFIG } from '../../config/renderConfig';
import { translateCountryCode } from '../../helpers/countryCodes';
import { combine } from '../../helpers/styles';
import { useJuror } from '../../hooks/useAuth';
import { useConfig } from '../../hooks/useConfig';
import Submission from '../../models/submission';
import Loading from '../loading';
import ScoreTable from '../scoreTable';
import ActionsColumn from './actionsColumn';
import AverageScoreColumn from './averageScoreColumn';
import NameColumn from './nameColumn';
import StatusColumn from './statusColumn';
import styles from './styles.module.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const TABLET_VIEW_THRESHOLD = 992;

const EntriesTable = ({
  loading,
  submissions,
  updateSubmission,
  onEntryClick,
  onSort,
  isAdminView,
  awardType,
}) => {
  const { t } = useTranslation();
  const [scoreAlertModalData, setScoreAlertModal] = useState(null);
  const { jurorGroup, role, juror, session } = useJuror();
  const { ENTRIES_TABLE } = useConfig();

  const { width } = useWindowDimensions();
  const isTabletView = width < TABLET_VIEW_THRESHOLD;

  const handleBookmark = async (submission) => {
    const jurorGroupId =
      role === ROLES.chairPerson
        ? juror.jurorGroups.find(
            (jg) => jg.jurorGroupId === submission?.evaluations[0].jurorGroupId
          ).jurorGroupId
        : jurorGroup.jurorGroupId;

    return await Submission.setBookmark(
      !submission.bookmarked,
      jurorGroupId,
      submission.submissionId
    )
      .then(() => {
        updateSubmission({
          ...submission,
          bookmarked: !submission.bookmarked,
        });
      })
      .catch((err) => console.error(err));
  };

  const setScoreAlertModalData = (data) => {
    setScoreAlertModal(data);
  };

  const columnConfig = !isTabletView
    ? {
        name: {
          dataField: 'submissionName',
          text: t('jurorEntries.table.headings.name'),
          classes: styles.nameColumn,
          formatter: (cell, row, rowIndex) => {
            return (
              <NameColumn
                submission={row}
                onBookmark={() => handleBookmark(row)}
                onEntryClick={onEntryClick}
                readOnly={isAdminView}
                awardType={awardType}
              />
            );
          },
          headerStyle: () => {
            return {
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: '#000',
            };
          },
        },
        actions: {
          dataField: 'actions',
          text: t(
            `jurorEntries.table.headings.action.${
              session?.awardType || awardType
            }`
          ),
          classes: styles.actionsColumn,
          headerStyle: () => {
            return {
              width: `${ENTRIES_TABLE.ACTIONS.length * 50 + 50}px`,
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: '#000',
            };
          },
          formatter: (cell, row) => {
            return (
              <ActionsColumn
                submission={row}
                updateSubmission={updateSubmission}
                isAdminView={isAdminView}
              />
            );
          },
        },
        status: {
          dataField: 'status',
          text: t('jurorEntries.table.headings.status'),
          classes: styles.statusColumn,
          headerStyle: () => {
            return {
              width: '160px',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: '#000',
            };
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <StatusColumn
                submission={row}
                isAdminView={isAdminView}
                onNameClick={
                  hasPermission(SCOPES.canViewIndividualScores, role)
                    ? onEntryClick
                    : null
                }
              />
            );
          },
        },
        score: {
          dataField: 'averageScore',
          text: t('jurorEntries.table.headings.averageScore'),
          classes: styles.averageScoreColumn,
          headerClasses: styles.averageScoreColumnHeader,
          headerEvents: {
            onClick: onSort,
          },
          headerFormatter: (column) => {
            return (
              <span>
                <FontAwesomeIcon
                  icon={faEmptySet}
                  className={styles.averageIcon}
                />
                {column.text}
              </span>
            );
          },
          headerStyle: () => {
            return {
              width: '200px',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: '#000',
            };
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <AverageScoreColumn
                submission={row}
                averageScore={row?.averageScore}
                averageScoreFromPriorSession={row?.averageScoreFromPriorSession}
                setScoreAlertModalData={setScoreAlertModalData}
                onEntryClick={onEntryClick}
                readOnly={isAdminView}
              />
            );
          },
        },
        country: {
          dataField: 'accountCountryIsoCode',
          text: t('jurorEntries.table.headings.country'),
          classes: styles.countryColumn,
          headerStyle: () => {
            return {
              width: '150px',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: '#000',
            };
          },
          formatter: (cell) => {
            if (!cell) return '-';
            return translateCountryCode(cell);
          },
        },
      }
    : {
        name: {
          dataField: 'submissionName',
          text: t('jurorEntries.table.headings.name'),
          classes: styles.tabletView,
          formatter: (cell, row, rowIndex) => {
            return (
              <div className={styles.tabletViewRow}>
                <div className={styles.topRowContainer}>
                  <NameColumn
                    submission={row}
                    onBookmark={() => handleBookmark(row)}
                    onEntryClick={onEntryClick}
                    readOnly={isAdminView}
                    awardType={awardType}
                    isTabletView={true}
                  />
                  <AverageScoreColumn
                    submission={row}
                    averageScore={row?.averageScore}
                    averageScoreFromPriorSession={
                      row?.averageScoreFromPriorSession
                    }
                    setScoreAlertModalData={setScoreAlertModalData}
                    onEntryClick={onEntryClick}
                    readOnly={isAdminView}
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <ActionsColumn
                    submission={row}
                    updateSubmission={updateSubmission}
                    isAdminView={isAdminView}
                  />
                  <StatusColumn
                    submission={row}
                    isAdminView={isAdminView}
                    onNameClick={
                      hasPermission(SCOPES.canViewIndividualScores, role)
                        ? onEntryClick
                        : null
                    }
                    className={styles.statusColumn}
                  />
                </div>
              </div>
            );
          },
          headerStyle: () => {
            return {
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: '#000',
            };
          },
        },
      };

  const columns = useMemo(() => {
    if (isTabletView) return Object.values(columnConfig);

    if (isAdminView)
      return ADMIN_CONFIG.ENTRIES_TABLE.COLUMNS.map(
        (name) => columnConfig[name]
      );
    return ENTRIES_TABLE.COLUMNS.map((name) => columnConfig[name]) || [];
  }, [ENTRIES_TABLE, submissions, isAdminView, isTabletView]);

  const rowClasses = (row, rowIndex) => {
    let classes = [styles.entriesTableRow];

    if (role == ROLES.juror || role == ROLES.groupLeader) {
      if (row.chairPersonMustDecide) {
        classes.push(styles.disabled);
      }
    }
    return combine(...classes);
  };

  return (
    <>
      {scoreAlertModalData && (
        <Dialog
          noNav={true}
          title={t(
            'jurorEntries.table.columns.averageScore.scoreAlert.dialog.title'
          )}
          titleIcon={faTriangleExclamation}
          onClose={setScoreAlertModalData}
          body={<ScoreTable submissionId={scoreAlertModalData} />}
          footer={null}
          xs={12}
          sm={12}
          md={12}
          lg={11}
          xl={10}
          xxl={9}
        />
      )}

      {columns.length && (
        <BootstrapTable
          keyField="submissionId"
          data={submissions}
          columns={columns}
          rowClasses={rowClasses}
          wrapperClasses={styles.entriesTable}
          rowEvents={{
            onClick: (e, row) => {
              onEntryClick(`../entry/${row.submissionId}`, row);
            },
          }}
          noDataIndication={() => (
            <>
              {loading ? (
                <Loading />
              ) : (
                <span className={styles.emptyMessage}>
                  {t('jurorEntries.table.noData')}
                </span>
              )}
            </>
          )}
        />
      )}
    </>
  );
};

EntriesTable.propTypes = {
  loading: PropTypes.bool,
  submissions: PropTypes.array,
  updateSubmission: PropTypes.func,
  onSort: PropTypes.func,
  isAdminView: PropTypes.bool,
  awardType: PropTypes.string,
};

EntriesTable.defaultProps = {
  loading: false,
  submissions: [],
  updateSubmission: () => null,
  onSort: () => null,
  isAdminView: false,
  awardType: 'IF_DA',
};

export default EntriesTable;
