import { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { combine } from '../../../../helpers/styles';
import styles from './styles.module.scss';

const Dropdown = ({ maxHeight, value, options, onSelect }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  const handleDropdownToggle = () => {
    setIsActive((prev) => !prev);
  };

  const handleSelection = (option) => {
    setIsActive(false);
    onSelect(option);
  };

  useEffect(() => {
    const checkForOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', checkForOutsideClick);

    return () => {
      document.removeEventListener('mousedown', checkForOutsideClick);
    };
  }, [ref, setIsActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <button className={styles.dropdownButton} onClick={handleDropdownToggle}>
        <span dangerouslySetInnerHTML={{ __html: value.name }}></span>
        <FontAwesomeIcon
          className={styles.icon}
          icon={faChevronDown}
          size="lg"
        />
      </button>
      <div
        className={combine(styles.optionsContainer, isActive && styles.active)}
        style={{ maxHeight }}
      >
        <ul className={styles.list}>
          {options.map((option) => (
            <li
              className={combine(
                styles.listItem,
                value.id === option.id && styles.active
              )}
              key={option.id}
            >
              <button
                className={styles.button}
                onClick={() => {
                  handleSelection(option);
                }}
              >
                <div className={styles.iconContainer}>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={option.id === 'group' ? faUserGroup : faUser}
                  />
                </div>
                <span
                  className={styles.label}
                  dangerouslySetInnerHTML={{ __html: option.name }}
                ></span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  maxHeight: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
};

Dropdown.defaultProps = {
  maxHeight: 'none',
  value: null,
  options: [],
  onSelect: () => null,
};

export default Dropdown;
