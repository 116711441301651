import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AccordionItem from './components/accordionItem';

const Accordion = ({ content }) => {
  const location = useLocation();
  const [openItem, setOpenItem] = useState(-1);

  useEffect(() => {
    const hash = location?.hash;
    if (hash) {
      content.forEach((item) => {
        if (`${item.id}` === hash?.replace(/#/g, '')) {
          setOpenItem(item.id);
        }
      });
    }
  }, [content, location]);

  const handleClick = (id) => {
    setOpenItem((prev) => (prev === id ? -1 : id));
  };

  return (
    <div>
      {content?.map((item, index) => (
        <AccordionItem
          key={`accordion-item-${item.id}`}
          title={item.title}
          text={item.infoText}
          pdfs={item.pdfs}
          videos={item.videos}
          isFirst={index === 0}
          isOpen={item.id === openItem}
          onOpen={() => handleClick(item.id)}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = {
  content: PropTypes.array,
};

Accordion.defaultProps = {
  content: [],
};

export default Accordion;
