import PropTypes from 'prop-types';
import { Carousel as BsCarousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraSlash } from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';

const Carousel = ({ preview }) => (
  <BsCarousel className={styles.carousel} interval="5000">
    {preview?.map((submission) => (
      <BsCarousel.Item
        className={styles.carouselItem}
        key={submission.submissionId}
      >
        {submission.submissionPrimaryMediaUrl ? (
          <img
            className={styles.carouselImage}
            src={submission.submissionPrimaryMediaUrl}
            loading="lazy"
          />
        ) : (
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={faCameraSlash} size="8x" />
          </div>
        )}
      </BsCarousel.Item>
    ))}
  </BsCarousel>
);

Carousel.propTypes = {
  preview: PropTypes.arrayOf(
    PropTypes.shape({
      submissionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      submissionPrimaryMediaUrl: PropTypes.string,
    })
  ),
};

Carousel.defaulTprops = {
  preview: [],
};

export default Carousel;
