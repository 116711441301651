import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../components/dialog';
import Loading from '../../components/loading';
import InformationMedia from '../../models/informationMedia';
import InformationMaterialForm from './informationMaterialForm';
import styles from './styles.module.scss';

const InformationMaterialDialog = ({
  onClose,
  onSubmit,
  currentSession,
  informationMaterial,
  refetchItem,
}) => {
  const [informationItem, setInformationItem] = useState(null);

  useEffect(() => {
    if (refetchItem) {
      InformationMedia.adminFetchItem(
        informationMaterial.sessionId,
        informationMaterial.id
      ).then((item) => {
        setInformationItem(item);
      });
    } else {
      setInformationItem(informationMaterial);
    }
  }, [informationMaterial, refetchItem, setInformationItem]);

  return (
    <Dialog
      xxl={6}
      xl={7}
      lg={9}
      md={10}
      sm={11}
      xs={12}
      body={
        <div className={styles.formWrapper}>
          {refetchItem && !informationItem ? (
            <Loading />
          ) : (
            <InformationMaterialForm
              onCancel={onClose}
              onSubmit={onSubmit}
              currentSession={currentSession}
              informationMaterial={informationItem}
            />
          )}
        </div>
      }
      onClose={onClose}
      noHeader
      noNav
      scrollable
    />
  );
};

InformationMaterialDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  currentSession: PropTypes.object,
  informationMaterial: PropTypes.object,
  refetchItem: PropTypes.bool,
};

InformationMaterialDialog.defaultProps = {
  onClose: () => null,
  onSubmit: () => null,
  currentSession: null,
  informationMaterial: null,
  refetchItem: false,
};

export default InformationMaterialDialog;
