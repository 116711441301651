import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button, { ButtonTypes } from '../../components/button';
import Dialog from '../../components/dialog';
import Input from '../../components/input';
import { useTranslation } from 'react-i18next';

const ModalVideoLink = ({ onClose, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const isEmpty = !title || !link;

  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit({
      title,
      url: link,
    });
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  return (
    <Dialog
      noNav={true}
      title={t('sessions.informationMaterial.modals.addLink.title')}
      onClose={onClose}
      body={
        <>
          <Row>
            <Col>
              <Input
                required
                label={t(
                  'sessions.informationMaterial.modals.addLink.label.title'
                )}
                onChange={handleTitleChange}
                value={title}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                required
                label={t(
                  'sessions.informationMaterial.modals.addLink.label.url'
                )}
                onChange={handleLinkChange}
                value={link}
              />
            </Col>
          </Row>
        </>
      }
      footer={
        <>
          <Button
            text={t(
              'sessions.informationMaterial.modals.addLink.buttons.cancel'
            )}
            type={ButtonTypes.SECONDARY}
            onClick={onClose}
          />
          <Button
            disabled={isEmpty}
            text={t('sessions.informationMaterial.modals.addLink.buttons.add')}
            type={ButtonTypes.PRIMARY}
            onClick={handleSubmit}
          />
        </>
      }
    />
  );
};

ModalVideoLink.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

ModalVideoLink.defaultProps = {
  onClose: () => null,
  onSubmit: () => null,
};

export default ModalVideoLink;
