import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Input from '../../../components/input';
import Checkbox from '../../../components/checkbox';
import Button, { ButtonTypes } from '../../../components/button';
import Dropdown from '../../../components/dropdown';
import styles from './styles.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { useFormik } from 'formik';
import TextEditor from '../../../components/textEditor';
import { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faUpload,
  faTrash,
  faFilePdf,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';
import {
  faUpload as faUploadBold,
  faVideo as faVideoBold,
} from '@fortawesome/pro-regular-svg-icons';
import ModalFileUpload from '../../modalFileUpload';
import ModalVideoLink from '../../modalVideoLink';
import Session from '../../../models/session';
import InformationMedia from '../../../models/informationMedia';

const InformationMaterialForm = ({
  informationMaterial,
  onCancel,
  onSubmit,
  currentSession,
}) => {
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [nextPosition, setNextPosition] = useState(-1);
  const { t } = useTranslation();

  useEffect(() => {
    Session.fetchAll({ archived: false }).then((sessions) => {
      setSessionOptions(
        sessions.map((session) => ({
          value: session.sessionId,
          text: session.sessionName,
        }))
      );
    });

    if (currentSession) {
      InformationMedia.adminFetchAllForSession(currentSession.sessionId).then(
        (informationMedia) => {
          if (informationMedia.length > 0) {
            setNextPosition(informationMedia.slice(-1)[0].order + 1);
          }
        }
      );
    }

    if (informationMaterial?.sessionId) {
      InformationMedia.adminFetchAllForSession(
        informationMaterial.sessionId
      ).then((informationMedia) => {
        setNextPosition(informationMedia.slice(-1)[0].order + 1);
      });
    }
  }, []);

  const extendedSessionOptions = useMemo(() => {
    if (sessionOptions.length === 0) return [];
    if (!informationMaterial || !currentSession) return sessionOptions;

    const isSessionInSessionOptions = sessionOptions.some(
      (option) => option.value === currentSession.sessionId
    );

    if (!isSessionInSessionOptions) {
      return [
        { value: currentSession.sessionId, text: currentSession.sessionName },
        ...sessionOptions,
      ];
    }

    return sessionOptions;
  }, [currentSession, sessionOptions]);

  const formik = useFormik({
    initialValues: informationMaterial || {
      title: '',
      infoText: '',
      sessionId: currentSession ? currentSession.sessionId : '',
      pdfs: [],
      videos: [],
      published: false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const v = { ...values };
      const submittedValues = {
        sessionId: v.sessionId,
      };
      delete v.sessionId;
      v.order = nextPosition;
      submittedValues.values = v;
      onSubmit(submittedValues);
    },
  });

  const handlePdfDelete = (deletedPdf) => {
    const filteredPdfs = formik.values.pdfs.filter(
      (pdf) => pdf.url !== deletedPdf.url
    );
    formik.setFieldValue('pdfs', filteredPdfs);
  };

  const handleVideoDelete = (videoIndex) => {
    const filteredVideos = formik.values.videos.filter(
      (_, index) => index !== videoIndex
    );
    formik.setFieldValue('videos', filteredVideos);
  };

  const handleTitleInputChange = (e) => {
    if (e.target.value.length > 50) return;

    formik.setFieldValue('title', e.target.value);
  };

  const onTextEditorChange = (v) => {
    formik.setFieldValue('infoText', v);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Row className="justify-content-md-center">
          <Col md={7}>
            <h2>
              <Trans
                i18nKey={`sessions.informationMaterial.form.title.${
                  informationMaterial?.id ? 'edit' : 'create'
                }`}
                components={{
                  bold: <b />,
                }}
              />
            </h2>
          </Col>
          <Col md={5} className={styles.checkBoxContainer}>
            <Checkbox
              label={t(
                'sessions.informationMaterial.form.inputs.publish.label'
              )}
              name="published"
              value={!!formik.values.published}
              onChange={formik.handleChange}
              tooltip={t(
                'sessions.informationMaterial.form.inputs.publish.tooltip'
              )}
              icon={faCircleInfo}
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <Input
              name="title"
              label={t('sessions.informationMaterial.form.inputs.title')}
              required
              value={formik.values.title}
              onChange={handleTitleInputChange}
            />
            <div className={styles.hintContainer}>
              <span className={styles.hint}>
                {t('sessions.informationMaterial.form.inputs.maxChars')}
              </span>
              <span
                className={styles.hint}
              >{`${formik.values.title.length} / 50`}</span>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Dropdown
            name="sessionId"
            required
            label={t('sessions.informationMaterial.form.inputs.session')}
            options={extendedSessionOptions}
            value={formik.values.sessionId}
            onRawChange={formik.handleChange}
            readOnly
          />
        </Row>
        <Row className="justify-content-md-center">
          <TextEditor
            label={t('sessions.informationMaterial.form.inputs.text')}
            onChange={onTextEditorChange}
            value={formik.values.infoText}
            required
          />
        </Row>
        {formik.values.pdfs.length !== 0 && (
          <div className={styles.filesContainer}>
            {formik.values.pdfs.map((pdf) => (
              <Row key={pdf.url}>
                <Col xs={12}>
                  <div
                    className={styles.file}
                    onClick={() => {
                      window.open(pdf.url, '_blank').focus();
                    }}
                  >
                    <div className={styles.details}>
                      <span className={styles.title}>{pdf.title}</span>
                      <div className={styles.metadataContainer}>
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          className={styles.icon}
                        />
                        <span className={styles.filename}>
                          {pdf.url || pdf.filename}
                        </span>
                      </div>
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className={styles.deleteIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePdfDelete(pdf);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        )}
        {formik.values.videos.length !== 0 && (
          <div className={styles.linksContainer}>
            {formik.values.videos.map((video, index) => (
              <Row key={video.title}>
                <Col xs={12}>
                  <div
                    className={styles.link}
                    onClick={() => {
                      window.open(video.url, '_blank').focus();
                    }}
                  >
                    <div className={styles.details}>
                      <span className={styles.title}>{video.title}</span>
                      <div className={styles.metadataContainer}>
                        <FontAwesomeIcon
                          icon={faVideo}
                          className={styles.icon}
                        />
                        <span className={styles.linkUrl}>{video.url}</span>
                      </div>
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className={styles.deleteIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleVideoDelete(index);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        )}
        <Row>
          <Col className={styles.uploadButtonRow}>
            <Button
              className={styles.addPdfButton}
              type={ButtonTypes.PRIMARY}
              icon={faUpload}
              iconBold={faUploadBold}
              onClick={() => {
                setShowFileUploadModal(true);
              }}
              text={t('sessions.informationMaterial.form.buttons.uploadFile')}
              htmlType="button"
            />
            <Button
              className={styles.button}
              type={ButtonTypes.PRIMARY}
              icon={faVideo}
              iconBold={faVideoBold}
              onClick={() => {
                setShowVideoModal(true);
              }}
              text={t('sessions.informationMaterial.form.buttons.addLink')}
              htmlType="button"
            />
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${styles.buttonRow} ${styles.addButtons}`}
        >
          <Col md="auto">
            <Button
              className={styles.button}
              text={t('sessions.meetings.form.buttons.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={onCancel}
              htmlType="button"
            />
          </Col>
          <Col>
            <Button
              className={styles.addButton}
              text={t('sessions.meetings.form.buttons.submit')}
              type={ButtonTypes.PRIMARY}
              htmlType="submit"
            />
          </Col>
        </Row>
      </form>
      {showFileUploadModal && (
        <ModalFileUpload
          onClose={() => {
            setShowFileUploadModal(false);
          }}
          onSubmit={(file) => {
            formik.setFieldValue('pdfs', [...formik.values.pdfs, file]);
            setShowFileUploadModal(false);
          }}
          currentSessionId={formik.values.sessionId}
        />
      )}
      {showVideoModal && (
        <ModalVideoLink
          onClose={() => {
            setShowVideoModal(false);
          }}
          onSubmit={(video) => {
            formik.setFieldValue('videos', [...formik.values.videos, video]);
            setShowVideoModal(false);
          }}
        />
      )}
    </div>
  );
};

InformationMaterialForm.propTypes = {
  informationMaterial: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  currentSession: PropTypes.object,
};

InformationMaterialForm.defaultProps = {
  informationMaterial: null,
  onCancel: () => null,
  onSubmit: () => null,
  currentSession: null,
};

export default InformationMaterialForm;
