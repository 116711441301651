class EvaluationCriterion {
  constructor(id, name, description) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  static from = (criterion) => {
    if (!criterion) return null;

    return new EvaluationCriterion(
      criterion.id,
      criterion.name,
      criterion.description
    );
  };

  static of = (criteria) => {
    return criteria?.map((criterion) => EvaluationCriterion.from(criterion));
  };

  static transformForAPI = (criterion) => {
    return {
      name: criterion.name,
      description: criterion.description,
    };
  };

  static fetchAll = async () => {
    return fetch(`/if-api/jury-v2/evaluationCriteria`)
      .then((response) => response.json())
      .then((criteria) => EvaluationCriterion.of(criteria))
      .catch((err) => {
        console.error(err);
      });
  };

  static create = async (criterion) => {
    const newCriterion = EvaluationCriterion.transformForAPI(criterion);

    return fetch(`/if-api/jury-v2/evaluationCriteria`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newCriterion),
    }).catch((err) => {
      console.error(err);
    });
  };

  static update = async (criterion) => {
    const updatedCriterion = EvaluationCriterion.transformForAPI(criterion);

    return fetch(`/if-api/jury-v2/evaluationCriterion/${criterion.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCriterion),
    }).catch((err) => {
      console.error(err);
    });
  };

  static delete = async (criterionId) => {
    return fetch(`/if-api/jury-v2/evaluationCriterion/${criterionId}`, {
      method: 'DELETE',
    }).catch((err) => {
      console.error(err);
    });
  };
}

export default EvaluationCriterion;
