import { AWARD_TYPES } from '../../models/award';
import { SESSION_TYPES } from '../../models/session';

export const ADMIN_CONFIG = {
  ENTRIES_FILTER: [
    'categoryName',
    'country',
    'suggestedForNomination',
    'nominated',
  ],
  ENTRIES_TABLE: {
    COLUMNS: ['name', 'actions', 'country', 'status', 'score'],
    ACTIONS: ['nominate', 'prize-money'],
  },
};

const CONFIG = {
  [AWARD_TYPES.IF_DA]: {
    AWARD_YEAR: 2024,
    ENTRIES_FILTER: [
      'disciplineName',
      'categoryName',
      'jurorGroup',
      'presentationForm',
      'suggestedForNomination',
      'nominated',
      'chairpersonMustDecide',
      'discussInGroup',
      'toBeDecided',
      'scoreAlert',
      'bookmarked',
      'hasZeroEvaluationCategory',
    ],
    ENTRIES_TABLE: {
      COLUMNS: ['name', 'actions', 'status', 'score'],
      ACTIONS: [
        'nominate',
        'suggestForNomination',
        'delegateChairperson',
        'comment',
      ],
    },
    EVALUATION_CRITERIA_ORDER: [
      'idea',
      'form',
      'function',
      'differentiation',
      'impact',
    ],
    ENTRY_DETAIL: {
      ENABLE_SCORE_TRANSFER: false,
    },
    HELP_CONTACT_INFO: {
      FIELDS: ['description', 'contactBlock'],
      CONTACTS: ['franziska', 'frank'],
    },
    FOOTER_CONTACTS: ['franziska', 'frank'],
    DASHBOARD_CONFIG: {
      task: true,
      meetings: true,
      statistic: true,
      information: true,
      groupMembers: true,
      chat: true,
      BIG_STATISTIC: {
        [SESSION_TYPES.PRESELECTION]: false,
        [SESSION_TYPES.SINGLE_SESSION]: false,
        [SESSION_TYPES.FINAL_JURY]: true,
      },
    },
    NAVIGATION: {
      QR_CODE_BUTTON: {
        [SESSION_TYPES.PRESELECTION]: false,
        [SESSION_TYPES.SINGLE_SESSION]: false,
        [SESSION_TYPES.FINAL_JURY]: true,
      },
    },
  },
  [AWARD_TYPES.IF_SIP]: {
    AWARD_YEAR: 2024,
    ENTRIES_FILTER: [
      'categoryName',
      'country',
      'suggestedForNomination',
      'nominated',
      'toBeDecided',
      'bookmarked',
    ],
    ENTRIES_TABLE: {
      COLUMNS: ['name', 'actions', 'country', 'status', 'score'],
      ACTIONS: ['nominate'],
    },
    EVALUATION_CRITERIA_ORDER: [
      'problem-solving',
      'reasonable effort',
      'moral-ethical standards',
      'beneficial experiences',
      'solidarity',
    ],
    ENTRY_DETAIL: {
      ENABLE_SCORE_TRANSFER: false,
    },
    HELP_CONTACT_INFO: {
      FIELDS: ['contactBlock'],
      CONTACTS: ['gabriele'],
    },
    FOOTER_CONTACTS: ['gabriele'],
    DASHBOARD_CONFIG: {
      task: true,
      meetings: true,
      statistic: true,
      information: true,
      groupMembers: false,
      chat: false,
      BIG_STATISTIC: {
        [SESSION_TYPES.PRESELECTION]: false,
        [SESSION_TYPES.SINGLE_SESSION]: false,
        [SESSION_TYPES.FINAL_JURY]: false,
      },
    },
    NAVIGATION: {
      QR_CODE_BUTTON: {
        [SESSION_TYPES.PRESELECTION]: false,
        [SESSION_TYPES.SINGLE_SESSION]: false,
        [SESSION_TYPES.FINAL_JURY]: false,
      },
    },
  },
  [AWARD_TYPES.IF_DTA]: {
    AWARD_YEAR: 2024,
    ENTRIES_FILTER: [
      'categoryName',
      'country',
      'suggestedForNomination',
      'toBeDecided',
      'bookmarked',
    ],
    ENTRIES_TABLE: {
      COLUMNS: ['name', 'actions', 'country', 'status', 'score'],
      ACTIONS: ['nominate'],
    },
    EVALUATION_CRITERIA_ORDER: [
      'problem-solving',
      'moral-ethical standards',
      'solidarity',
      'economic calculation',
      'beneficial experiences',
    ],
    ENTRY_DETAIL: {
      ENABLE_SCORE_TRANSFER: false,
    },
    HELP_CONTACT_INFO: {
      FIELDS: ['contactBlock'],
      CONTACTS: ['andrea', 'heike'],
    },
    FOOTER_CONTACTS: ['andrea', 'heike'],
    DASHBOARD_CONFIG: {
      task: true,
      meetings: false,
      statistic: true,
      information: true,
      groupMembers: false,
      chat: false,
      BIG_STATISTIC: {
        [SESSION_TYPES.PRESELECTION]: false,
        [SESSION_TYPES.SINGLE_SESSION]: false,
        [SESSION_TYPES.FINAL_JURY]: false,
      },
    },
    NAVIGATION: {
      QR_CODE_BUTTON: {
        [SESSION_TYPES.PRESELECTION]: false,
        [SESSION_TYPES.SINGLE_SESSION]: false,
        [SESSION_TYPES.FINAL_JURY]: false,
      },
    },
  },
};

export default CONFIG;
