class ThresholdCalcSubmissions {
  constructor(
    submissionId,
    submissionName,
    submissionPrimaryMediaUrl,
    submissionPrimaryMediaThumbnailUrl,
    categoryName,
    disciplineName,
    submissionType,
    accountCountryIsoCode,
    totalPointsFromAllDecidedEvaluations,
    numDecidedEvaluations,
    numSuggestedForNominations,
    numNominations
  ) {
    this.submissionId = submissionId;
    this.submissionName = submissionName;
    this.submissionPrimaryMediaUrl = submissionPrimaryMediaUrl;
    this.submissionPrimaryMediaThumbnailUrl =
      submissionPrimaryMediaThumbnailUrl;
    this.categoryName = categoryName;
    this.disciplineName = disciplineName;
    this.submissionType = submissionType;
    this.accountCountryIsoCode = accountCountryIsoCode;
    this.totalPointsFromAllDecidedEvaluations =
      totalPointsFromAllDecidedEvaluations;
    this.numDecidedEvaluations = numDecidedEvaluations;
    this.numSuggestedForNominations = numSuggestedForNominations;
    this.numNominations = numNominations;
  }

  static from = (thresholdCalcSubmissions) => {
    if (!thresholdCalcSubmissions) return null;

    return new ThresholdCalcSubmissions(
      thresholdCalcSubmissions.submissionId,
      thresholdCalcSubmissions.submissionName,
      thresholdCalcSubmissions.submissionPrimaryMediaUrl,
      thresholdCalcSubmissions.submissionPrimaryMediaThumbnailUrl,
      thresholdCalcSubmissions.categoryName,
      thresholdCalcSubmissions.disciplineName,
      thresholdCalcSubmissions.submissionType,
      thresholdCalcSubmissions.accountCountryIsoCode,
      thresholdCalcSubmissions.totalPointsFromAllDecidedEvaluations,
      thresholdCalcSubmissions.numDecidedEvaluations,
      thresholdCalcSubmissions.numSuggestedForNominations,
      thresholdCalcSubmissions.numNominations
    );
  };

  static of = (submissions) => {
    return submissions?.map((submission) =>
      ThresholdCalcSubmissions.from(submission)
    );
  };

  static fetchAllSubmissions = async (sessionId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/submissionsOverview`)
      .then((response) => response.json())
      .then((submissions) =>
        ThresholdCalcSubmissions.of(submissions.submissions)
      )
      .catch((err) => {
        console.error(err);
      });
  };

  static submitWinners = async (sessionId, winners) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/submissionsOverview/winners`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(winners),
      }
    )
      .then(() => 'Success')
      .catch((err) => {
        console.error(err);
      });
  };

  static fetchWinnerThreshold = async (sessionId) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/submissionsOverview/winnerThreshold`
    )
      .then((response) => response.json())
      .catch((err) => {
        console.error(err);
      });
  };

  static setWinnerThreshold = async (sessionId, threshold) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/submissionsOverview/winnerThreshold`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ threshold }),
      }
    ).catch((err, res) => {
      console.error(err);
      return res;
    });
  };
}

export default ThresholdCalcSubmissions;
