import { faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { ROLES, VOTING_ROLES } from '../../config/permissions/roles';
import {
  getAverageScore,
  getProgressPrecentage,
} from '../../helpers/statistic';
import variables from '../../styles/__variables.scss';
import Loading from '../loading';
import ProgressBar from '../progressBar';
import { SESSION_TYPES } from '../../models/session';
import { matchRoles } from '../../helpers/roles';
import styles from './styles.module.scss';

const groupFormatter = (_cell, row) => <span>{row.jurorGroupName}</span>;

const entriesFormatter = (_cell, row) => {
  const evaluators = row.jurors.filter((juror) =>
    matchRoles(juror.role, [ROLES.juror, ROLES.groupLeader])
  ).length;
  const evaluationCount = row.jurors.reduce(
    (prev, curr) => prev + curr.decidedEvaluationCount || 0,
    0
  );

  return (
    <span>{`${Math.floor(evaluationCount / evaluators)} / ${
      row.submissionCount
    }`}</span>
  );
};

const decidedByNowFormatter = (_cell, row) => {
  const votingJurors = row.jurors.filter((juror) => juror.role !== ROLES.staff);

  const progress = votingJurors.reduce(
    (stats, juror) => {
      const decidedEvaluationCount = juror.decidedEvaluationCount || 0;
      const totalEvaluationCount = juror.totalEvaluationCount || 0;

      return {
        totalEvaluationCount: stats.totalEvaluationCount + totalEvaluationCount,
        decidedEvaluationCount:
          stats.decidedEvaluationCount + decidedEvaluationCount,
      };
    },
    {
      totalEvaluationCount: 0,
      decidedEvaluationCount: 0,
    }
  );
  const result = getProgressPrecentage(
    progress.decidedEvaluationCount,
    progress.totalEvaluationCount
  );

  return (
    <div className={styles.progressBarWrapper}>
      <ProgressBar withMarker color={variables.secondaryBlue} value={result} />
      <span className={styles.progressValue}>{result} %</span>
    </div>
  );
};

const averageScoreFormatter = (_cell, row, _, maxPoints) => {
  const scores = row.jurors
    .filter((juror) => juror.role !== ROLES.staff)
    .reduce(
      (stats, juror) => {
        const decidedEvaluationCount = juror.decidedEvaluationCount || 0;
        const totalPointsFromAllDecidedEvaluations =
          juror.totalPointsFromAllDecidedEvaluations || 0;

        return {
          decidedEvaluationCount:
            stats.decidedEvaluationCount + decidedEvaluationCount,
          totalPointsFromAllDecidedEvaluations:
            stats.totalPointsFromAllDecidedEvaluations +
            totalPointsFromAllDecidedEvaluations,
        };
      },
      {
        decidedEvaluationCount: 0,
        totalPointsFromAllDecidedEvaluations: 0,
      }
    );
  const result = getAverageScore(
    scores.decidedEvaluationCount,
    scores.totalPointsFromAllDecidedEvaluations
  );
  const resultInPercentage = Number((result / maxPoints) * 100).toFixed(0);

  return (
    <div className={styles.progressBarWrapper}>
      <ProgressBar
        withMarker
        value={resultInPercentage}
        color={variables.secondaryBlue}
      />
      <span className={styles.progressValue}>
        {result} / {maxPoints}
      </span>
    </div>
  );
};

const actionsFormatter = (row, setExpandedRow) => (
  <div className={styles.actions}>
    <FontAwesomeIcon
      icon={faEye}
      onClick={() =>
        setExpandedRow((prev) =>
          row.jurorGroupId === prev?.jurorGroupId ? null : row
        )
      }
    />
  </div>
);

const EvaluationStatusTable = ({ groups, maxPoints, session, loading }) => {
  const { t } = useTranslation();
  const [expandedRow, setExpandedRow] = useState(null);

  const columns = [
    {
      dataField: 'name',
      text: t('sessions.evaluation.table.headers.group'),
      formatter: (cell, row) => groupFormatter(cell, row, t),
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'entries',
      text: t('sessions.evaluation.table.headers.entries'),
      formatter: (cell, row) => entriesFormatter(cell, row, t),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField:
        session?.sessionType === SESSION_TYPES.PRESELECTION
          ? 'numSubmissionsWithSuggestedForNomination'
          : 'numNominationsInGroup',
      text: t(
        `sessions.evaluation.table.headers.nomination.${
          session?.sessionType || 'SINGLE_SESSION'
        }`
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'decisionRatio',
      text: t('sessions.evaluation.table.headers.decided'),
      formatter: (cell, row) => decidedByNowFormatter(cell, row, t),
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'averageScore',
      text: t('sessions.evaluation.table.headers.average'),
      formatter: (cell, row) => averageScoreFormatter(cell, row, t, maxPoints),
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'actions',
      text: '',
      classes: styles.actionsWrapper,
      formatter: (_, row) => actionsFormatter(row, setExpandedRow),
      headerStyle: () => {
        return { width: '4%' };
      },
    },
  ];

  const expandRow = {
    renderer: (row) => {
      return (
        <div>
          {row.jurors
            .filter((juror) => matchRoles(juror.role, VOTING_ROLES))
            .map((juror) => {
              const progressPercentage = getProgressPrecentage(
                juror.decidedEvaluationCount,
                juror.totalEvaluationCount
              );

              const averageScore = getAverageScore(
                juror.decidedEvaluationCount,
                juror.totalPointsFromAllDecidedEvaluations
              );

              const averageScorePercentage = Number(
                (averageScore / maxPoints) * 100
              ).toFixed(0);

              return (
                <div className={styles.jurorRow}>
                  <div className={styles.nameColumn}>
                    <span className={styles.name}>{juror.name}</span>
                    <span className={styles.role}>
                      {t(`roles.${juror.role}`)}
                    </span>
                  </div>
                  <div>
                    <span>{juror.decidedEvaluationCount}</span>
                  </div>
                  <div>
                    <div className={styles.progressBarWrapper}>
                      <ProgressBar
                        withMarker
                        color={variables.secondaryBlue}
                        value={progressPercentage}
                      />
                      <span className={styles.progressValue}>
                        {progressPercentage} %
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={styles.progressBarWrapper}>
                      <ProgressBar
                        withMarker
                        value={averageScorePercentage}
                        color={variables.secondaryBlue}
                      />
                      <span className={styles.progressValue}>
                        {averageScore} / {maxPoints}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      );
    },
    expanded: [expandedRow?.jurorGroupId],
    expandByColumnOnly: true,
  };

  return (
    <BootstrapTable
      expandRow={expandRow}
      keyField="jurorGroupId"
      data={groups}
      columns={columns}
      wrapperClasses={styles.groupsTable}
      noDataIndication={() => (
        <>
          {loading ? (
            <Loading />
          ) : (
            <span className={styles.emptyMessage}>
              {t('sessions.evaluation.table.noData')}
            </span>
          )}
        </>
      )}
    />
  );
};

EvaluationStatusTable.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

EvaluationStatusTable.defaultProps = {
  groups: [],
  loading: false,
};

export default EvaluationStatusTable;
