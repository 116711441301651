import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPlus as faPlusBold } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonTypes } from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import GroupsTable from '../../../../components/groupsTable';
import JurorGroup from '../../../../models/jurorGroup';
import Session from '../../../../models/session';
import Hero from '../../../../modules/hero';
import styles from './styles.module.scss';

const JurorGroupsOverview = () => {
  const [groups, setGroups] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [sessions, setSessions] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const fetchJurorGroups = async () => {
    if (session) {
      setGroupsLoading(true);
      const data = await JurorGroup.fetchAll(session);
      setGroups(data);
      setGroupsLoading(false);
    }
  };

  useEffect(async () => {
    let data = await Session.fetchAll({ archived: false });
    data = data.map((session) => ({
      value: session.sessionId,
      text: session.sessionName,
    }));
    setSessions(data);
    if (state?.selectedSession) {
      setSession(state.selectedSession);
    }
  }, []);

  useEffect(() => {
    fetchJurorGroups();
  }, [session]);

  useEffect(() => {
    if (state?.triggerReload) {
      fetchJurorGroups();
    }
  }, [state]);

  const handleSessionChange = (value) => {
    setSession(value);
  };

  return (
    <div className={styles.sessionsOverview}>
      <Hero
        title={t('jurors.overview.total', { amount: groups?.length || 0 })}
        rightSection={
          <Dropdown
            options={sessions}
            onChange={handleSessionChange}
            value={session}
            label={t('jurors.overview.dropdownLabel')}
            required
            className={styles.dropdown}
          />
        }
      />
      <Container className={'py-5'}>
        <div
          className={
            'd-flex justify-content-between align-items-center font-weight-bold text-capitalize'
          }
        >
          <Button
            text={t('jurors.overview.addGroup')}
            className={'text-capitalize'}
            type={ButtonTypes.PRIMARY}
            icon={faPlus}
            iconBold={faPlusBold}
            onClick={() =>
              navigate('/admin/groups/overview/create', {
                state: { sessionId: session },
              })
            }
          />
        </div>
        <GroupsTable
          groups={groups}
          loading={groupsLoading}
          sessionId={session}
          fetchJurorGroups={fetchJurorGroups}
        />
      </Container>
    </div>
  );
};

export default JurorGroupsOverview;
