import { faPen, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPlus as faPlusBold } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container, Nav, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonTypes } from '../../../../components/button';
import InformationTable from '../../../../components/informationTable';
import MeetingsTable from '../../../../components/meetingsTable';
import { TABLE_TYPES } from '../../../../components/sessionsTable';
import { combine } from '../../../../helpers/styles';
import InformationMedia from '../../../../models/informationMedia';
import Meeting from '../../../../models/meeting';
import Session from '../../../../models/session';
import InformationMaterialDialog from '../../../../modules/informationMaterialDialog';
import MeetingDialog from '../../../../modules/meetingDialog';
import SessionActions from '../../../../modules/sessionActions';
import SessionForm, {
  SESSION_FORM_TYPES,
} from '../../../../modules/sessionForm';
import styles from './styles.module.scss';

const SessionDetail = () => {
  const [session, setSession] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [informationMedia, setInformationMedia] = useState([]);
  const [activeTab, setActiveTab] = useState('meetings');
  const [showMeetingDialog, setShowMeetingDialog] = useState(false);
  const [showInformationDialog, setShowInformationDialog] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!id) {
      return navigate('/admin/sessions');
    }

    Session.fetch(id).then((data) => {
      setSession(data);
    });
    Meeting.fetchAllForSession(id).then((meetings) => {
      setMeetings(meetings);
    });
    InformationMedia.adminFetchAllForSession(id).then((informationMedia) => {
      setInformationMedia(informationMedia);
    });
  }, [id]);

  const refreshSession = () => {
    Session.fetch(id).then((data) => {
      setSession(data);
    });
  };

  const handleSubmit = (formData) => {
    Session.update({ sessionId: session.sessionId, ...formData }).then(
      (updatedSession) => {
        setSession(updatedSession);
        setIsEditable(false);
      }
    );
  };

  const createMeeting = (meeting) => {
    Meeting.create(session.sessionId, meeting).then((meeting) => {
      setMeetings((prev) => [...prev, meeting]);
      setShowMeetingDialog(false);
    });
  };

  const editMeeting = (meeting) => {
    Meeting.update(session.sessionId, meeting).then((updatedMeeting) => {
      setMeetings((prev) =>
        prev.map((m) => (m.id === updatedMeeting.id ? updatedMeeting : m))
      );
      setShowMeetingDialog(false);
    });
  };

  const createInformationMedia = (informationMediaItem) => {
    InformationMedia.create(
      informationMediaItem.sessionId,
      informationMediaItem.values
    ).then((newInformationMediaItem) => {
      setInformationMedia((prev) => [...prev, newInformationMediaItem]);
      setShowInformationDialog(false);
    });
  };

  return (
    <div className={styles.sessionDetail}>
      {state && (
        <div className={styles.navigationContainer}>
          {state.type === TABLE_TYPES.overview ? (
            <Link to="../../sessions/overview">
              {t('session.navigation.sessionOverview', {
                sessionsCount: state.sessionsCount,
              })}
            </Link>
          ) : (
            <Link to="../../sessions/archived">
              {t('session.navigation.sessionArchive', {
                sessionsCount: state.sessionsCount,
              })}
            </Link>
          )}
        </div>
      )}
      <div className={styles.sessionDetailContainer}>
        <div className={styles.formWrapper}>
          <div className={styles.formHeader}>
            <div>
              <h3 className={styles.headerText}>
                <b>{session?.sessionName || '-'}</b>
              </h3>
              <h3 className={styles.headerText}>
                {session ? t(`sessionTypes.${session.sessionType}`) : '-'}
              </h3>
            </div>
            {!isEditable && (
              <button
                className={styles.editButton}
                onClick={() => {
                  setIsEditable(true);
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
          </div>
          <SessionForm
            session={session}
            readOnly={!isEditable}
            withButtons={isEditable}
            type={SESSION_FORM_TYPES.edit}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsEditable(false);
            }}
            whiteFieldBackgrounds
          />
          <SessionActions session={session} refreshSession={refreshSession} />
        </div>
      </div>
      <div className={styles.tabView}>
        <Tab.Container
          defaultActiveKey="meetings"
          onSelect={(activeKey) => setActiveTab(activeKey)}
        >
          <Row className={styles.tabNavigationSection}>
            <Nav>
              <Container className={styles.navigationLinksContainer}>
                <div>
                  <Nav.Item>
                    <Nav.Link
                      className={combine(
                        styles.headerLinks,
                        activeTab === 'meetings' ? styles.active : null
                      )}
                      data-label={'Meetings'}
                      eventKey="meetings"
                    >
                      {t('session.detail.tabs.meetings.heading')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={combine(
                        styles.headerLinks,
                        activeTab === 'information' ? styles.active : null
                      )}
                      data-label={'Information & Guidelines'}
                      eventKey="information"
                    >
                      {t('session.detail.tabs.information.heading')}
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Container>
            </Nav>
          </Row>
          <Container className={styles.tab__content__container}>
            <Row>
              <Tab.Content>
                <Tab.Pane eventKey="meetings">
                  <MeetingsTable
                    meetings={meetings}
                    setMeetings={setMeetings}
                    onMeetingEdit={editMeeting}
                    sessionId={session?.sessionId}
                  />
                  <div className={styles.buttonRow}>
                    <Button
                      icon={faPlus}
                      iconBold={faPlusBold}
                      onClick={() => {
                        setShowMeetingDialog(true);
                      }}
                      text={t('session.detail.tabs.meetings.buttons.add')}
                      type={ButtonTypes.PRIMARY}
                    />
                    <Button
                      onClick={() => {
                        navigate('../../sessions/meetings');
                      }}
                      text={t('session.detail.tabs.meetings.buttons.all')}
                      type={ButtonTypes.SECONDARY}
                    />
                    {showMeetingDialog && (
                      <MeetingDialog
                        onSubmit={createMeeting}
                        onClose={() => {
                          setShowMeetingDialog(false);
                        }}
                        sessionId={session?.sessionId}
                      />
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="information">
                  <InformationTable
                    informationMedia={informationMedia}
                    setInformationMedia={setInformationMedia}
                  />
                  <div className={styles.buttonRow}>
                    <Button
                      icon={faPlus}
                      iconBold={faPlusBold}
                      onClick={() => {
                        setShowInformationDialog(true);
                      }}
                      text={t('session.detail.tabs.information.buttons.add')}
                      type={ButtonTypes.PRIMARY}
                    />
                    <Button
                      onClick={() => {
                        navigate('/admin/sessions/information-material');
                      }}
                      text={t('session.detail.tabs.information.buttons.all')}
                      type={ButtonTypes.SECONDARY}
                    />
                    {showInformationDialog && (
                      <InformationMaterialDialog
                        onClose={() => {
                          setShowInformationDialog(false);
                        }}
                        onSubmit={createInformationMedia}
                        currentSession={session}
                      />
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Container>
        </Tab.Container>
      </div>
    </div>
  );
};

export default SessionDetail;
