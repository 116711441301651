import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faCameraSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SCOPES } from '../../../config/permissions/roles';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import PermissionHandler from '../../../modules/permissionHandler';
import { translateCountryCode } from '../../../helpers/countryCodes';
import { useConfig } from '../../../hooks/useConfig';
import styles from './styles.module.scss';

const NameColumn = ({
  submission,
  onBookmark,
  onEntryClick,
  readOnly,
  awardType,
  isTabletView,
}) => {
  const [bookmark, setBookmark] = useState(submission.bookmarked);
  const { session } = useJuror();
  const { ENTRIES_TABLE } = useConfig();
  const { t } = useTranslation();
  const navigateToDetail = () => {
    onEntryClick(
      `../entry/${submission.submissionId}${
        submission.jurorId ? `/${submission.jurorId}` : ''
      } `,
      submission
    );
  };

  const handleBookmark = (e) => {
    e.stopPropagation();
    setBookmark(!bookmark);
    return onBookmark();
  };

  return (
    <div
      className={styles.nameColumnContainer}
      id={`submission_${submission.submissionId}`}
    >
      <PermissionHandler scopes={[SCOPES.canBookmarkSubmissions]}>
        <button
          className={combine(styles.bookmarkButton, bookmark && styles.active)}
          onClick={handleBookmark}
        >
          <FontAwesomeIcon
            className={combine(styles.icon, bookmark && styles.active)}
            icon={faBookmark}
          />
        </button>
      </PermissionHandler>
      <div className={styles.leftSection}>
        {submission?.submissionPrimaryMediaUrl ? (
          <Image
            src={submission.submissionPrimaryMediaThumbnailUrl}
            className={combine(styles.image, readOnly && styles.disabled)}
            onClick={navigateToDetail}
            loading="lazy"
          />
        ) : (
          <div
            className={combine(styles.image, styles.empty)}
            onClick={navigateToDetail}
          >
            <FontAwesomeIcon icon={faCameraSlash} size={'2x'} />
          </div>
        )}
      </div>
      <div className={styles.rightSection}>
        <div className={styles.nameContainer}>
          <span className={styles.entryName}>{submission.submissionName}</span>
          <span className={styles.disciplineName}>
            {submission.disciplineName}
          </span>
        </div>
        <div className={styles.categoryContainer}>
          <div className={styles.idContainer}>
            <span className={styles.title}>
              {t('jurorEntries.table.columns.name.id')}
            </span>
            <span className={styles.value}>{submission.submissionId}</span>
          </div>
          <div className={styles.idContainer}>
            <span className={styles.title}>
              {t(
                `jurorEntries.table.columns.name.category.${
                  session?.awardType || awardType || 'IF_DA'
                }`
              )}
            </span>
            <span className={styles.value}>{submission.categoryName}</span>
          </div>
          {isTabletView && ENTRIES_TABLE.COLUMNS.includes('country') && (
            <div className={styles.idContainer}>
              <span className={styles.title}>
                {t('jurorEntries.table.headings.country')}
              </span>
              <span className={styles.value}>
                {translateCountryCode(submission?.accountCountryIsoCode)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

NameColumn.propTypes = {
  onBookmark: PropTypes.func,
  submission: PropTypes.object,
  readOnly: PropTypes.bool,
  isTabletView: PropTypes.bool,
};

NameColumn.defaultProps = {
  onBookmark: () => {},
  submission: {},
  readOnly: false,
  isTabletView: false,
};

export default NameColumn;
