import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import styles from './styles.module.scss';
import { combine } from '../../../helpers/styles';

const GroupDropdown = ({ groups, selectedGroup, onSelect }) => {
  const { t } = useTranslation();

  const hasMultipleGroups = groups?.length > 1;

  return (
    <div className={styles.groupDropdown}>
      <button
        className={combine(
          styles.button,
          !hasMultipleGroups && styles.disabled
        )}
      >
        <span>{t('jurorDashboard.hero.group.title')}</span>
        {hasMultipleGroups && <FontAwesomeIcon icon={faChevronDown} />}
      </button>
      <select
        className={combine(
          styles.select,
          !hasMultipleGroups && styles.disabled
        )}
        onChange={(e) => {
          onSelect(e.target.value);
        }}
        value={selectedGroup}
      >
        {groups?.map((group) => (
          <option key={group.jurorGroupId} value={group.jurorGroupId}>
            {group.jurorGroupName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GroupDropdown;

GroupDropdown.propTypes = {
  groups: PropTypes.array,
  selectedGroup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
};

GroupDropdown.defaultProps = {
  groups: [],
  selectedGroup: '',
  onSelect: () => null,
};
