import pt from 'prop-types';

const IFDABadge = ({ year, className }) => {
  return (
    <svg
      viewBox="0 0 276.7 141.7"
      className={className}
      dangerouslySetInnerHTML={{
        __html: `
          <defs>
            <style>
              ${`.cls-11,.cls-31{fill:#fff}.cls-21{fill:#ec1d26}.cls-31{font-size:26px;font-family:Frutiger;font-weight:400;letter-spacing:0}`}
            </style>
          </defs>
          <path class="cls-11" d="M0 0h276.7v141.7H0z"/>
          <path class="cls-21" d="M6.8 6.7H135v128.2H6.8z"/>
          <path class="cls-11" d="M33.8 108H54V60.7H33.8Zm10.1-75.6a11.5 11.5 0 1 0 11.5 11.5 11.57 11.57 0 0 0-11.5-11.5Zm43.8 1.3V54H108V33.7Zm-27 74.3H81V33.7H60.7Zm27-27H108V60.7H87.7Z"/>
          <path class="cls-21" d="M141.7 6.7h128.2v128.2H141.7z"/>
          <path class="cls-11" d="M164.1 33.8c6.2 0 10.8 2.6 10.8 9.7 0 6.8-4.8 9.6-10.8 9.6h-5V33.8Zm-1.3 16.4h1c4.1 0 7.3-1.7 7.3-6.6 0-5.2-3.2-6.7-7.3-6.7h-1ZM178.3 33.8h10.8V37H182v4.6h6.6v3.1H182v5.1h7.3V53h-10.9ZM202.8 37.4a9.46 9.46 0 0 0-3.7-.8c-1.9 0-3.2.9-3.2 2.2s.6 1.9 3.3 3c3.4 1.3 4.8 2.9 4.8 5.8 0 3.7-2.7 6-6.9 6a15.58 15.58 0 0 1-4.8-.8l.3-3.4a9.16 9.16 0 0 0 4.3 1.1c1.9 0 3.3-.9 3.3-2.4s-.8-2.2-3.5-3.3c-3.2-1.2-4.6-2.8-4.6-5.5 0-3.4 2.6-5.6 6.6-5.6a13.08 13.08 0 0 1 4.4.7ZM207.4 33.8h3.7v19.3h-3.7ZM229.7 37.9a11.7 11.7 0 0 0-4.9-1.1c-3.9 0-6.4 2.6-6.4 6.7s2.3 6.7 6.3 6.7a7.11 7.11 0 0 0 2.1-.3V45H223v-3h7.4v10.3a18.56 18.56 0 0 1-6.1 1c-5.6 0-9.8-3.8-9.8-9.9s4-9.8 10.4-9.8a17.92 17.92 0 0 1 5.1.7ZM246.4 33.8h3.3v19.3H245l-4.7-9.5c-.9-1.9-2.1-4.7-2.6-5.9.1 1.2.1 4.7.1 6.4v9h-3.3V33.8h4.9l4.6 9.5c.7 1.4 1.9 4.3 2.5 5.6h.1c-.1-1.4-.2-5.1-.2-7.1ZM175.6 80.6h-4l-1.6-4.5h-7.6l-1.6 4.5H157l7.2-19.3h4.2Zm-8.1-11.9c-.4-1.2-1-3.4-1.3-4.4h-.1c-.3 1-.9 3.2-1.3 4.4l-1.5 4.5h5.7ZM188.9 67.7c-.2-.9-.6-2.6-.7-3.6h-.1a33.74 33.74 0 0 1-.6 3.6l-2.7 12.9h-4.7l-5-19.3h3.9l2.6 11.6c.1.6.7 3.4.9 4.7h.1c.2-1.3.7-4.1.8-4.7l2.4-11.6h4.9l2.5 11.6a37.75 37.75 0 0 1 .8 4.7h.1c.2-1.3.7-4 .9-4.7l2.6-11.6h3.7l-4.9 19.3h-4.6ZM219.3 80.6h-4l-1.6-4.5h-7.6l-1.6 4.5h-3.8l7.2-19.3h4.2Zm-8.1-11.9c-.4-1.2-1-3.4-1.3-4.4h-.1c-.3 1-.9 3.2-1.3 4.4l-1.5 4.5h5.7ZM221.7 61.3h5.3c4.5 0 7.5 1 7.5 5.1a4.38 4.38 0 0 1-3.8 4.6c1.3.4 1.8 1.1 2.3 2.6l2.8 7h-4l-2-5.5c-.8-2-1.1-2.5-3.4-2.5h-1v8h-3.7Zm3.7 8.3h1.1c2.9 0 4.2-.8 4.2-2.7 0-2.1-1.6-2.5-4.2-2.5h-1.1ZM244 61.3c6.2 0 10.8 2.6 10.8 9.7 0 6.8-4.8 9.6-10.8 9.6h-5V61.3Zm-1.3 16.3h1c4.1 0 7.3-1.7 7.3-6.7 0-5.2-3.2-6.7-7.3-6.7h-1Z"/>
          <text class="cls-31" transform="translate(157 108.95)">${year}</text>
        `,
      }}
    />
  );
};

IFDABadge.propTypes = {
  year: pt.number,
  className: pt.string,
};

IFDABadge.defaultProps = {
  className: '',
  year: 2023,
};

export default IFDABadge;
