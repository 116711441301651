import { faCalculator, faDownload } from '@fortawesome/pro-light-svg-icons';
import {
  faCalculator as faCalculatorBold,
  faDownload as faDownloadBold,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../../../../App';
import Button, { ButtonTypes } from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import EvaluationStatusTable from '../../../../components/evaluationStatusTable';
import LinkButton from '../../../../components/linkButton';
import JurorGroup from '../../../../models/jurorGroup';
import JurorGroupEvaluationsOverview from '../../../../models/jurorGroupEvaluationsOverview';
import Session from '../../../../models/session';
import AwardStatistic from '../../../../modules/awardStatistic';
import Hero from '../../../../modules/hero';
import styles from './styles.module.scss';

const EvaluationStatus = () => {
  const [groupEvaluationsOverview, setGroupEvaluationsOverview] =
    useState(null);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [session, setSession] = useState(null);
  const [sessions, setSessions] = useState([]);
  const sessionOptions = sessions.map((s) => ({
    value: s.sessionId,
    text: s.sessionName,
  }));
  const { t } = useTranslation();
  const navigate = useNavigate();

  const maxPoints = groupEvaluationsOverview
    ? groupEvaluationsOverview.maxPointsPerCriterion *
      groupEvaluationsOverview.numCriteria
    : 0;

  useEffect(() => {
    Session.fetchAll({ archived: false }).then((data) => {
      setSessions(data);
    });
  }, []);

  useEffect(async () => {
    if (!session) return;
    setGroupsLoading(true);
    const data = await JurorGroupEvaluationsOverview.fetchForSession(
      session?.sessionId
    );
    setGroupEvaluationsOverview(data);
    const groupsData = await JurorGroup.fetchAll(session?.sessionId);
    // include numNominationsInGroup so it can be displayed in table (ask backend to include it?)
    setGroups(
      groupsData.map((group) => ({
        ...group,
        numNominationsInGroup:
          data.jurorGroups.find((el) => el.jurorGroupId === group.jurorGroupId)
            ?.numNominationsInGroup || 0,
        numSubmissionsWithSuggestedForNomination:
          data.jurorGroups.find((el) => el.jurorGroupId === group.jurorGroupId)
            ?.numSubmissionsWithSuggestedForNomination || 0,
      }))
    );
    setGroupsLoading(false);
  }, [session]);

  const handleSessionChange = (val) => {
    setSession(sessions.find((session) => session.sessionId == val));
  };

  return (
    <div className={styles.sessionsOverview}>
      <Hero
        title={t('sessions.evaluation.title')}
        rightSection={
          <Dropdown
            options={sessionOptions}
            value={session?.sessionId}
            onChange={handleSessionChange}
            label={t('jurors.overview.dropdownLabel')}
            className={'w-100'}
          />
        }
      />
      {session && (
        <AwardStatistic
          groupEvaluations={groupEvaluationsOverview}
          isLoading={groupsLoading}
          session={session}
        />
      )}
      <Container className={'py-5'}>
        <Button
          text={t('sessions.evaluation.tresholdCalculator')}
          type={ButtonTypes.PRIMARY}
          icon={faCalculator}
          iconBold={faCalculatorBold}
          onClick={() =>
            navigate('/admin/sessions/threshold-calculator', {
              state: { sessionId: session?.sessionId },
            })
          }
        />
        <EvaluationStatusTable
          groups={groups}
          loading={groupsLoading}
          session={session}
          maxPoints={maxPoints}
        />
        <div className={styles.actions}>
          <div>
            {session && (
              <LinkButton
                text={t('sessions.evaluation.exportScores')}
                type={ButtonTypes.PRIMARY}
                icon={faDownload}
                iconBold={faDownloadBold}
                link={`${BACKEND_URL}/if-api/jury-v2/session/${session?.sessionId}/jurorGroupsOverview/csv`}
                isDownload
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EvaluationStatus;
