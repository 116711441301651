import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useJuror } from '../../../hooks/useAuth';
import Messages from './messages';
import Dialog from '../../dialog';
import styles from './styles.module.scss';
import Button, { ButtonTypes } from '../../button';

const ChatWidget = () => {
  const [showAllMessages, setShowAllMessages] = useState(false);
  const { jurorGroup } = useJuror();
  const { t } = useTranslation();

  const onModalClose = () => {
    setShowAllMessages(false);
  };

  const onModalOpen = () => {
    setShowAllMessages(true);
  };

  return (
    <>
      {showAllMessages && (
        <Dialog
          title={t('jurorDashboard.chat.title')}
          onClose={onModalClose}
          noNav
          lg={10}
          body={<Messages autoRefresh limitMessagesAmount={false} />}
          footer={null}
        />
      )}
      <div className={styles.container}>
        <div className={styles.header}>
          <h3 className="mb-0">
            {jurorGroup?.jurorGroupName} {t('jurorDashboard.chat.title')}
          </h3>
          <Button
            text={t('jurorDashboard.chat.allButton')}
            type={ButtonTypes.SECONDARY}
            onClick={onModalOpen}
          />
        </div>
        <Messages />
      </div>
    </>
  );
};

export default ChatWidget;
