export const EVALUATING_ROLES = {
  juror: 'JUROR',
  groupLeader: 'GROUP_LEADER',
  chairPerson: 'CHAIR_PERSON',
};

export const ROLES = {
  ...EVALUATING_ROLES,
  staff: 'IF_STAFF',
};

export const SCOPES = {
  juror: 'juror',
  groupLeader: 'group-leader',
  chairPerson: 'chair-person',
  staff: 'staff',
  canSeeGroupMembers: 'can-see-group-members',
  canBookmarkSubmissions: 'can-bookmark-submissions',
  canLetChairpersonDecide: 'can-let-chairperson-decide',
  canSeeScoreAlert: 'can-see-score-alert',
  canNominateSubmission: 'can-nominate-submission',
  canSuggestSubmissionForNomination: 'can-suggest-submission-for-nomination',
  canEvaluateSubmission: 'can-evaluate-submission',
  canEndDiscussion: 'can-end-discussion',
  canViewIndividualScores: 'can-view-individual-scores',
  canAccessJTWhenClosed: 'can-access-jt-when-closed',
};

export const PERMISSIONS = {
  [ROLES.juror]: [
    SCOPES.juror,
    SCOPES.canBookmarkSubmissions,
    SCOPES.canLetChairpersonDecide,
    SCOPES.canSuggestSubmissionForNomination,
    SCOPES.canEvaluateSubmission,
    SCOPES.canSeeScoreAlert,
  ],
  [ROLES.groupLeader]: [
    SCOPES.groupLeader,
    SCOPES.canBookmarkSubmissions,
    SCOPES.canLetChairpersonDecide,
    SCOPES.canSeeScoreAlert,
    SCOPES.canSuggestSubmissionForNomination,
    SCOPES.canNominateSubmission,
    SCOPES.canEvaluateSubmission,
    SCOPES.canEndDiscussion,
  ],
  [ROLES.chairPerson]: [
    SCOPES.chairPerson,
    SCOPES.canSeeScoreAlert,
    SCOPES.canSuggestSubmissionForNomination,
    SCOPES.canEvaluateSubmission,
    SCOPES.canEndDiscussion,
    SCOPES.canBookmarkSubmissions,
    SCOPES.canViewIndividualScores,
  ],
  [ROLES.staff]: [
    SCOPES.staff,
    SCOPES.canBookmarkSubmissions,
    SCOPES.canViewIndividualScores,
    SCOPES.canSeeScoreAlert,
    SCOPES.canAccessJTWhenClosed,
  ],
};

export const VOTING_ROLES = [ROLES.juror, ROLES.groupLeader, ROLES.chairPerson];

export const hasPermission = (scope, role) => {
  if (!PERMISSIONS?.[role]) return false;

  return PERMISSIONS[role].includes(scope);
};
