import { faMessageCode } from '@fortawesome/pro-light-svg-icons';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../../../config/permissions/roles';
import { useJuror } from '../../../../hooks/useAuth';
import { AWARD_TYPES } from '../../../../models/award';
import Submission from '../../../../models/submission';
import Button, { ButtonSizes, ButtonTypes } from '../../../button';
import Dialog from '../../../dialog';
import styles from './styles.module.scss';
import { isAwardType } from '../../../../helpers/awardTypes';
import { combine } from '../../../../helpers/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { JUROR_GROUP_PHASES } from '../../../../models/jurorGroup';

const MAX_CHARS = 280;
const MIN_CHARS = {
  ['DEFAULT']: 1,
  [AWARD_TYPES.IF_DTA]: 15,
};

const CommentModal = ({
  submission,
  onClose,
  onSubmit,
  visible,
  isAdminView,
  onDelete,
  isDiscussed,
  onSetDiscussed,
}) => {
  const { t } = useTranslation();
  const [initialComment, setInitialComment] = useState('');
  const [comment, setComment] = useState('');
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);
  const { role, session, juror, jurorGroup, isPreview } = useJuror();

  const hasMinCharCount = MIN_CHARS[session?.awardType]
    ? MIN_CHARS[session.awardType] <= comment.length
    : MIN_CHARS['DEFAULT'] <= comment.length;

  const hasComments = !!submission?.evaluations?.some(
    (evaluation) => evaluation?.comment
  );

  useEffect(() => {
    if (!visible) return setComment('');
    if (isAdminView) {
      Submission.fetchJuryStatement(
        submission.evaluations[0]?.jurorGroupId,
        submission.submissionId
      ).then((juryStatement) => {
        setComment(juryStatement || '');
      });
    }
    if (isAwardType(AWARD_TYPES.IF_DA, session)) {
      const currentJurorEvaluation = submission?.evaluations?.find(
        (evaluation) =>
          evaluation.jurorId === submission?.jurorId ||
          evaluation.jurorId === juror?.jurorId
      );

      const comment =
        currentJurorEvaluation?.comment || submission?.comment || '';

      setComment(comment);
      setInitialComment(comment);
    }
    if (
      isAwardType(AWARD_TYPES.IF_SIP, session) &&
      (submission.suggestedForNomination || submission.hasJuryStatement)
    ) {
      Submission.fetchJuryStatement(
        jurorGroup.jurorGroupId,
        submission.submissionId
      ).then((juryStatement) => {
        setComment(juryStatement || '');
      });
    }

    if (
      isAwardType(AWARD_TYPES.IF_DTA, session) &&
      (submission?.hasJuryStatement ||
        submission?.suggestedForNomination ||
        submission?.suggestedForNominationExternal)
    ) {
      Submission.fetchJuryStatement(
        jurorGroup.jurorGroupId,
        submission.submissionId
      ).then((juryStatement) => {
        setComment(juryStatement || '');
      });
    }
  }, [setComment, visible, submission, juror, jurorGroup]);

  const handleSubmit = () => {
    onSubmit({ comment });
  };

  const handleCommentChange = (e) => {
    if (e.target.value.length > MAX_CHARS || isAdminView) return;
    setComment(e.target.value);
    setHasEdited(true);
  };

  if (!visible) return null;

  return (
    <Dialog
      title={t(
        `jurorEntries.table.columns.actions.discuss.dialog.title.${
          session?.awardType || 'IF_SIP'
        }`
      )}
      titleIcon={faMessageCode}
      onClose={onClose}
      noNav
      body={
        <>
          {!isAdminView && !isAwardType(AWARD_TYPES.IF_SIP, session) && (
            <div
              className={combine(
                styles.commentListWrapper,
                role === ROLES.groupLeader && isDiscussed && styles.discussed
              )}
            >
              {submission.evaluations
                ?.filter((evaluation) => !!evaluation?.comment)
                .map((evaluation) => (
                  <div className={styles.commentWrapper}>
                    <span className={styles.name}>
                      {evaluation?.firstname} {evaluation?.lastname}
                    </span>
                    <span className={styles.comment}>
                      {evaluation?.comment}
                    </span>
                  </div>
                ))}
            </div>
          )}
          {jurorGroup?.jurorGroupPhase === JUROR_GROUP_PHASES.discussionPhase &&
            role !== ROLES.juror &&
            hasComments && (
              <div className={styles.discussionContainer}>
                {isDiscussed ? (
                  <div className={styles.discussionMessage}>
                    <span>
                      {t(
                        'jurorEntries.table.columns.actions.discuss.dialog.discussionMessage'
                      )}
                    </span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                ) : (
                  <Button text="Discussed" onClick={onSetDiscussed} />
                )}
              </div>
            )}
          <textarea
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            className={styles.textarea}
            readOnly={isPreview}
            disabled={isConfirmationVisible}
          />
          <div
            className={combine(
              styles.charsContainer,
              isAwardType(AWARD_TYPES.IF_DTA, session) &&
                hasEdited &&
                !hasMinCharCount &&
                styles.invalid
            )}
          >
            {isAwardType(AWARD_TYPES.IF_DTA, session) ? (
              <span>
                {t(
                  'jurorEntries.table.columns.actions.discuss.dialog.hintMinMaxChars'
                )}
              </span>
            ) : (
              <span>
                {t(
                  'jurorEntries.table.columns.actions.discuss.dialog.hintMaxChars'
                )}
              </span>
            )}
            <span className={'d-flex justify-content-end'}>{`${
              comment?.length || 0
            } / 280`}</span>
          </div>
          {isConfirmationVisible && (
            <div className={styles.confirmationContainer}>
              <p className={styles.message}>
                {t(
                  `jurorEntries.table.columns.actions.discuss.dialog.deleteConfirmation.${session?.awardType}`
                )}
              </p>
              <div className={styles.buttonRow}>
                <Button
                  text={t(
                    'jurorEntries.table.columns.actions.discuss.dialog.buttons.close'
                  )}
                  size={ButtonSizes.LARGE}
                  type={ButtonTypes.SECONDARY}
                  onClick={() => {
                    setIsConfirmationVisible(false);
                  }}
                />
                <Button
                  text={t(
                    'jurorEntries.table.columns.actions.discuss.dialog.buttons.delete'
                  )}
                  type={ButtonTypes.TERTIARY}
                  size={ButtonSizes.LARGE}
                  onClick={() => {
                    onDelete();
                    setIsConfirmationVisible(false);
                  }}
                />
              </div>
            </div>
          )}
        </>
      }
      footer={
        !isConfirmationVisible && (
          <>
            <div
              className={combine(
                styles.modalFooter,
                isAwardType(AWARD_TYPES.IF_DTA, session) && styles.spaceBetween,
                isAwardType(AWARD_TYPES.IF_DA, session) && styles.spaceBetween
              )}
            >
              {isAwardType(AWARD_TYPES.IF_DTA, session) && (
                <div>
                  <Button
                    text={t(
                      'jurorEntries.table.columns.actions.discuss.dialog.buttons.delete'
                    )}
                    type={ButtonTypes.TERTIARY}
                    size={ButtonSizes.LARGE}
                    onClick={() => {
                      setIsConfirmationVisible(true);
                    }}
                    disabled={
                      !submission.suggestedForNomination ||
                      (isAwardType(AWARD_TYPES.IF_DTA, session) &&
                        role === ROLES.staff)
                    }
                  />
                </div>
              )}
              {isAwardType(AWARD_TYPES.IF_DA, session) && (
                <div>
                  <Button
                    text={t(
                      'jurorEntries.table.columns.actions.discuss.dialog.buttons.delete'
                    )}
                    type={ButtonTypes.TERTIARY}
                    size={ButtonSizes.LARGE}
                    onClick={() => {
                      setIsConfirmationVisible(true);
                    }}
                    disabled={!initialComment}
                  />
                </div>
              )}
              <div className={styles.rightButtonGroup}>
                <Button
                  text={t(
                    'jurorEntries.table.columns.actions.discuss.dialog.buttons.cancel'
                  )}
                  type={ButtonTypes.SECONDARY}
                  size={ButtonSizes.LARGE}
                  onClick={onClose}
                />
                <Button
                  text={t(
                    'jurorEntries.table.columns.actions.discuss.dialog.buttons.save'
                  )}
                  disabled={
                    !hasMinCharCount ||
                    initialComment === comment ||
                    isAdminView ||
                    isPreview ||
                    (isAwardType(AWARD_TYPES.IF_DTA, session) &&
                      role === ROLES.staff)
                  }
                  type={ButtonTypes.PRIMARY}
                  size={ButtonSizes.LARGE}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </>
        )
      }
    />
  );
};

CommentModal.propTypes = {
  submission: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  isAdminView: PropTypes.bool,
  onDelete: PropTypes.func,
  isDiscussed: PropTypes.bool,
  onSetDiscussed: PropTypes.func,
};

CommentModal.defaultProps = {
  submission: null,
  onClose: () => null,
  onSubmit: () => null,
  visible: false,
  isAdminView: false,
  onDelete: () => null,
  isDiscussed: false,
  onSetDiscussed: () => null,
};

export default CommentModal;
