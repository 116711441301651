import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../footer';
import Navigation from '../navigation';

const Layout = ({ type }) => {
  return (
    <>
      <div className={'content'}>
        <Navigation type={type} />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
