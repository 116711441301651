import * as _ from 'lodash';
import { translateCountryCode } from '../../helpers/countryCodes';
import { ROLES } from '../../config/permissions/roles';

export const EVALUATION_STATUS = {
  toBeDecidedByChairperson: 'TO_BE_DECIDED_BY_CHAIRPERSON',
  decidedByChairperson: 'DECIDED_BY_CHAIRPERSON',
  toBeDecided: 'TO_BE_DECIDED',
  readOnly: 'READ_ONLY',
  decided: 'DECIDED',
};

class Submission {
  constructor(
    submissionId,
    submissionName,
    submissionPrimaryMediaUrl,
    submissionPrimaryMediaThumbnailUrl,
    categoryName,
    disciplineName,
    submissionType,
    fields,
    maxPointsPerCriterion,
    pointsStep,
    points,
    comment,
    bookmarked,
    suggestedForNomination,
    discussInGroup,
    chairPersonMustDecide,
    scoresFromPriorSessions,
    nominated
  ) {
    this.submissionId = submissionId;
    this.submissionName = submissionName;
    this.submissionPrimaryMediaUrl = submissionPrimaryMediaUrl;
    this.submissionPrimaryMediaThumbnailUrl =
      submissionPrimaryMediaThumbnailUrl;
    this.categoryName = categoryName;
    this.disciplineName = disciplineName;
    this.submissionType = submissionType;
    this.fields = fields;
    this.maxPointsPerCriterion = maxPointsPerCriterion;
    this.pointsStep = pointsStep;
    this.points = points;
    this.comment = comment;
    this.bookmarked = bookmarked;
    this.suggestedForNomination = suggestedForNomination;
    this.discussInGroup = discussInGroup;
    this.chairPersonMustDecide = chairPersonMustDecide;
    this.scoresFromPriorSessions = scoresFromPriorSessions;
    this.nominated = nominated;
  }

  static from = (submission) => {
    if (!submission) return null;

    const fields = submission.fields?.reduce((prev, field) => {
      prev[field.name] = field.value;
      return prev;
    }, {});

    return new Submission(
      submission.submissionId,
      submission.submissionName,
      submission.submissionPrimaryMediaUrl,
      submission.submissionPrimaryMediaThumbnailUrl,
      submission.categoryName,
      submission.disciplineName,
      submission.submissionType,
      fields,
      submission.maxPointsPerCriterion,
      submission.pointsStep,
      submission.points,
      submission.comment,
      submission.bookmarked,
      submission.suggestedForNomination,
      submission.discussInGroup,
      submission.chairPersonMustDecide,
      submission.scoresFromPriorSessions,
      submission.nominated
    );
  };

  static of = (submissions) => {
    return submissions.map((submission) => Submission.from(submission));
  };

  static fetch = async (
    jurorGroupId,
    submissionId,
    impersonatingJurorId = null
  ) => {
    let url = `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/evaluation`;

    if (impersonatingJurorId) {
      url += `?jurorId=${impersonatingJurorId}`;
    }

    return fetch(url)
      .then((response) => response.json())
      .then((submission) =>
        Submission.from({ ...submission, ...submission.submission })
      )
      .catch((err) => console.error(err));
  };

  static updateEvaluation = async (
    jurorGroupId,
    submissionId,
    criterionId,
    points,
    impersonatingJurorId = null
  ) => {
    let url = `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/evaluation/criterion/${criterionId}`;

    if (impersonatingJurorId) {
      url += `?jurorId=${impersonatingJurorId}`;
    }

    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ points }),
    })
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static deleteEvaluation = async (jurorGroupId, submissionId, criterionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/evaluation/criterion/${criterionId}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static setBookmark = async (value, jurorGroupId, submissionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/bookmark`,
      {
        method: value ? 'POST' : 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static setSuggestForNomination = async (
    value,
    jurorGroupId,
    submissionId,
    impersonatingJurorId = null
  ) => {
    let url = `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/suggestForNomination`;

    if (impersonatingJurorId) {
      url += `?jurorId=${impersonatingJurorId}`;
    }

    return fetch(url, {
      method: value ? 'POST' : 'DELETE',
    }).then((response) => response.json());
  };

  static setDiscussInGroup = async (value, jurorGroupId, submissionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/discussInGroup`,
      {
        method: value ? 'POST' : 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static setChairPersonMustDecide = async (
    value,
    jurorGroupId,
    submissionId
  ) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/chairPersonMustDecide`,
      {
        method: value ? 'POST' : 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static setComment = async (
    comment,
    jurorGroupId,
    submissionId,
    impersonatingJurorId = false
  ) => {
    let url = `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/comment`;

    if (impersonatingJurorId) {
      url += `?jurorId=${impersonatingJurorId}`;
    }

    return fetch(url, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'text/plain' }),
      body: comment,
    })
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static deleteComment = async (jurorGroupId, submissionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/comment`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static setCommentsDiscussed = async ({ jurorGroupId, submissionId }) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/discussed`,
      {
        method: 'POST',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static setDiscussedInGroup = async (value, jurorGroupId, submissionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/discussedInGroup`,
      {
        method: value ? 'POST' : 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static setNominated = async (value, jurorGroupId, submissionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/nominated`,
      {
        method: value ? 'POST' : 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static fetchJuryStatement = async (jurorGroupId, submissionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/juryStatement`
    )
      .then((response) => (response.ok ? response.text() : ''))
      .catch((err) => console.error(err));
  };

  static setJuryStatement = async ({
    juryStatement,
    jurorGroupId,
    submissionId,
  }) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/juryStatement`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'text/plain',
        },
        body: juryStatement,
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static deleteJuryStatement = async ({ jurorGroupId, submissionId }) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/juryStatement`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static getPoints = async (jurorGroupId, submissionId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submission/${submissionId}/points`,
      {
        method: 'GET',
      }
    )
      .then(async (response) => {
        // jurors points can be a null-filled array, which causes an error in the application.
        // set the null values to 0 as Michel Seuthe requested
        const json = await response.json();
        const jurors = json.jurors?.map((juror) => {
          return {
            ...juror,
            points: juror.points?.map((val) => val || { points: 0 }),
          };
        });
        return {
          ...json,
          jurors,
        };
      })
      .catch((err) => console.error(err));
  };

  static transferPointsFromPreselection = async (
    submission,
    jurorGroupId,
    impersonatingJurorId = null
  ) => {
    if (!submission.scoresFromPriorSessions.length) return;

    const scoresFromPriorSession = submission.scoresFromPriorSessions[0];

    const criteriaScores = scoresFromPriorSession.criteria.map((criterion) => ({
      id: criterion.id,
      score:
        Math.round(
          criterion.totalPoints /
            scoresFromPriorSession.numDecidedEvaluations /
            submission.pointsStep
        ) * submission.pointsStep,
    }));

    let results = [];

    for (const criterionScore of criteriaScores) {
      const res = await Submission.updateEvaluation(
        jurorGroupId,
        submission.submissionId,
        criterionScore.id,
        criterionScore.score,
        impersonatingJurorId
      );

      results.push(res);
    }

    results = results
      .map((isOk, index) => (isOk ? criteriaScores[index] : null))
      .filter((el) => !!el);

    return results;
  };

  static filter = (entries, filterKeys, juror) => {
    if (filterKeys.some((key) => key.includes('disciplineName'))) {
      const disciplineFilterName = filterKeys
        .find((key) => key.includes('disciplineName'))
        .split('__')[1];
      entries = entries.filter(
        (entry) => entry.disciplineName === disciplineFilterName
      );
    }
    if (filterKeys.includes('toBeDecided')) {
      entries = entries.filter((entry) => {
        const jurorGroupId = entry.evaluations.length
          ? entry.evaluations[0].jurorGroupId
          : 0;

        const role = juror.jurorGroups.find(
          (group) => group.jurorGroupId === jurorGroupId
        )?.role;

        if (role === ROLES.chairPerson) {
          return entry.evaluations.some(
            (evaluation) =>
              evaluation.status === EVALUATION_STATUS.toBeDecidedByChairperson
          );
        }

        const evaluation = entry.evaluations.find(
          (evaluation) =>
            evaluation.jurorId === entry?.jurorId ||
            evaluation.jurorId === juror?.jurorId
        );

        return (
          evaluation?.status === EVALUATION_STATUS.toBeDecided ||
          evaluation?.status === EVALUATION_STATUS.toBeDecidedByChairperson
        );
      });
    }
    if (filterKeys.some((key) => key.includes('jurorGroup'))) {
      const jurorGroupFilterName = filterKeys
        .find((key) => key.includes('jurorGroup'))
        .split('__')[1];
      entries = entries.filter((entry) => {
        return entry.evaluations.some(
          (evaluation) => evaluation.jurorGroupName === jurorGroupFilterName
        );
      });
    }
    if (filterKeys.some((key) => key.includes('presentationForm'))) {
      const presentationFormFilterValue = filterKeys
        .find((key) => key.includes('presentationForm'))
        .split('__')[1]
        .toLowerCase();
      entries = entries.filter((entry) => {
        return entry.extraFields.some(
          (field) =>
            field.name === 'presentationForm' &&
            field.value.toLowerCase() === presentationFormFilterValue
        );
      });
    }
    if (filterKeys.some((key) => key.includes('categoryName'))) {
      const categoryNameFilter = filterKeys
        .find((key) => key.includes('categoryName'))
        .split('__')[1];
      entries = entries.filter(
        (entry) => entry.categoryName === categoryNameFilter
      );
    }
    if (filterKeys.includes('status')) {
      entries = entries.filter((entry) => entry.status);
    }
    if (filterKeys.includes('suggestedForNomination')) {
      entries = entries.filter(
        (entry) =>
          entry.evaluations.some((e) => e.suggestedForNomination) ||
          entry.suggestedForNomination
      );
    }
    if (filterKeys.includes('nominated')) {
      entries = entries.filter((entry) => entry.nominated);
    }
    if (filterKeys.includes('chairPersonMustDecide')) {
      entries = entries.filter((entry) =>
        entry.evaluations.some(
          (evaluation) =>
            evaluation.status === EVALUATION_STATUS.toBeDecidedByChairperson ||
            evaluation.status === EVALUATION_STATUS.decidedByChairperson
        )
      );
    }
    if (filterKeys.includes('discussInGroup')) {
      entries = entries.filter(
        (entry) =>
          entry.numComments > 0 &&
          entry.evaluations.some(
            (evaluation) => evaluation.comment && !evaluation.discussed
          )
      );
    }
    if (filterKeys.includes('scoreAlert')) {
      entries = entries.filter(
        (entry) => entry.scoreAlertA || entry.scoreAlertB || entry.scoreAlertC
      );
    }
    if (filterKeys.includes('bookmarked')) {
      entries = entries.filter((entry) => entry.bookmarked);
    }
    if (filterKeys.some((key) => key.includes('country'))) {
      const countryFilterName = filterKeys
        .find((key) => key.includes('country'))
        .split('__')[1];
      entries = entries.filter(
        (entry) =>
          translateCountryCode(entry.accountCountryIsoCode) ===
          countryFilterName
      );
    }
    if (filterKeys.includes('hasZeroEvaluationCategory')) {
      entries = entries.filter((entry) =>
        entry?.evaluations?.some((evaluation) => evaluation?.hasZeroValues)
      );
    }
    return entries;
  };

  static sort = (entries, order = 'asc') => {
    return _.orderBy(entries, ['averageScore'], order);
  };

  static search = (entries, search = '') => {
    search = search.toLowerCase();

    return entries.filter((submission) => {
      const submissionNameMatch = submission.submissionName
        .toLowerCase()
        .includes(search);

      const submissionCategoryMatch = submission.categoryName
        .toLowerCase()
        .includes(search);

      const disciplineNameMatch = submission?.disciplineName
        ?.toLowerCase()
        .includes(search);

      const submissionTypeMatch = submission.submissionType
        .toLowerCase()
        .includes(search);

      const submissionIdMatch = submission.submissionId
        .toString()
        .includes(search);

      return (
        submissionNameMatch ||
        submissionCategoryMatch ||
        disciplineNameMatch ||
        submissionTypeMatch ||
        submissionIdMatch
      );
    });
  };

  static getEvaluations = (submissionId) => {
    return fetch(`/if-api/jury-v2/submission/${submissionId}/evaluations`)
      .then((response) => (response.ok ? response.json() : ''))
      .catch((err) => console.error(err));
  };
}

export default Submission;
