class JurorGroupJuror {
  constructor(
    jurorId,
    firstname,
    lastname,
    profilePictureUrl,
    profilePictureThumbnailUrl,
    role
  ) {
    this.jurorId = jurorId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.profilePictureUrl = profilePictureUrl;
    this.profilePictureThumbnailUrl = profilePictureThumbnailUrl;
    this.role = role;
  }

  static from = (jurorGroupJuror) => {
    if (!jurorGroupJuror) return null;

    return new JurorGroupJuror(
      jurorGroupJuror.jurorId,
      jurorGroupJuror.firstname,
      jurorGroupJuror.lastname,
      jurorGroupJuror.profilePictureUrl,
      jurorGroupJuror.profilePictureThumbnailUrl,
      jurorGroupJuror.role
    );
  };

  static of = (jurorGroupJurors) => {
    return jurorGroupJurors?.map((jurorGroupJuror) =>
      JurorGroupJuror.from(jurorGroupJuror)
    );
  };

  static fetchAll = async (jurorGroupId) => {
    if (!jurorGroupId) return [];

    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/jurors`)
      .then((response) => response.json())
      .then((jurors) => JurorGroupJuror.of(jurors))
      .catch((err) => {
        console.error(err);
      });
  };
}

export default JurorGroupJuror;
