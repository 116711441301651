import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';

const Pill = ({ text, handleClick }) => {
  return (
    <div role="button" className={styles.pill} onClick={handleClick}>
      <FontAwesomeIcon icon={faXmark} className={styles.icon} />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

Pill.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
};

Pill.defaultProps = {
  text: 'Button',
  handleClick: () => {},
};

export default Pill;
