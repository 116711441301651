class InformationMediaResource {
  constructor(file) {
    this.file = file;
  }

  static upload = async (sessionId, file) => {
    const formData = new FormData();
    formData.append('file', file, file.name.replace(/ /g, "_"));

    return fetch(
      `/if-api/jury-v2/session/${sessionId}/informationMedia/resource`,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((err) => console.error(err));
  };

  static delete = async (sessionId, url) => {
    fetch(`/if-api/jury-v2/session/${sessionId}/informationMedia/resource`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url }),
    }).catch((err) => console.error(err));
  };
}

export default InformationMediaResource;
