import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Carousel as BsCarousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import { ROLES } from '../../../config/permissions/roles';
import Dropdown from './dropdown';
import MainStatistic from './mainStatistic';
import ProgressStatistic from './progressStatistic';
import ScoreStatistic from './scoreStatistic';
import EvaluationStatistic from '../../../models/evaluationStatistic';
import styles from './styles.module.scss';
import { AWARD_TYPES } from '../../../models/award';
import { isAwardType } from '../../../helpers/awardTypes';
import { SESSION_TYPES } from '../../../models/session';
import { JUROR_GROUP_PHASES } from '../../../models/jurorGroup';

const StatisticWidget = ({ customJurorGroup }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [statistic, setStatistic] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [filter, setFilter] = useState({
    id: '',
    name: '',
  });
  const { role, juror, jurorGroup, session } = useJuror();
  const bigStats = !!customJurorGroup;

  useEffect(() => {
    if (customJurorGroup) {
      setIsLoading(true);
      EvaluationStatistic.fetchForGroup(customJurorGroup.jurorGroupId).then(
        (statisticData) => {
          setStatistic(statisticData);
          setIsLoading(false);
        }
      );
    } else if (jurorGroup) {
      setIsLoading(true);
      EvaluationStatistic.fetchForGroup(jurorGroup.jurorGroupId).then(
        (statisticData) => {
          setStatistic(statisticData);
          setIsLoading(false);
        }
      );
    }
  }, [setIsLoading, setStatistic, jurorGroup, customJurorGroup]);

  useEffect(() => {
    if (
      (role === ROLES.juror &&
        jurorGroup?.jurorGroupPhase === JUROR_GROUP_PHASES.editPhase) ||
      isAwardType(AWARD_TYPES.IF_SIP, session) ||
      isAwardType(AWARD_TYPES.IF_DTA, session)
    ) {
      setFilter({
        id: juror.jurorId,
        name: t('jurorDashboard.statistic.group.dropdown.personalStatistic'),
      });
    } else {
      setFilter({
        id: 'group',
        name: t('jurorDashboard.statistic.group.dropdown.groupStatistic'),
      });
    }
    setSlideIndex(0);
  }, [role, juror, session]);

  const isJuror = role === ROLES.juror;

  const isCarouselActive =
    !bigStats && !isJuror && (filter.id === 'group' || slideIndex !== 0);

  const isExpanded = filter.id === 'group';

  const handleCarouselSelect = (selectedIndex, e) => {
    setSlideIndex(selectedIndex);
  };

  const handleFilterSelect = (option) => {
    setFilter(option);
  };

  const filterOptions = useMemo(() => {
    if (!statistic?.jurors) return [];

    let jurors = [];

    if (
      role !== ROLES.juror &&
      (!isAwardType(AWARD_TYPES.IF_DA, session) ||
        session?.sessionType !== SESSION_TYPES.FINAL_JURY ||
        role !== ROLES.groupLeader)
    ) {
      jurors = statistic.jurors
        .filter((stats) => stats.jurorId && stats.jurorId !== juror?.jurorId)
        .map((jurorStats) => ({
          id: jurorStats.jurorId,
          name: `${jurorStats.firstname} ${jurorStats.lastname}`,
        }));
    }

    let options = [
      {
        id: 'group',
        name: t('jurorDashboard.statistic.group.dropdown.groupStatistic'),
      },
    ];

    if (role !== ROLES.staff) {
      options.push({
        id: juror?.jurorId,
        name: t('jurorDashboard.statistic.group.dropdown.personalStatistic'),
      });
    }

    options = options.concat(jurors);

    return options;
  }, [statistic, juror]);

  return (
    <div
      className={combine(
        styles.widgetContainer,
        isExpanded && styles.expanded,
        bigStats && styles.bigStats
      )}
    >
      <BsCarousel
        activeIndex={slideIndex}
        className={combine(
          styles.carousel,
          isCarouselActive && !isLoading && styles.withIndicators
        )}
        onSelect={handleCarouselSelect}
        interval={null}
        indicators={!isLoading && isCarouselActive}
        prevIcon={
          isCarouselActive && !isLoading ? (
            <FontAwesomeIcon
              icon={faChevronLeft}
              color="black"
              size="2x"
              className={styles.prevIcon}
            />
          ) : null
        }
        nextIcon={
          isCarouselActive && !isLoading ? (
            <FontAwesomeIcon
              icon={faChevronRight}
              color="black"
              size="2x"
              className={styles.nextIcon}
            />
          ) : null
        }
      >
        <BsCarousel.Item className={styles.carouselItem}>
          <div className={styles.itemContainer}>
            {customJurorGroup ||
            (isJuror &&
              jurorGroup?.jurorGroupPhase === JUROR_GROUP_PHASES.editPhase) ||
            isAwardType(AWARD_TYPES.IF_SIP, session) ||
            isAwardType(AWARD_TYPES.IF_DTA, session) ? (
              <h2 className={styles.title}>
                {customJurorGroup ? (
                  <b>{customJurorGroup.jurorGroupName}</b>
                ) : (
                  <Trans
                    i18nKey="jurorDashboard.statistic.jurorHeader"
                    components={{ bold: <b /> }}
                  />
                )}
              </h2>
            ) : (
              <Dropdown
                maxHeight={isCarouselActive ? '350px' : '200px'}
                options={filterOptions}
                value={filter}
                onSelect={handleFilterSelect}
              />
            )}
            <MainStatistic
              isLoading={isLoading}
              statistic={statistic}
              filter={filter.id}
              bigStats={bigStats}
            />
          </div>
        </BsCarousel.Item>
        {!isJuror && (
          <BsCarousel.Item className={styles.carouselItem}>
            <div className={styles.itemContainer}>
              <h2 className={styles.title}>
                <Trans
                  i18nKey="jurorDashboard.statistic.group.progress.title"
                  components={{ bold: <b /> }}
                />
              </h2>
              <ProgressStatistic statistic={statistic} />
            </div>
          </BsCarousel.Item>
        )}
        {!isJuror && (
          <BsCarousel.Item className={styles.carouselItem}>
            <div className={styles.itemContainer}>
              <h2 className={styles.title}>
                <Trans
                  i18nKey="jurorDashboard.statistic.group.score.title"
                  components={{ bold: <b /> }}
                />
              </h2>
              <ScoreStatistic statistic={statistic} />
            </div>
          </BsCarousel.Item>
        )}
      </BsCarousel>
    </div>
  );
};

StatisticWidget.propTypes = {
  customJurorGroup: PropTypes.object,
};

StatisticWidget.defaultProps = {
  customJurorGroup: null,
};

export default StatisticWidget;
