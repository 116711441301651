import * as React from 'react';
import logo from '../../assets/images/if-logo.svg';
import styles from './styles.module.scss';

const Loader = () => {
  return (
    <div className={styles.loader}>
      <img src={logo} className={styles.logo} alt="logo" />
    </div>
  );
};

export default Loader;
