import PropTypes from 'prop-types';
import React from 'react';
import { ROLES } from '../../config/permissions/roles';
import styles from './styles.module.scss';

const Avatar = ({ name, role, img }) => {
  const transformRole = (role) => {
    switch (role) {
      case ROLES.juror:
        return 'Juror';
      case ROLES.groupLeader:
        return 'Group Leader';
      case ROLES.chairPerson:
        return 'Chairperson';
      default:
        return 'Juror';
    }
  };

  return (
    <div className={styles.avatar}>
      {img ? (
        <img src={img} loading="lazy" className={styles.img} />
      ) : (
        <div className={styles.img} />
      )}
      <div>
        <span className={styles.name}>{name}</span>
        {role && <span className={styles.role}>{transformRole(role)}</span>}
      </div>
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  img: PropTypes.string,
};

Avatar.defaultProps = {
  name: '',
  role: '',
  img: '',
};

export default Avatar;
