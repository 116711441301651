import {
  faArrowRightFromBracket,
  faBars,
  faClose,
  faHome,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft, faQrcode } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, NavDropdown, NavLink } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/if-logo.svg';
import { combine } from '../../helpers/styles';
import { useAuth, useJuror } from '../../hooks/useAuth';
import { useConfig } from '../../hooks/useConfig';
import Debug from '../debug';
import styles from './styles.module.scss';

const Navigation = ({ type }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['']);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [showNavigation, setShowNavigation] = React.useState(false);
  const isAdmin = type === 'admin';
  const isJuror = type === 'juror';
  const admin = useAuth();
  const juror = useJuror();
  const { t } = useTranslation();
  const { isJurorPreview } = cookies;
  const {
    NAVIGATION: { QR_CODE_BUTTON: renderQrButton },
    AWARD_YEAR,
  } = useConfig();

  const toggleNavigation = () => {
    return setShowNavigation(!showNavigation);
  };

  const handleExitJurorPreviewClick = () => {
    removeCookie('isJurorPreview', { path: '/' });
    removeCookie('rememberMe');
    setCookie('JSESSIONID', admin.sessionCookie, { path: '/' });

    juror.clearJurorAuth();
  };

  return (
    <>
      {isMobile && showNavigation && (
        <div className={styles.mobileNavigation}></div>
      )}
      <Container
        as={'nav'}
        fluid
        className={combine(styles.navigation, styles[type])}
      >
        <Debug />
        <Container className={styles.navigationContainer}>
          <div className={combine(styles.navigationInner)}>
            <div className={styles.navigationLeft}>
              <Link
                to={
                  isAdmin
                    ? '/admin/dashboard'
                    : juror.isAuthenticated
                    ? `/juror/dashboard`
                    : '/juror'
                }
                className={styles.logoContainer}
              >
                <img src={logo} className={styles.logo} alt={'iF Logo'} />
                {t('navigation.title')}
              </Link>
              {isJuror &&
                !isMobile &&
                !isJurorPreview &&
                juror.isAuthenticated && (
                  <div
                    className={combine(styles.navContainer, 'd-none d-xl-flex')}
                  >
                    <NavLink
                      as={Link}
                      to={`/juror/dashboard`}
                      className={styles.navLink}
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className={styles.navIcon}
                      />
                      Dashboard
                    </NavLink>
                  </div>
                )}
              {isJuror && !isMobile && isJurorPreview && (
                <div
                  className={combine(styles.navContainer, 'd-none d-xl-flex')}
                >
                  <Link
                    to={'/admin/groups/overview'}
                    state={{ selectedSession: juror?.session?.sessionId }}
                    onClick={handleExitJurorPreviewClick}
                    className={styles.navLink}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className={styles.navIcon}
                    />
                    Juror Groups
                  </Link>
                </div>
              )}
              {admin.isAuthenticated && !isMobile && !isJuror && (
                <div className={styles.navContainer}>
                  <NavDropdown
                    className={styles.navLink}
                    title={t('navigation.sessions')}
                  >
                    <NavDropdown.Item className={styles.dropdownItem} as={'li'}>
                      <Link
                        className={styles.dropdownItemLink}
                        to={'sessions/overview'}
                      >
                        {t('navigation.menu.sessions.sessionsOverview')}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className={styles.dropdownItem} as={'li'}>
                      <Link
                        className={styles.dropdownItemLink}
                        to={'sessions/evaluation-status'}
                      >
                        {t('navigation.menu.sessions.evaluationStatus')}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className={styles.dropdownItem} as={'li'}>
                      <Link
                        className={styles.dropdownItemLink}
                        to={'sessions/threshold-calculator'}
                      >
                        {t('navigation.menu.sessions.threshold')}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={combine(styles.dropdownItem)}
                      as={'li'}
                    >
                      <Link
                        className={styles.dropdownItemLink}
                        to={'sessions/information-material'}
                      >
                        {t('navigation.menu.sessions.informationMaterial')}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={combine(styles.dropdownItem)}
                      as={'li'}
                    >
                      <Link
                        className={styles.dropdownItemLink}
                        to={'sessions/meetings'}
                      >
                        {t('navigation.menu.sessions.meetings')}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={combine(styles.dropdownItem)}
                      as={'li'}
                    >
                      <Link
                        className={styles.dropdownItemLink}
                        to={'sessions/submission-overview'}
                      >
                        {'Submission Overview'}
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    className={styles.navLink}
                    title={t('navigation.jurors')}
                  >
                    <NavDropdown.Item className={styles.dropdownItem} as={'li'}>
                      <Link
                        className={styles.dropdownItemLink}
                        to={'groups/overview'}
                      >
                        {t('navigation.menu.jurors.groupsOverview')}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={combine(styles.dropdownItem)}
                      as={'li'}
                    >
                      <Link className={styles.dropdownItemLink} to={'jurors'}>
                        {t('navigation.menu.jurors.jurorsList')}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={combine(styles.dropdownItem)}
                      as={'li'}
                    >
                      <Link className={styles.dropdownItemLink} to={'chat'}>
                        {t('navigation.menu.jurors.chat')}
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              )}
            </div>
            {isJuror && !isMobile && (
              <div className={styles.navigationCenter}>
                <div className={styles.navContainer}>
                  <div className={styles.title}>
                    {!isJurorPreview && (
                      <>
                        <h3 className={styles.navTitle}>
                          {juror?.session?.awardName || t('appName')}
                          {` ${AWARD_YEAR}`}
                        </h3>
                        <span className={styles.juryPhase}>
                          |{' '}
                          {juror?.session?.sessionType
                            ? t(`sessionTypes.${juror?.session?.sessionType}`)
                            : ''}
                        </span>
                      </>
                    )}
                    {isJurorPreview && (
                      <div>
                        <div className={styles.juryPhase}>
                          Preview: {juror?.juror?.firstname}{' '}
                          {juror?.juror?.lastname}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className={styles.navigationRight}>
              {juror.isAuthenticated && !admin.isAuthenticated && (
                <>
                  {renderQrButton[juror?.session?.sessionType] && (
                    <div className={styles.scanLink}>
                      <Link to={'/juror/entry/scan'}>
                        <FontAwesomeIcon
                          icon={faQrcode}
                          color="white"
                          className={styles.qrIcon}
                          size="2x"
                        />
                      </Link>
                    </div>
                  )}
                  <div className={styles.userInfo}>
                    <span className={styles.userName}>
                      {t('navigation.welcome')} {`${juror.juror?.firstname}`}
                    </span>
                    <NavDropdown
                      className={styles.userDropdown}
                      title={
                        <div
                          className={styles.userAvatar}
                          style={{
                            backgroundImage: `url(${juror?.juror?.profilePictureUrl})`,
                          }}
                        >
                          {!juror.juror?.profilePictureUrl && (
                            <FontAwesomeIcon
                              icon={faUser}
                              className={styles.avatarIcon}
                            />
                          )}
                        </div>
                      }
                    >
                      <NavDropdown.Item
                        className={styles.userActions}
                        onClick={juror.signOut}
                      >
                        <FontAwesomeIcon
                          icon={faArrowRightFromBracket}
                          className={styles.logoutIcon}
                        />
                        {t('navigation.logout')}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </>
              )}
              {admin.isAuthenticated && (
                <div className={styles.userInfo}>
                  <span className={styles.userName}>
                    {t('navigation.welcome')} {admin?.user?.username}
                  </span>
                  <NavDropdown
                    className={styles.userDropdown}
                    title={
                      <div className={styles.userAvatar}>
                        <FontAwesomeIcon
                          icon={faUser}
                          className={styles.avatarIcon}
                        />
                      </div>
                    }
                  >
                    <NavDropdown.Item
                      className={styles.userActions}
                      onClick={() => admin.signout()}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        className={styles.logoutIcon}
                      />
                      {t('navigation.logout')}
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              )}
              {isMobile && admin.isAuthenticated && (
                <div
                  className={styles.hamburgerMenu}
                  onClick={() => toggleNavigation()}
                >
                  <FontAwesomeIcon
                    icon={showNavigation ? faClose : faBars}
                    className={styles.burgerIcon}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Navigation;
