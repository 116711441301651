import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { faArrowRight, faUser } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight as faArrowRightBold } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { combine } from '../../../helpers/styles';
import styles from './styles.module.scss';
import { useJuror } from '../../../hooks/useAuth';
import GroupMember from '../../../models/groupMember';
import { ROLES } from '../../../config/permissions/roles';

const GroupMembers = () => {
  const [hoverItem, setHoverItem] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [groupMembers, setGroupMembers] = useState([]);

  const { t } = useTranslation();
  const { jurorGroup } = useJuror();

  useEffect(() => {
    if (jurorGroup) {
      setIsLoading(true);
      GroupMember.fetchForJurorGroup(jurorGroup.jurorGroupId).then(
        (groupMembers) => {
          const filteredGroupMembers = groupMembers.filter(
            (juror) => juror.role !== ROLES.staff
          );
          setGroupMembers(filteredGroupMembers);
          setIsLoading(false);
        }
      );
    }
  }, [setIsLoading, setGroupMembers, jurorGroup]);

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {isLoading
          ? new Array(4).fill(null).map((_, i) => (
              <li
                key={i}
                className={combine(styles.placeholder, 'placeholder-glow')}
              >
                <span className="placeholder col-8"></span>
              </li>
            ))
          : groupMembers.map((juror) => (
              <li key={juror.jurorId}>
                <a
                  href={`https://ifdesign.com/en/if-design-award-and-jury/jury/profile/jury-tool/${juror.jurorId}`}
                  target="_blank"
                  className={styles.item}
                  onMouseEnter={() => {
                    setHoverItem(juror.jurorId);
                  }}
                  onMouseLeave={() => {
                    setHoverItem(-1);
                  }}
                >
                  <div className={styles.profilePicture}>
                    {juror.profilePictureUrl ? (
                      <img
                        src={juror.profilePictureUrl}
                        className={styles.img}
                        loading="lazy"
                      />
                    ) : (
                      <div className={styles.profileIconContainer}>
                        <FontAwesomeIcon
                          icon={faUser}
                          className={styles.icon}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.textContainer}>
                    <span
                      className={styles.name}
                    >{`${juror.firstname} ${juror.lastname}`}</span>
                    <span className={styles.role}>
                      {t(`roles.${juror.role}`)}
                    </span>
                  </div>
                  <div className={styles.iconContainer}>
                    <FontAwesomeIcon
                      icon={
                        hoverItem === juror.jurorId
                          ? faArrowRightBold
                          : faArrowRight
                      }
                      className={styles.icon}
                    />
                  </div>
                </a>
              </li>
            ))}
      </ul>
    </div>
  );
};

export default GroupMembers;
