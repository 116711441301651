import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Modal } from 'react-bootstrap';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

const Dialog = ({
  title,
  titleIcon,
  onClose,
  body,
  footer,
  noNav,
  noHeader,
  scrollable,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  containerClass,
}) => {
  const handleBackdropClick = (e) => {
    e.stopPropagation();
    if (e.target !== e.currentTarget) {
      return false;
    }

    return onClose();
  };

  return (
    <Container
      fluid
      onClick={handleBackdropClick}
      className={combine(
        styles.dialogContainer,
        noNav && styles.noNav,
        scrollable && styles.scrollable
      )}
    >
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        xxl={xxl}
        className={containerClass || ''}
      >
        <Modal.Dialog
          className={styles.dialog}
          contentClassName={styles.dialogWrapper}
        >
          <div className={styles.closeButton} onClick={() => onClose()}>
            <FontAwesomeIcon className={styles.closeIcon} icon={faClose} />
          </div>
          <Col xs={12}>
            {!noHeader && (
              <Modal.Header className={styles.header} onHide={() => onClose()}>
                {titleIcon && (
                  <FontAwesomeIcon
                    size="2x"
                    icon={titleIcon}
                    className={styles.icon}
                  />
                )}
                <Modal.Title className={styles.title}>{title}</Modal.Title>
              </Modal.Header>
            )}

            <Modal.Body className={styles.body}>
              <div>{body}</div>
            </Modal.Body>

            {footer && (
              <Modal.Footer className={styles.footer}>{footer}</Modal.Footer>
            )}
          </Col>
        </Modal.Dialog>
      </Col>
    </Container>
  );
};

Dialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noNav: PropTypes.bool,
  noHeader: PropTypes.bool,
  scrollable: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  containerClass: PropTypes.string,
  titleIcon: PropTypes.object,
};

Dialog.defaultProps = {
  title: '',
  onClose: () => null,
  body: '',
  footer: '',
  noNav: false,
  noHeader: false,
  scrollable: false,
  xs: 12,
  sm: 10,
  md: 8,
  lg: 6,
  xl: 6,
  xxl: 6,
  containerClass: '',
  titleIcon: null,
};

export default Dialog;
