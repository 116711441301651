import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../../components/dialog';
import Session from '../../../../models/session';
import SessionForm, {
  SESSION_FORM_TYPES,
} from '../../../../modules/sessionForm';
import styles from './styles.module.scss';

const CreateSession = () => {
  const navigate = useNavigate();

  const createSession = (formData) => {
    Session.create(formData).then(() => {
      navigate('/admin/sessions');
    });
  };

  return (
    <Dialog
      containerClass={styles.dialogContainer}
      xs={12}
      sm={12}
      md={11}
      lg={9}
      xl={8}
      xxl={7}
      title={
        <Trans i18nKey="sessions.create.title" components={{ bold: <b /> }} />
      }
      noNav
      body={
        <div className={styles.formWrapper}>
          <SessionForm
            onSubmit={createSession}
            onCancel={() => navigate('/admin/sessions')}
            session={null}
            type={SESSION_FORM_TYPES.create}
            withButtons
          />
        </div>
      }
      onClose={() => navigate('/admin/sessions')}
    />
  );
};

export default CreateSession;
