import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/if-logo.svg';
import styles from './styles.module.scss';

const SessionEnded = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.sessionEnded}>
      <img src={logo} className={styles.logo} alt={'iF Logo'} />
      <h1>{t('closed.sessionClosedTitle')}</h1>
      <h2>{t('closed.sessionClosedSubtitle')}</h2>
    </div>
  );
};

export default SessionEnded;
