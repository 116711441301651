import { isSessionClosed } from './date';
import { SCOPES, hasPermission } from '../config/permissions/roles';

export const hasAccess = (session, role) => {
  if (!session || !role) return false;

  const sessionClosed = isSessionClosed(session?.endDate);

  return !sessionClosed || hasPermission(SCOPES.canAccessJTWhenClosed, role);
};
