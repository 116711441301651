import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonTypes } from '../../../../components/button';
import SessionsTable, {
  TABLE_TYPES,
} from '../../../../components/sessionsTable';
import Session from '../../../../models/session';
import Hero from '../../../../modules/hero';
import styles from './styles.module.scss';

const ArchivedSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    Session.fetchAll({ archived: true }).then((data) => {
      setSessions(data);
    });
    setLoading(false);
  }, [setSessions]);

  return (
    <div className={styles.sessionsOverview}>
      <Hero title={t('sessions.archived.title')} />
      <Container className={'py-4'}>
        <div className={styles.topButtonContainer}>
          <Button
            text={'Session Overview'}
            type={ButtonTypes.SECONDARY}
            onClick={() => {
              navigate('../overview');
            }}
          />
        </div>
        <SessionsTable
          sessions={sessions}
          setSessions={setSessions}
          loading={loading}
          type={TABLE_TYPES.archive}
        />
      </Container>
    </div>
  );
};

export default ArchivedSessions;
