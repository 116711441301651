import { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AWARD_TYPES } from '../models/award';
import { ROLES } from '../config/permissions/roles';
import { JUROR_GROUP_PHASES } from '../models/jurorGroup';
import Submission from '../models/submission';
import { translateCountryCode } from '../helpers/countryCodes';
import { isAwardType } from '../helpers/awardTypes';
import { useAuth, useJuror } from './useAuth';
import { useConfig } from './useConfig';
import { useTranslation } from 'react-i18next';
import { ADMIN_CONFIG } from '../config/renderConfig';
import { SESSION_TYPES } from '../models/session';

const useSubmissionsFilter = ({
  submissions,
  setVisibleSubmissions,
  isAdminView = false,
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { juror, jurorGroup, role, session } = useJuror();
  const { ENTRIES_FILTER } = useConfig();
  const { t } = useTranslation();

  const filterParams = useMemo(
    () => ({
      data:
        searchParams
          .get('filterBy')
          ?.split(',')
          .map((p) => p.replaceAll('_cm_', ',')) || [],
      order: searchParams.get('order') || null,
      search: searchParams.get('search') || null,
    }),
    [searchParams]
  );

  useEffect(() => {
    const optionsConfig = {
      disciplineName: {
        key: 'disciplineName',
        text: t('jurorEntries.filter.options.discipline'),
        childs: submissions
          .map((submission) => submission.disciplineName)
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((disciplineName) => {
            return { key: disciplineName, text: disciplineName };
          }),
      },
      categoryName: {
        key: 'categoryName',
        text: t(
          `jurorEntries.filter.options.category.${
            session?.awardType || 'IF_SIP'
          }`
        ),
        childs: submissions
          .map((submission) => submission.categoryName)
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((categoryName) => {
            return { key: categoryName, text: categoryName };
          }),
      },
      presentationForm: {
        key: 'presentationForm',
        text: t(`jurorEntries.filter.options.presentationForm.title`),
        childs: [
          ...new Set(
            submissions
              .map((submission) =>
                submission.extraFields?.reduce(
                  (prev, field) =>
                    field.name === 'presentationForm' ? field.value : prev,
                  []
                )
              )
              .flat()
          ),
        ].map((presentationForm) => {
          return {
            key: presentationForm,
            text: t(
              `jurorEntries.filter.options.presentationForm.${presentationForm}`
            ),
          };
        }),
      },
      jurorGroup: {
        key: 'jurorGroup',
        text: t(`jurorEntries.filter.options.jurorGroup`),
        childs: [
          ...new Set(
            submissions
              .map((submission) =>
                submission.evaluations.map((ev) => ev.jurorGroupName)
              )
              .flat()
          ),
        ].map((jurorGroup) => {
          return { key: jurorGroup, text: jurorGroup };
        }),
      },
      country: {
        key: 'country',
        text: t('jurorEntries.filter.options.country'),
        childs: submissions
          .map((submission) => submission.accountCountryIsoCode)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort()
          .map((countryCode) => {
            return {
              key: translateCountryCode(countryCode),
              text: translateCountryCode(countryCode),
            };
          }),
      },
      suggestedForNomination: {
        key: 'suggestedForNomination',
        text: t('jurorEntries.filter.options.suggestedForNomination'),
      },
      nominated: {
        key: 'nominated',
        text: t(
          `jurorEntries.filter.options.nominated.${
            session?.awardType || 'IF_SIP'
          }`
        ),
      },
      chairpersonMustDecide: {
        key: 'chairPersonMustDecide',
        text: t('jurorEntries.filter.options.chairPersonMustDecide'),
      },
      discussInGroup: {
        key: 'discussInGroup',
        text: t('jurorEntries.filter.options.discussInGroup'),
      },
      toBeDecided: {
        key: 'toBeDecided',
        text: t('jurorEntries.filter.options.toBeDecided'),
      },
      bookmarked: {
        key: 'bookmarked',
        text: t('jurorEntries.filter.options.bookmarked'),
      },
      scoreAlert: {
        key: 'scoreAlert',
        text: t('jurorEntries.filter.options.scoreAlert'),
      },
      hasZeroEvaluationCategory: {
        key: 'hasZeroEvaluationCategory',
        text: t('jurorEntries.filter.options.hasZeroEvaluationCategory'),
      },
    };

    let keys = [];
    if (isAdminView) {
      keys = ADMIN_CONFIG.ENTRIES_FILTER || [];
    } else if (isAwardType(AWARD_TYPES.IF_SIP, session)) {
      keys =
        ENTRIES_FILTER.filter(
          (key) => session.prizeMoneyModeEnabled || key !== 'nominated'
        ) || [];
    } else {
      keys =
        ENTRIES_FILTER.filter((key) => {
          if (key === 'jurorGroup') return role === ROLES.chairPerson;
          if (key === 'scoreAlert')
            return (
              role !== ROLES.juror ||
              jurorGroup?.jurorGroupPhase !== JUROR_GROUP_PHASES.editPhase
            );
          if (key === 'presentationForm')
            return session?.sessionType === SESSION_TYPES.FINAL_JURY;
          if (key === 'hasZeroEvaluationCategory') return role === ROLES.staff;
          if (key === 'nominated')
            return session?.sessionType === SESSION_TYPES.FINAL_JURY;
          return true;
        }) || [];
    }

    setFilterOptions(keys.map((key) => optionsConfig[key]));
  }, [submissions, session, isAdminView, jurorGroup]);

  useEffect(() => {
    let filtered = submissions;

    if (filterParams?.search) {
      filtered = Submission.search(filtered, filterParams?.search);
    }
    if (filterParams?.data) {
      filtered = Submission.filter(filtered, filterParams?.data, juror);
    }
    if (filterParams?.order) {
      filtered = Submission.sort(filtered, filterParams?.order);
    }

    setVisibleSubmissions(filtered);
  }, [filterParams, submissions]);

  const handleSortChange = (value) => {
    let newFilterParams = [...filterParams.data];

    if (value.includes('__')) {
      const paramKey = value.split('__')[0];
      const paramIndex = newFilterParams.findIndex(
        (param) => param.includes('__') && param.split('__')[0] === paramKey
      );

      if (paramIndex > -1) {
        if (newFilterParams.find((el) => el === value)) {
          newFilterParams = newFilterParams.filter((el) => el !== value);
        } else {
          newFilterParams[paramIndex] = value;
        }
      } else {
        newFilterParams.push(value);
      }
    } else {
      if (newFilterParams.find((el) => el === value)) {
        newFilterParams = newFilterParams.filter((el) => el !== value);
      } else {
        newFilterParams.push(value);
      }
    }

    if (newFilterParams.length) {
      searchParams.set(
        'filterBy',
        newFilterParams.map((p) => p.replaceAll(',', '_cm_')).join(',')
      );
    } else {
      searchParams.delete('filterBy');
    }
    setSearchParams(searchParams);
  };

  const handleFilterDeselect = (value) => {
    if (filterParams.data.length > 1) {
      searchParams.set(
        'filterBy',
        filterParams.data.filter((p) => p !== value).join(',')
      );
      return setSearchParams(searchParams);
    }
    handleFilterReset('filterBy');
  };

  const onFulltextSearch = (searchTerm) => {
    if (searchTerm == '') {
      searchParams.delete('search');
    } else {
      searchParams.set('search', searchTerm);
    }
    setSearchParams(searchParams);
  };

  const handleFilterReset = (key) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const onSort = () => {
    let order = searchParams.get('order');

    if (!order || order == 'desc') {
      order = 'asc';
    } else {
      order = 'desc';
    }
    searchParams.set('order', order);
    setSearchParams(searchParams);
  };

  return {
    filterParams,
    filterOptions,
    handleSortChange,
    handleFilterDeselect,
    handleFilterReset,
    onFulltextSearch,
    onSort,
  };
};

export default useSubmissionsFilter;
