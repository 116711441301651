import React, { useEffect, useRef, useState } from 'react';
import pt from 'prop-types';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronUp,
  faChevronDown,
  faDownload as faDownloadBold,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { combine } from '../../../../helpers/styles';
import Button from '../../../button';
import ReactMarkdown from 'react-markdown';

const AccordionItem = ({
  title,
  text,
  pdfs,
  videos,
  isFirst,
  isOpen,
  onOpen,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const outerContainerRef = useRef();
  const [maxHeight, setMaxHeight] = useState('none');

  const style = {
    transition: 'max-height 350ms ease-in-out',
    overflow: 'hidden',
    // For the collapse animation to work we need a fixed max-height,
    // therefore we simply pass the actual height of the element when the user collapses it (isOpen === false)
    maxHeight:
      maxHeight === 'none' && !isOpen ? ref.current?.clientHeight : maxHeight,
  };

  useEffect(() => {
    setMaxHeight(isOpen ? `${ref.current?.clientHeight}px` : '0');
    if (isOpen) {
      // wait for animation to finish
      setTimeout(() => {
        window.scrollTo({
          top: outerContainerRef.current.offsetTop,
          behavior: 'smooth',
        });
      }, 350);
    }
  }, [isOpen]);

  return (
    <div
      className={styles.accordionItem}
      ref={outerContainerRef}
      id={title.replace(/ /g, '_')}
    >
      <button
        onClick={onOpen}
        className={combine(styles.header, isOpen && styles.isOpen)}
      >
        <div
          className={combine(styles.headerContent, isFirst && styles.isFirst)}
        >
          <h2 className={styles.leftAligned}>{title}</h2>

          <div className="d-flex align-items-center">
            {Boolean(videos.length) && (
              <FontAwesomeIcon className={styles.icon} icon={faVideo} />
            )}

            {Boolean(pdfs.length) && (
              <FontAwesomeIcon className={styles.icon} icon={faDownloadBold} />
            )}

            <FontAwesomeIcon
              className={styles.arrow}
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </div>
        </div>
      </button>
      <div className={styles.contentContainer}>
        <div
          style={style}
          onTransitionEnd={() => {
            if (isOpen) {
              // To avoid problems when resizing the screen we simply reset max-height to default after ended transition
              setMaxHeight('none');
            }
          }}
        >
          <div className={styles.content} ref={ref}>
            <ReactMarkdown>{text}</ReactMarkdown>
            {Boolean(videos.length) && (
              <div className={styles.mediaBlock}>
                <h3>{t('jurors.guidelines.media')}</h3>
                <div className={styles.mediaList}>
                  {videos.map((video) => (
                    <div className={styles.media} key={video.url}>
                      <a
                        href={video.url}
                        className="position-relative"
                        target="__blank"
                      >
                        <div className={styles.playIconContainer}>
                          <FontAwesomeIcon
                            className={styles.play}
                            icon={faPlay}
                          />
                        </div>
                        <img
                          src="https://cdn.zeplin.io/620a072a89959ba5b151e763/assets/55B7F4E8-EB63-4E6B-A9C9-75F7FE8365AB.png"
                          alt={video.infoText}
                        />
                      </a>
                      <span>{video.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {Boolean(pdfs.length) && (
              <div className={styles.fileBlock}>
                <h3>{t('jurors.guidelines.files')}</h3>
                <div className={styles.fileList}>
                  {pdfs.map((file) => (
                    <div className={styles.file} key={file.url}>
                      <span>{`PDF | ${file.title}`}</span>
                      <a href={file.url} download>
                        <Button
                          type="tertiary"
                          icon={faDownload}
                          iconBold={faDownloadBold}
                          className={styles.downloadButton}
                          text={t(
                            'jurors.guidelines.buttons.download'
                          ).toUpperCase()}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const accordionItemPropTypes = {
  title: pt.string.isRequired,
  text: pt.string,
  pdfs: pt.arrayOf(
    pt.shape({
      title: pt.string,
      url: pt.string,
    })
  ),
  videos: pt.arrayOf(
    pt.shape({
      title: pt.string,
      url: pt.string,
    })
  ),
  isFirst: pt.bool,
  isOpen: pt.bool,
  onOpen: pt.func,
};

AccordionItem.propTypes = accordionItemPropTypes;

AccordionItem.defaultProps = {
  text: '',
  pdfs: [],
  videos: [],
  isFirst: false,
  isOpen: false,
  onOpen: () => null,
};

export default AccordionItem;
