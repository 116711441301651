import { toast } from 'react-hot-toast';

const handleRequestError = async (res) => {
  if (!res.ok) {
    const x = await res.json();
    toast(`${x?.error}: ${x?.message}`, {
      duration: 10000,
      icon: '❌',
    });
  }

  return res;
};

const displayWarning = (text) => {
  toast(text, { duration: 10000, icon: '❗' });
};

export default handleRequestError;
export { displayWarning };
