import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import * as React from 'react';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

export const ButtonTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const ButtonSizes = {
  SMALL: 'small',
  LARGE: 'large',
};

const LinkButton = ({
  text,
  className,
  size,
  type,
  icon,
  iconBold,
  link,
  isDownload,
}) => {
  return (
    <a
      href={link}
      data-label={text}
      className={combine(
        styles.button,
        styles[type],
        styles[size],
        className,
        icon && styles.withIcon
      )}
      download={isDownload}
    >
      <span className={styles.label}>{text}</span>
      {icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
      {iconBold && (
        <FontAwesomeIcon
          className={combine(styles.icon, styles.iconBold)}
          icon={iconBold}
        />
      )}
    </a>
  );
};

LinkButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'small', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconBold: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.string,
  isDownload: PropTypes.bool,
};

LinkButton.defaultProps = {
  text: 'Button',
  className: '',
  size: 'normal',
  type: 'primary',
  icon: null,
  iconBold: null,
  link: '',
  isDownload: false,
};

export default LinkButton;
