import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/images/if-logo.svg';
import Button from '../../../components/button';
import Input from '../../../components/input';
import Loading from '../../../components/loading';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import { hasAccess } from '../../../helpers/access';
import styles from './styles.module.scss';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckMarkVisible, setIsCheckMarkVisible] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { juror, role, session, signIn } = useJuror();

  const uuid = id || localStorage.getItem('juror_uuid');

  useEffect(() => {
    if (juror && session && role && hasAccess(session, role)) {
      setTimeout(() => {
        setIsCheckMarkVisible(true);
        setTimeout(() => {
          navigate('/juror/dashboard');
        }, 1500);
      }, 2500);
    }
  }, [setIsCheckMarkVisible, juror, session, role]);

  const formik = useFormik({
    initialValues: { password: '' },
    onSubmit: async (values) => {
      const { password } = values;
      setIsLoading(true);
      const accountData = await signIn(uuid, password);
      setIsLoading(!!accountData);
    },
  });

  const renderContent = () => {
    if (!uuid) {
      return (
        <div className={styles.bottomContainer}>
          <h4>{t('jurorLogin.noID.message')}</h4>
        </div>
      );
    }

    if (!isLoading && !isCheckMarkVisible) {
      return (
        <div className={combine(styles.bottomContainer, styles.loginContainer)}>
          <form onSubmit={formik.handleSubmit}>
            <p>{t('jurorLogin.enterPassword')}</p>
            <div className={styles.inputElements}>
              <Input
                label={t('jurorLogin.password')}
                type="password"
                name="password"
                id="current-password"
                autoComplete="current-password"
                required
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <Button text={t('jurorLogin.loginButton')} />
            </div>
          </form>
        </div>
      );
    }

    if (isCheckMarkVisible) {
      return (
        <div className={styles.bottomContainer}>
          <div className={styles.check}></div>
        </div>
      );
    }

    return (
      <div className={styles.bottomContainer}>
        <Loading />
      </div>
    );
  };

  return (
    <div className={styles.login}>
      <div className={styles.topContainer}>
        <div className={styles.imageContainer}>
          <img
            src={logo}
            className={combine(styles.image, styles.logo)}
            alt={'iF Logo'}
          />
          {juror?.profilePictureUrl && (
            <img
              src={juror.profilePictureUrl}
              className={combine(styles.image, styles.profilePicture)}
              alt={'Juror Profile Picture'}
            />
          )}
        </div>

        {juror && (
          <div className={styles.greetingsContainer}>
            <span>
              <Trans
                i18nKey="jurorLogin.welcome.message"
                components={{ bold: <b /> }}
                values={{
                  name: juror.firstname,
                }}
              />
            </span>
          </div>
        )}
      </div>
      {renderContent()}
    </div>
  );
};

export default Login;
