import { faPaperPlaneTop, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { combine } from '../../../../helpers/styles';
import { useAuth, useJuror } from '../../../../hooks/useAuth';
import Chat from '../../../../models/chat';
import styles from './styles.module.scss';

const Messages = ({
  limitMessagesAmount,
  numDisplayedMessages,
  autoRefresh,
  jurorGroupId,
  wholePage
}) => {
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const {
    jurorGroup,
    juror,
  } = useJuror();
  const { user } = useAuth();
  const { t } = useTranslation();

  const fetchMessages = ({ shouldScrollToBottom = false } = {}) => {
    Chat.fetchMessages(jurorGroupId || jurorGroup.jurorGroupId).then((messages) => {
      const messagesToDisplay = limitMessagesAmount
        ? messages.slice(-numDisplayedMessages)
        : messages;
      setMessages(messagesToDisplay);
      if (autoRefresh && shouldScrollToBottom) {
        scrollToBottom();
      }
    });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const submitMessage = (message) => {
    if (!message) return false;
    Chat.sendMessage(jurorGroupId || jurorGroup.jurorGroupId, message).then((newMessage) => {
      setMessages((messages) => [...messages, newMessage]);
      scrollToBottom();
    });
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values) => {
      submitMessage(values.message);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (jurorGroup || jurorGroupId) {
      fetchMessages({ shouldScrollToBottom: true });

      if (autoRefresh) {
        const intervalCall = setInterval(() => {
          fetchMessages();
        }, 10000);
        return () => {
          clearInterval(intervalCall);
        };
      }
    }
  }, [jurorGroup, jurorGroupId]);

  return (
    <>
      <div className={combine(styles.messagesContainer, wholePage && styles.wholePage)}>
        {messages.map((message) => (
          <div
            className={combine(styles.messageContainer,
               (message?.jurorId == juror?.jurorId ||
               (message?.jurorId === 1 && user?.backofficeUser)) && styles.right
               )}
            key={message.created}
          >
            {message?.profilePictureUrl ? (
              <img
                src={message.profilePictureUrl}
                className={styles.img}
                loading="lazy"
              />
            ) : (
              <div className={styles.profileIconContainer}>
                <FontAwesomeIcon icon={faUser} />
              </div>
            )}
            <div className={styles.message}>
              <span className={styles.name}>
                {message.firstname} {message.lastname}
              </span>{' '}
              <span className={styles.date}>
                {Intl.DateTimeFormat(navigator.language, {
                  weekday: 'long',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(message.created))}
              </span>
              <div className={styles.text}>{message.message}</div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={formik.handleSubmit} className={combine(styles.form, wholePage && styles.wholePage)}>
        <input
          name="message"
          className={styles.input}
          type="text"
          placeholder={t('jurorDashboard.chat.inputPlaceholder')}
          onChange={formik.handleChange}
          value={formik.values.message}
        />
        <button role="button" type="submit" className={styles.submitBtn}>
          <FontAwesomeIcon
            icon={faPaperPlaneTop}
            className={styles.submitIcon}
          />
        </button>
      </form>
    </>
  );
};

Messages.propTypes = {
  limitMessagesAmount: PropTypes.bool,
  numDisplayedMessages: PropTypes.number,
  autoRefresh: PropTypes.bool,
  jurorGroupId: PropTypes.string,
  wholePage: PropTypes.bool
};

Messages.defaultProps = {
  limitMessagesAmount: true,
  numDisplayedMessages: 2,
  autoRefresh: false,
  jurorGroupId: null
};

export default Messages;
