import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import JurorsTable from '../../../../components/jurorsTable';
import SearchField from '../../../../components/searchField';
import JurorList from '../../../../models/jurorList';
import Hero from '../../../../modules/hero';
import styles from './styles.module.scss';

const JurorsOverview = () => {
  const [jurorsList, setJurorsList] = useState([]);
  const [jurors, setJurors] = useState([]);
  const [jurorsLoading, setJurorsLoading] = useState(true);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  useEffect(async () => {
    const data = await JurorList.fetchAll();
    setJurors(data?.jurors);
    setJurorsList(data);
    setJurorsLoading(false);
  }, []);

  const onFulltextSearch = async (value) => {
    setSearch(value)
    const data = await JurorList.fetchQuery(value.toLowerCase());
    setJurorsList(data);
    // setFilteredJurors(filtered);
  };

  const handleLoadMore = async () => {
    setJurorsLoading(true);
    const { jurors: newJurors } = await jurorsList.fetchNextPage();
    
    setJurorsLoading(false);
    return setJurors(newJurors);
  };

  const debounceFn = useCallback(debounce(onFulltextSearch, 1000), []);

  function handleChange (val) {
      debounceFn(val);
  };

  return (
    <div className={styles.sessionsOverview}>
      <Hero
        title={t('jurors.list.total', {
          amount: jurorsList?.total || 0,
        })}
      />
      <Container className={'py-5'}>
        <div
          className={
            'd-flex justify-content-between align-items-center font-weight-bold text-capitalize'
          }
        >
          <SearchField onChange={onFulltextSearch} value={search} />
        </div>
        <JurorsTable
          jurors={jurorsList?.jurors}
          loading={jurorsLoading}
        />
        <Button onClick={handleLoadMore} text={"Load more"} disabled={jurorsLoading}></Button>
      </Container>
    </div>
  );
};

export default JurorsOverview;
