import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactBlock from '../../components/contactBlock';
import styles from './styles.module.scss';
import { CONTACTS as ALL_CONTACTS } from '../../config/contacts';
import { useConfig } from '../../hooks/useConfig';
import { useJuror } from '../../hooks/useAuth';
const { version } = require('../../../package.json');

const Footer = () => {
  const { t } = useTranslation();
  const { session } = useJuror();
  const { FOOTER_CONTACTS } = useConfig();

  const contacts = React.useMemo(
    () =>
      Object.entries(ALL_CONTACTS)
        .filter(([key]) => FOOTER_CONTACTS.includes(key))
        .map(([_, val]) => val),
    [FOOTER_CONTACTS]
  );

  return (
    <Container fluid className={styles.footer}>
      <Container>
        <Row>
          <Col xs={12} md={2} className={styles.footerCol}>
            <span className={styles.footerHeadline}>General Information</span>
            <a
              className={styles.footerLink}
              target={'_blank'}
              href={'https://ifdesign.com/en/terms-and-conditions'}
            >
              Terms
            </a>
            <a
              className={styles.footerLink}
              target={'_blank'}
              href={'https://ifdesign.com/en/legal-notice'}
            >
              Legal Notice
            </a>
            <a
              className={styles.footerLink}
              target={'_blank'}
              href={'https://ifdesign.com/en/data-privacy-statement'}
            >
              Data Privacy Statement
            </a>
            <a
              className={styles.footerLink}
              target={'_blank'}
              href={'https://ifdesign.com/en/cookie-policy'}
            >
              Cookie Policy
            </a>
            <a
              className={styles.footerLink}
              target={'_blank'}
              href={'https://ifdesign.com/en/about'}
            >
              About iF
            </a>
            <span className={styles.version}>
              Version {version} - {process.env.REACT_APP_GIT_SHA}
            </span>
          </Col>
          <Col xs={12} md={10} className={styles.footerCol}>
            <span className={styles.footerHeadline}>
              {t(
                `jurors.help.contactBlockTitle.${session?.awardType || 'IF_DA'}`
              )}
            </span>
            <ContactBlock contacts={contacts} className={styles.contactBlock} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
