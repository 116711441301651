import * as React from 'react';
import { useCookies } from 'react-cookie';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AuthContext } from '../context/auth.context';
import { JurorContext } from '../context/juror.context';
import { hasAccess } from '../helpers/access';
import Account from '../models/account';
import Auth from '../models/auth';
import Session from '../models/session';
import { ROLES } from '../config/permissions/roles';

export const authProvider = {
  isAuthenticated: false,
  async signin(email, password) {
    const token = 'Basic ' + btoa(`${email}:${password}`);

    return fetch('/if-api/backoffice/auth', {
      headers: {
        Authorization: token,
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          this.isAuthenticated = true;
        }
        return res;
      })
      .catch((error) => {
        console.error('Error:', error);
        return new Error('Login failed');
      });
  },
  async signout() {
    clearSessionCookie('JSESSIONID');
    return true;
  },
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [sessionCookie, setSessionCookie] = React.useState('');

  const signin = async (email, password) => {
    const data = await authProvider.signin(email, password);
    if (data instanceof Error) return data;

    setIsAuthenticated(true);
    setUser(data);

    // Removing a previously persisted juror_uuid to prevent session glitches, when doing a refresh in Preview mode.
    localStorage.removeItem('juror_uuid');

    return data;
  };

  const signout = (callback) => {
    setUser(null);
    setIsAuthenticated(false);
    callback();
  };

  let value = {
    user,
    isAuthenticated,
    sessionCookie,
    setSessionCookie,
    signin,
    signout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useJuror = () => {
  return React.useContext(JurorContext);
};

export const AUTH_STATES = {
  INIT: 'INIT',
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
};

export const RequireJuror = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { signIn, isLoading, authState } = useJuror();

  React.useEffect(() => {
    if (
      location.pathname === '/juror' ||
      location.pathname.match(/\/juror\/\w*-\w*-\w*-\w*-\w*$/)
    )
      return;

    if (authState === AUTH_STATES.INIT && !isLoading) {
      // Try to fetch account data with Cookie
      signIn().then((data) => {
        if (!data) {
          navigate('/juror');
        }
      });
    }
  }, [authState, isLoading, location, id]);

  return children;
};

export const JurorProvider = ({ children }) => {
  const [juror, setJuror] = React.useState(null);
  const [jurorGroup, setJurorGroup] = React.useState(null);
  const [authState, setAuthState] = React.useState(AUTH_STATES.INIT);
  const [session, setSession] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cookies, _, removeCookie] = useCookies(['isJurorPreview']);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // mirror jurorGroupID from internal state to search params only for if staff
    if (juror?.jurorGroups?.[0]?.role !== ROLES.staff || !jurorGroup) return;

    const params = new URLSearchParams(location?.search);

    if (params.get('jurorGroup') == jurorGroup?.jurorGroupId) return;

    params.set('jurorGroup', jurorGroup?.jurorGroupId);

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [location, jurorGroup]);

  const signIn = async (uuid, password) => {
    setIsLoading(true);

    let accountData = null;

    if (!uuid && !password) {
      accountData = await Account.fetchNoAuth();
    } else {
      removeCookie('JSESSIONID', { path: '/' });
      await Auth.logout();
      accountData = await Account.fetch(uuid, password);
    }

    if (!accountData) {
      setAuthState(AUTH_STATES.NOT_AUTHENTICATED);
      setIsLoading(false);
      return false;
    }

    if (uuid && !cookies?.isJurorPreview) {
      localStorage.setItem('juror_uuid', uuid);
    }

    const sessionData = await Session.fetch(accountData.sessionId);
    let selectedJurorGroup = accountData?.jurorGroups.length
      ? accountData.jurorGroups[0]
      : null;

    setAuthState(AUTH_STATES.AUTHENTICATED);
    setJuror(accountData);

    // select initial jurorGroup from search param for staff
    const params = new URLSearchParams(location?.search);
    const initialJurorGroupId = params.get('jurorGroup');
    const initialJurorGroup = accountData?.jurorGroups?.find(
      (jg) => jg.jurorGroupId == initialJurorGroupId
    );

    if (
      initialJurorGroup &&
      accountData?.jurorGroups?.[0]?.role === ROLES.staff
    ) {
      selectedJurorGroup = initialJurorGroup;
    }

    setJurorGroup(selectedJurorGroup);
    setSession(sessionData);
    setIsLoading(false);

    if (!hasAccess(sessionData, selectedJurorGroup?.role)) {
      navigate('/closed');
    }

    return accountData;
  };

  const signOut = async () => {
    setIsLoading(true);
    navigate('/juror/logout');

    const loggedOut = await Auth.logout();

    if (loggedOut) {
      localStorage.removeItem('juror_uuid');
      removeCookie('JSESSIONID', { path: '/' });
      clearJurorAuth();
      setIsLoading(false);
    }
  };

  const updateRole = (role) => {
    setJurorGroup((prev) => ({ ...prev, role }));
  };

  const selectJurorGroup = (jurorGroupId) => {
    const jurorGroup = juror.jurorGroups.find(
      (group) => group.jurorGroupId == jurorGroupId
    );
    setJurorGroup(jurorGroup);
  };

  const clearJurorAuth = () => {
    setJuror(null);
    setJurorGroup(null);
    setAuthState(AUTH_STATES.NOT_AUTHENTICATED);
    setSession(null);
  };

  let value = {
    juror,
    isLoading,
    session,
    signIn,
    isAuthenticated: authState === AUTH_STATES.AUTHENTICATED,
    role: jurorGroup?.role,
    updateRole,
    jurorGroup,
    setJurorGroup,
    selectJurorGroup,
    clearJurorAuth,
    setSession,
    isPreview: cookies?.isJurorPreview,
    authState,
    signOut,
  };

  return (
    <JurorContext.Provider value={value}>{children}</JurorContext.Provider>
  );
};
