import {
  faArrowRight,
  faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ROLES, SCOPES } from '../../../config/permissions/roles';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import { JUROR_GROUP_PHASES } from '../../../models/jurorGroup';
import PermissionHandler from '../../../modules/permissionHandler';
import styles from './styles.module.scss';

const AverageScoreColumn = ({
  averageScore,
  averageScoreFromPriorSession,
  submission,
  setScoreAlertModalData,
  onEntryClick,
  readOnly,
}) => {
  const { chairPersonMustDecide } = submission;
  const { t } = useTranslation();
  const { role, jurorGroup, session } = useJuror();

  const scoreAlert =
    submission?.scoreAlertA ||
    submission?.scoreAlertB ||
    submission?.scoreAlertC;
  const canSeeScoreAlert =
    role !== ROLES.juror ||
    jurorGroup.jurorGroupPhase !== JUROR_GROUP_PHASES.editPhase;
  const canClickScoreAlert = scoreAlert && !readOnly && canSeeScoreAlert;

  const handleOnScoreClick = () => {
    setScoreAlertModalData(submission.submissionId);
  };

  const navigateToDetail = () => {
    onEntryClick(
      `../entry/${submission.submissionId}${
        submission.jurorId ? `/${submission.jurorId}` : ''
      } `,
      submission,
      {
        jurorGroupId: submission.evaluations?.[0]?.jurorGroupId,
        jurorId: submission.evaluations?.[0]?.jurorId,
      }
    );
  };

  return (
    <div
      className={styles.averageScoreContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.left}>
        <div
          className={combine(
            styles.averageScore,
            styles.currentSession,
            scoreAlert && canSeeScoreAlert && styles.scoreAlert
          )}
          role={canClickScoreAlert && 'button'}
          onClick={handleOnScoreClick}
        >
          <span className={styles.value}>
            <PermissionHandler scopes={[SCOPES.canLetChairpersonDecide]}>
              {chairPersonMustDecide && (
                <span
                  className={styles.chairpersonLabel}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'jurorEntries.table.columns.averageScore.chairperson'
                    ),
                  }}
                ></span>
              )}
            </PermissionHandler>
            <PermissionHandler scopes={[SCOPES.chairPerson, SCOPES.staff]}>
              {averageScore.toFixed(0) || 0}
            </PermissionHandler>
            <PermissionHandler scopes={[SCOPES.juror, SCOPES.groupLeader]}>
              {!chairPersonMustDecide && (averageScore.toFixed(0) || 0)}
            </PermissionHandler>

            <PermissionHandler scopes={[SCOPES.canSeeScoreAlert]}>
              {scoreAlert && canSeeScoreAlert && (
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  size="xs"
                  className={styles.alertIcon}
                />
              )}
            </PermissionHandler>
          </span>
          {!readOnly && (
            <span className={styles.title}>
              {t(
                `jurorEntries.table.columns.averageScore.${session?.sessionType}`
              )}
            </span>
          )}
        </div>

        {averageScoreFromPriorSession && (
          <div className={combine(styles.averageScore, styles.priorSession)}>
            <span className={styles.value}>{averageScoreFromPriorSession}</span>
            {!readOnly && (
              <span className={styles.title}>
                {t(
                  `jurorEntries.table.columns.averageScore.${submission?.scoresFromPriorSessions?.[0]?.sessionType}`
                )}
              </span>
            )}
          </div>
        )}
      </div>
      {!readOnly && (
        <FontAwesomeIcon
          icon={faArrowRight}
          size="2x"
          className={combine(
            styles.arrow,
            role == ROLES.chairPerson &&
              !submission?.chairPersonMustDecide &&
              styles.blue
          )}
          onClick={navigateToDetail}
        />
      )}
    </div>
  );
};

AverageScoreColumn.propTypes = {
  averageScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  averageScoreFromPriorSession: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  submission: PropTypes.object,
  openScoreAlertModal: PropTypes.func,
  onEntryClick: PropTypes.func,
  readOnly: PropTypes.bool,
};

AverageScoreColumn.defaultProps = {
  averageScore: 0,
  averageScoreFromPriorSession: null,
  submission: {},
  openScoreAlertModal: () => {},
  onEntryClick: () => {},
  readOnly: false,
};

export default AverageScoreColumn;
