import Juror from '../juror';

export const JUROR_GROUP_PHASES = {
  editPhase: 'EDIT_PHASE',
  discussionPhase: 'DISCUSSION_PHASE',
  completed: 'COMPLETED',
};

class JurorGroup {
  constructor(
    jurorGroupId,
    jurorGroupName,
    submissionGroupId,
    submissionGroupName,
    submissionCount,
    jurors,
    numMandatoryMeetings,
    numOptionalMeetings,
    primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup,
    primaryMediaUrlOfLowestSubmissionIdInGroup,
    jurorGroupPhase
  ) {
    this.jurorGroupId = jurorGroupId;
    this.jurorGroupName = jurorGroupName;
    this.submissionGroupId = submissionGroupId;
    this.submissionGroupName = submissionGroupName;
    this.submissionCount = submissionCount;
    this.jurors = jurors;
    this.numMandatoryMeetings = numMandatoryMeetings;
    this.numOptionalMeetings = numOptionalMeetings;
    this.primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup =
      primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup;
    this.primaryMediaUrlOfLowestSubmissionIdInGroup =
      primaryMediaUrlOfLowestSubmissionIdInGroup;
    this.jurorGroupPhase = jurorGroupPhase;
  }

  static from = (jurorGroup) => {
    if (!jurorGroup) return null;

    const jurors = Juror.of(jurorGroup?.jurors);

    return new JurorGroup(
      jurorGroup.jurorGroupId,
      jurorGroup.jurorGroupName,
      jurorGroup.submissionGroupId,
      jurorGroup.submissionGroupName,
      jurorGroup.submissionCount,
      jurors,
      jurorGroup.numMandatoryMeetings,
      jurorGroup.numOptionalMeetings,
      jurorGroup.primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup,
      jurorGroup.primaryMediaUrlOfLowestSubmissionIdInGroup,
      jurorGroup.jurorGroupPhase
    );
  };

  static of = (jurorGroups) => {
    return jurorGroups?.map((jurorGroup) => JurorGroup.from(jurorGroup));
  };

  static fetchAll = async (sessionId) => {
    if (!sessionId) return [];

    return fetch(`/if-api/jury-v2/jurorGroups?sessionId=${sessionId}`)
      .then((response) => response.json())
      .then((jurorGroups) => JurorGroup.of(jurorGroups))
      .catch((err) => {
        console.error(err);
      });
  };

  static create = async (jurorGroup, sessionId) => {
    return fetch(`/if-api/jury-v2/jurorGroups?sessionId=${sessionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jurorGroup),
    })
      .then((response) => response.json())
      .then((jurorGroup) => JurorGroup.from(jurorGroup))
      .catch((err) => console.error(err));
  };

  static update = async (editedJurorGroup, jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedJurorGroup),
    })
      .then((response) => response.json())
      .then((jurorGroup) => JurorGroup.from(jurorGroup))
      .catch((err) => console.error(err));
  };

  static setEditPhase = async (jurorGroupId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/phase/EDIT_PHASE`,
      {
        method: 'PUT',
      }
    ).catch((err) => console.error(err));
  };

  static setDiscussionPhase = async (jurorGroupId) => {
    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/phase/DISCUSSION_PHASE`,
      {
        method: 'PUT',
      }
    ).catch((err) => console.error(err));
  };

  static setCompleted = async (jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/phase/COMPLETED`, {
      method: 'PUT',
    }).catch((err) => console.error(err));
  };

  static delete = async (jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}`, {
      method: 'DELETE',
    }).catch((err) => console.error(err));
  };
}

export default JurorGroup;
