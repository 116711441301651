export const AWARD_TYPES = {
  IF_FDA: 'IF_FDA',
  IF_DTA: 'IF_DTA',
  IF_DA: 'IF_DA',
  IF_CDA: 'IF_CDA',
  IF_SIP: 'IF_SIP',
  OTHER: 'OTHER',
};

class Award {
  constructor(awardId, awardName, awardType) {
    this.awardId = awardId;
    this.awardName = awardName;
    this.awardType = awardType;
  }

  static from = (award) => {
    if (!award) return null;

    return new Award(award.awardId, award.awardName, award.awardType);
  };

  static of = (awards) => {
    return awards?.map((award) => Award.from(award));
  };

  static fetchAll = async () => {
    return fetch(`/if-api/jury-v2/awards/active`)
      .then((response) => response.json())
      .then((awards) => Award.of(awards))
      .catch((err) => {
        console.error(err);
      });
  };
}

export default Award;
