import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo as faCircleInfoLight } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faCircleInfo,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { combine } from '../../../../helpers/styles';
import styles from './styles.module.scss';

const EvaluationCategory = ({
  category,
  text,
  isActive,
  maxPoints,
  onCategoryClick,
  handleEvaluationChange,
  pointsStep,
  scoreFromPriorSession,
  disabled,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [maxHeight, setMaxHeight] = useState('103px');

  useEffect(() => {
    setMaxHeight(isActive ? `${ref.current.clientHeight + 113}px` : '103px');
  }, [isActive]);

  const hasPoints = category.points !== null;

  const rangeDescriptionStyle = {
    left: `calc(${category.points * (50 / maxPoints) * 2}% + ${
      -2 + category.points * (50 / maxPoints) * -0.06
    }rem`,
  };

  const scoreFromPriorSessionStyle = {
    left: `calc(${scoreFromPriorSession * (50 / maxPoints) * 2}% + ${
      -2 + scoreFromPriorSession * (50 / maxPoints) * -0.055
    }rem`,
  };

  const containerStyle = {
    transition: 'max-height 0.5s ease-in-out',
    maxHeight: maxHeight,
  };

  const handleCategoryClick = () => {
    if (!isActive) {
      onCategoryClick(true);
    }
  };

  const handleCategoryHeaderClick = (e) => {
    e.stopPropagation();
    onCategoryClick(!isActive);
  };

  const getRangeDescription = (points) => {
    const relativePoints = points / maxPoints;

    if (relativePoints === 0) {
      return t('evaluation.rangeLegend.inadequate');
    } else if (relativePoints <= 0.2) {
      return t('evaluation.rangeLegend.poor');
    } else if (relativePoints <= 0.4) {
      return t('evaluation.rangeLegend.belowAverage');
    } else if (relativePoints <= 0.6) {
      return t('evaluation.rangeLegend.average');
    } else if (relativePoints <= 0.8) {
      return t('evaluation.rangeLegend.aboveAverage');
    } else if (relativePoints <= 0.9) {
      return t('evaluation.rangeLegend.excellent');
    }
    return t('evaluation.rangeLegend.outstanding');
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className={styles.tooltip} {...props}>
      <FontAwesomeIcon icon={faCircleInfoLight} size="2x" />
      <p
        dangerouslySetInnerHTML={{ __html: category.criterion.description }}
      ></p>
    </Tooltip>
  );

  return (
    <li
      className={combine(
        styles.evaluationCriterion,
        isActive && styles.expanded
      )}
      onClick={handleCategoryClick}
    >
      <Container>
        <Row>
          <Col
            xs={12}
            sm={6}
            className={styles.leftSection}
            style={containerStyle}
          >
            <div className={styles.header} onClick={handleCategoryHeaderClick}>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={combine(styles.icon, isActive && styles.rotated)}
              />
              <h3 className={styles.criterionTitle}>
                {category.criterion.name}
              </h3>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 600 }}
                overlay={renderTooltip}
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 20],
                      },
                    },
                  ],
                }}
              >
                <span>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
              </OverlayTrigger>
            </div>
            <div
              className={combine(
                styles.description,
                isActive && styles.visible
              )}
            >
              <p ref={ref} dangerouslySetInnerHTML={{ __html: text }}>
                {/*text ? text : '-'*/}
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            className={combine(
              styles.rightSection,
              isActive && styles.expanded
            )}
          >
            <div className={styles.sliderContainer}>
              <input
                value={hasPoints ? category.points : (maxPoints / 2).toFixed()}
                onChange={(e) => {
                  handleEvaluationChange(e.target.value, category.criterion.id);
                }}
                name={`range_${category.criterion.id}`}
                min="0"
                max={maxPoints}
                step={pointsStep || 1}
                type="range"
                disabled={disabled}
                className={combine(
                  styles.slider,
                  isActive && styles.expanded,
                  !hasPoints && styles.noValue
                )}
              />
              {isActive && hasPoints && (
                <div
                  className={styles.rangeDescriptionContainer}
                  style={rangeDescriptionStyle}
                >
                  <span className={styles.rangeDescription}>
                    {getRangeDescription(category.points)}
                  </span>
                </div>
              )}
              {scoreFromPriorSession && (
                <>
                  <div
                    className={combine(
                      styles.rangeDescriptionContainer,
                      styles.scoreFromPriorSession
                    )}
                    style={scoreFromPriorSessionStyle}
                  >
                    <span className={styles.rangeDescription}>
                      <FontAwesomeIcon icon={faCaretUp} size="2x" />
                    </span>
                  </div>
                  {isActive && (
                    <div
                      className={combine(
                        styles.rangeDescriptionContainer,
                        styles.scoreFromPriorSessionValue
                      )}
                      style={scoreFromPriorSessionStyle}
                    >
                      <span>{scoreFromPriorSession}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.points}>
              {hasPoints && (
                <span className={styles.pointsElement}>{category.points}</span>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </li>
  );
};

EvaluationCategory.propTypes = {
  category: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  maxPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onCategoryClick: PropTypes.func.isRequired,
  handleEvaluationChange: PropTypes.func.isRequired,
  pointsStep: PropTypes.number,
  scoreFromPriorSession: PropTypes.number,
  disabled: PropTypes.bool,
};

export default EvaluationCategory;
