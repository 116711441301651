import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { PERMISSIONS, ROLES } from '../../config/permissions/roles';
import { useAuth, useJuror } from '../../hooks/useAuth';

const hasPermission = ({ permissions, scopes }) => {
  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission) => scopesMap[permission]);
};

const PermissionHandler = ({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
}) => {
  const { role: r } = useJuror();
  const { isAuthenticated } = useAuth();

  let role = r;

  if (!role && !isAuthenticated) {
    return false;
  }

  if (isAuthenticated) {
    role = ROLES.staff;
  }

  const permissions = PERMISSIONS[role];
  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted && !errorProps) return <RenderError />;

  if (!permissionGranted && errorProps)
    return cloneElement(children, { ...errorProps });

  return <>{children}</>;
};

PermissionHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  permission: PropTypes.string,
};

PermissionHandler.defaultProps = {};

export default PermissionHandler;
