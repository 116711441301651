import EvaluationCriterion from '../evaluationCriterion';

export const SESSION_TYPES = {
  SINGLE_SESSION: 'SINGLE_SESSION',
  PRESELECTION: 'PRESELECTION',
  FINAL_JURY: 'FINAL_JURY',
};

class Session {
  constructor(
    sessionId,
    sessionName,
    sessionType,
    predecessorSessionId,
    awardId,
    awardName,
    awardType,
    startDate,
    endDate,
    maxPointsPerCriterion,
    pointsStep,
    maxNominationsPerGroup,
    scoreAlertAThreshold,
    scoreAlertBThresholdLo,
    scoreAlertBThresholdHi,
    scoreAlertCThreshold,
    threshold,
    criteria,
    prizeMoneyModeEnabled,
    expoModeEnabled,
    nominationPublishRestrictionLimit,
    uiConfig
  ) {
    this.sessionId = sessionId;
    this.sessionName = sessionName;
    this.sessionType = sessionType;
    this.predecessorSessionId = predecessorSessionId;
    this.awardId = awardId;
    this.awardName = awardName;
    this.awardType = awardType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.maxPointsPerCriterion = maxPointsPerCriterion;
    this.pointsStep = pointsStep;
    this.maxNominationsPerGroup = maxNominationsPerGroup;
    this.scoreAlertAThreshold = scoreAlertAThreshold;
    this.scoreAlertBThresholdLo = scoreAlertBThresholdLo;
    this.scoreAlertBThresholdHi = scoreAlertBThresholdHi;
    this.scoreAlertCThreshold = scoreAlertCThreshold;
    this.threshold = threshold;
    this.criteria = criteria;
    this.prizeMoneyModeEnabled = prizeMoneyModeEnabled;
    this.expoModeEnabled = expoModeEnabled;
    this.nominationPublishRestrictionLimit = nominationPublishRestrictionLimit;
    this.uiConfig = uiConfig;
  }

  static from = (session) => {
    if (!session) return null;

    return new Session(
      session.sessionId,
      session.sessionName,
      session.sessionType,
      session.predecessorSessionId,
      session.awardId,
      session.awardName,
      session.awardType,
      session.startDate,
      session.endDate,
      session.maxPointsPerCriterion,
      session.pointsStep,
      session.maxNominationsPerGroup,
      session.scoreAlertAThreshold,
      session.scoreAlertBThresholdLo,
      session.scoreAlertBThresholdHi,
      session.scoreAlertCThreshold,
      session.threshold,
      EvaluationCriterion.of(session.criteria),
      session.prizeMoneyModeEnabled,
      session.expoModeEnabled,
      session.nominationPublishRestrictionLimit,
      session.uiConfig
    );
  };

  static of = (sessions) => {
    return sessions?.map((session) => Session.from(session));
  };

  static fetch = async (id) => {
    if (!id) {
      return null;
    }
    return fetch(`/if-api/jury-v2/session/${id}`)
      .then((response) => response.json())
      .then((session) => Session.from(session))
      .catch((err) => {
        console.error(err);
      });
  };

  static fetchAll = async ({ archived }) => {
    return fetch(`/if-api/jury-v2/sessions?archived=${archived}`)
      .then((response) => response.json())
      .then((sessions) => Session.of(sessions))
      .catch((err) => {
        console.error(err);
      });
  };

  static create = async (session) => {
    return fetch(`/if-api/jury-v2/sessions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(session),
    }).catch((err) => console.error(err));
  };

  static update = async (session) => {
    return fetch(`/if-api/jury-v2/session/${session.sessionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(session),
    })
      .then((response) => response.json())
      .then((session) => Session.from(session))
      .catch((err) => console.error(err));
  };

  static delete = async (sessionId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}`, {
      method: 'DELETE',
    }).catch((err) => console.error(err));
  };

  static archive = async (sessionId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/archived`, {
      method: 'POST',
    }).catch((err) => console.error(err));
  };

  static unarchive = async (sessionId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/archived`, {
      method: 'DELETE',
    }).catch((err) => console.error(err));
  };

  static activatePrizeMoneyMode = async (sessionId) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/mode/prizeMoneyModeEnabled`,
      {
        method: 'PUT',
      }
    ).catch((err) => console.error(err));
  };

  static deactivatePrizeMoneyMode = async (sessionId) => {
    return fetch(
      `/if-api/jury-v2/session/${sessionId}/mode/prizeMoneyModeEnabled`,
      {
        method: 'DELETE',
      }
    ).catch((err) => console.error(err));
  };

  static activateExpoMode = async (sessionId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/mode/expoModeEnabled`, {
      method: 'PUT',
    })
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };

  static deactivateExpoMode = async (sessionId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/mode/expoModeEnabled`, {
      method: 'DELETE',
    })
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };
}

export default Session;
