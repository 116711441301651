import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faBold,
  faItalic,
  faListUl,
  faListOl,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBold as faBoldBold,
  faItalic as faItalicBold,
  faListUl as faListUlBold,
  faListOl as faListOlBold,
} from '@fortawesome/pro-regular-svg-icons';
import { combine } from '../../helpers/styles';
import 'draft-js/dist/Draft.css';
import styles from './styles.module.scss';

const TextEditor = ({ label, onChange, required, value }) => {
  const [editorState, setEditorState] = useState(() => {
    const rawData = markdownToDraft(value);
    const contentState = convertFromRaw(rawData);
    return EditorState.createWithContent(contentState);
  });
  const [visited, setVisited] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const currentBlock = editorState.getSelection().getStartKey();
  const currentContent = editorState.getCurrentContent();
  const currentBlockType = currentContent
    .getBlockForKey(currentBlock)
    .getType();
  const isEmpty = currentContent.getPlainText() === '';
  const inBoldState = currentInlineStyle.has('BOLD');
  const inItalicState = currentInlineStyle.has('ITALIC');
  const inUnorderedListBlock = currentBlockType === 'unordered-list-item';
  const inOrderedListBlock = currentBlockType === 'ordered-list-item';
  const error = visited && isEmpty;

  useEffect(() => {
    if (!visited && !isFocused && value) {
      const rawData = markdownToDraft(value);
      const contentState = convertFromRaw(rawData);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [visited, isFocused, value]);

  const exportMarkdown = (rawText) => {
    const markdownText = draftToMarkdown(rawText);
    onChange(markdownText);
  };

  const undo = (e) => {
    e.preventDefault();
    setEditorState((prev) => EditorState.undo(prev));
  };

  const toggleBold = (e) => {
    e.preventDefault();
    setEditorState((prev) => RichUtils.toggleInlineStyle(prev, 'BOLD'));
  };

  const toggleItalic = (e) => {
    e.preventDefault();
    setEditorState((prev) => RichUtils.toggleInlineStyle(prev, 'ITALIC'));
  };

  const toggleUnorderedList = (e) => {
    e.preventDefault();
    setEditorState((prev) =>
      RichUtils.toggleBlockType(prev, 'unordered-list-item')
    );
  };

  const toggleOrderedList = (e) => {
    e.preventDefault();
    setEditorState((prev) =>
      RichUtils.toggleBlockType(prev, 'ordered-list-item')
    );
  };

  return (
    <div className={combine(styles.textEditor, error && styles.error)}>
      <div className={styles.toolbar}>
        <button className={styles.button} onMouseDown={undo} type="button">
          <FontAwesomeIcon icon={faArrowRotateLeft} />
        </button>
        <button
          className={combine(styles.button, inBoldState && styles.active)}
          onMouseDown={toggleBold}
          type="button"
        >
          <FontAwesomeIcon icon={!inBoldState ? faBold : faBoldBold} />
        </button>
        <button
          className={combine(styles.button, inItalicState && styles.active)}
          onMouseDown={toggleItalic}
          type="button"
        >
          <FontAwesomeIcon icon={!inItalicState ? faItalic : faItalicBold} />
        </button>
        <button
          className={combine(
            styles.button,
            inUnorderedListBlock && styles.active
          )}
          onMouseDown={toggleUnorderedList}
          type="button"
        >
          <FontAwesomeIcon
            icon={!inUnorderedListBlock ? faListUl : faListUlBold}
          />
        </button>
        <button
          className={combine(
            styles.button,
            inOrderedListBlock && styles.active
          )}
          onMouseDown={toggleOrderedList}
          type="button"
        >
          <FontAwesomeIcon
            icon={!inOrderedListBlock ? faListOl : faListOlBold}
          />
        </button>
      </div>

      <div className={styles.textFieldContainer}>
        <div className={styles.labelContainer}>
          <span
            className={combine(
              styles.label,
              required && styles.required,
              (isFocused || !isEmpty) && styles.small
            )}
          >
            {label}
          </span>
        </div>
        <div className={styles.textField}>
          <Editor
            editorState={editorState}
            onChange={(editorState) => {
              setEditorState(editorState);
              exportMarkdown(convertToRaw(editorState.getCurrentContent()));
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setVisited(true);
              setIsFocused(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};

TextEditor.defaultProps = {
  label: '',
  onChange: null,
  required: false,
  value: null,
};

export default TextEditor;
