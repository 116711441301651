import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { ROLES } from '../../../../config/permissions/roles';
import styles from './styles.module.scss';
import { getProgressPrecentage } from '../../../../helpers/statistic';
import variables from '../../../../styles/__variables.scss';
import ProgressBar from '../../../progressBar';
import { useJuror } from '../../../../hooks/useAuth';
import { AWARD_ROLE, GROUP_ROLE } from '../../../../models/evaluationStatistic';

const ProgressStatistic = ({ statistic }) => {
  const { t } = useTranslation();
  const { jurorGroup, session } = useJuror();

  const jurorStats = useMemo(() => {
    if (!statistic) return [];

    return statistic.jurors
      .filter(
        (stats) =>
          stats.role === ROLES.juror || stats.role === ROLES.groupLeader
      )
      .map((stats) => ({
        ...stats,
        progressPercentage: getProgressPrecentage(
          stats.decidedEvaluationCount,
          stats.totalEvaluationCount
        ),
      }));
  }, [statistic]);

  const groupProgress = useMemo(() => {
    if (!statistic) return 0;

    const groupStats = statistic.jurors.find(
      (stats) => stats.role === GROUP_ROLE
    );
    return getProgressPrecentage(
      groupStats.decidedEvaluationCount,
      groupStats.totalEvaluationCount
    );
  }, [statistic]);

  const awardProgress = useMemo(() => {
    if (!statistic) return 0;

    const awardStats = statistic.jurors.find(
      (stats) => stats.role === AWARD_ROLE
    );

    return getProgressPrecentage(
      awardStats.decidedEvaluationCount,
      awardStats.totalEvaluationCount
    );
  }, [statistic]);

  return (
    <div className={styles.container}>
      <ul className={styles.jurorList}>
        {jurorStats.map((stat) => (
          <li key={stat.jurorId} className={styles.jurorStat}>
            <div className={styles.imageContainer}>
              {stat.profilePictureUrl ? (
                <img
                  className={styles.image}
                  src={stat.profilePictureUrl}
                  alt="juror-profile-picture"
                />
              ) : (
                <div className={styles.iconContainer}>
                  <FontAwesomeIcon icon={faUser} className={styles.icon} />
                </div>
              )}
            </div>
            <div className={styles.textContainer}>
              <span
                className={styles.name}
              >{`${stat.firstname} ${stat.lastname}`}</span>
              <span className={styles.role}>{t(`roles.${stat.role}`)}</span>
            </div>
            <div>
              <ProgressBar
                value={stat.progressPercentage}
                color={variables.secondaryBlue}
                backgroundColor={variables.secondaryLightGrey}
                withMarker
                markerColor={variables.secondaryGrey}
                markerWidth="1px"
              />
            </div>
            <span
              className={styles.progressPercentage}
            >{`${stat.progressPercentage} %`}</span>
          </li>
        ))}
      </ul>
      <div className={styles.groupContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={faUserGroup} className={styles.icon} />
          </div>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.name}>{jurorGroup?.jurorGroupName}</span>
        </div>
        <div>
          <ProgressBar
            value={groupProgress}
            color={variables.secondaryBlue}
            backgroundColor={variables.secondaryLightGrey}
            withMarker
            markerColor={variables.secondaryGrey}
            markerWidth="1px"
          />
        </div>
        <span
          className={styles.progressPercentage}
        >{`${groupProgress} %`}</span>
      </div>
      <div className={styles.awardContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={faUserGroup} className={styles.icon} />
          </div>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.name}>{session?.awardName}</span>
        </div>
        <div>
          <ProgressBar
            value={awardProgress}
            color={variables.secondaryBlue}
            backgroundColor={variables.secondaryLightGrey}
            withMarker
            markerColor={variables.secondaryGrey}
            markerWidth="1px"
          />
        </div>
        <span
          className={styles.progressPercentage}
        >{`${awardProgress} %`}</span>
      </div>
    </div>
  );
};

export default ProgressStatistic;
