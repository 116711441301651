export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const clearSessionCookie = (name, path = '/') => {
  var path = path || '/';
  document.cookie = name + '=null; path=' + path + ';';
};
