import PropTypes from 'prop-types';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import Avatar from '../avatar';
import Loading from '../loading';
import styles from './styles.module.scss';

const jurorEmail = (_, row) => {
  return <div className="d-flex flex-column">{row.email}</div>;
};

const jurorCountry = (_, row) => {
  return <div className="d-flex flex-column">{row.countryIsoCode}</div>;
};

const jurorName = (_, row) => {
  return (
    <div className="d-flex flex-column">
      <a
        href={
          'https://ifdesign.com/en/if-design-award-and-jury/jury/profile/jury-tool/' +
          row.jurorId
        }
        target={"_blank"}
        className={styles.jurorName}
      >
        <Avatar
          name={`${row.firstname} ${row.lastname}`}
          img={row.profilePictureUrl}
        />
      </a>
    </div>
  );
};

const JurorsTable = ({ jurors, loading }) => {
  const { t } = useTranslation();
  const columns = [
    {
      dataField: 'jurorName',
      text: t('jurors.list.table.nameColumn'),
      headerStyle: () => {
        return { width: '400px' };
      },
      formatter: (cell, row) => jurorName(cell, row, t),
    },
    {
      dataField: 'jurorCountry',
      text: t('jurors.list.table.countryColumn'),
      headerStyle: () => {
        return { width: '100px' };
      },
      classes: '',
      formatter: (_, row) => jurorCountry(_, row, t),
    },
    {
      dataField: 'jurorEmail',
      isDummyField: true,
      text: t('jurors.list.table.emailColumn'),
      classes: () => styles.multiValuedCell,
      headerStyle: () => {
        return { width: '300px' };
      },
      formatter: (_, row) => jurorEmail(_, row),
    },
  ];

  return (
    <BootstrapTable
      keyField="jurorId"
      data={jurors || []}
      columns={columns}
      wrapperClasses={styles.groupsTable}
      noDataIndication={() => (
        <>
          {loading ? (
            <Loading />
          ) : (
            <span className={styles.emptyMessage}>
              {t('jurors.list.table.noJurorGroups')}
            </span>
          )}
        </>
      )}
    />
  );
};

JurorsTable.propTypes = {
  jurors: PropTypes.array,
  loading: PropTypes.bool,
};

JurorsTable.defaultProps = {
  jurors: [],
  loading: false,
};

export default JurorsTable;
