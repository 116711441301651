import { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { combine } from '../../helpers/styles';
import Input from '../input';
import Loading from '../loading';
import styles from './styles.module.scss';

const InputAutoFill = ({
  options,
  optionsLoading,
  value,
  onChange,
  onClick,
  label,
  readOnly,
  required,
  handleFilter,
}) => {
  const ref = useRef();
  const [showSuggestions, setShowSuggestions] = useState();

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, setShowSuggestions]);

  const filteredOptions = useMemo(() => {
    if (!options) return [];
    if (!handleFilter) return options;
    return options.filter((option) =>
      option.value.toLowerCase().includes(value.trim().toLowerCase())
    );
  }, [value, options]);

  return (
    <div ref={ref} className={styles.inputAutoFill} onClick={onClick}>
      <Input
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClick={() => {
          setShowSuggestions(true);
        }}
        required={required}
        readOnly={readOnly}
      />
      {!readOnly && showSuggestions && (
        <>
          {optionsLoading ? (
            <div className={combine(styles.suggestionList, styles.loading)}>
              <Loading />
            </div>
          ) : (
            <ul className={styles.suggestionList}>
              {filteredOptions.map((option) => (
                <li
                  className={styles.item}
                  key={option.id}
                  onClick={() => {
                    onChange(option.value);
                    setShowSuggestions(false);
                  }}
                >
                  <span className={styles.itemText}>{option.value}</span>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

InputAutoFill.propTypes = {
  options: PropTypes.array,
  optionsLoading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  handleFilter: PropTypes.bool,
};

InputAutoFill.defaultProps = {
  options: [],
  optionsLoading: false,
  value: '',
  onChange: () => null,
  onClick: () => null,
  label: '',
  readOnly: false,
  required: false,
  handleFilter: true,
};

export default InputAutoFill;
