import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-scanner';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/loading';
import { combine } from '../../../../helpers/styles';
import { useJuror } from '../../../../hooks/useAuth';
import Submission from '../../../../models/submission';
import styles from './styles.module.scss';

const QRScanner = () => {
  const [scannerLoaded, setScannerLoaded] = useState(false);
  const { entryId } = useParams();
  const [data, setData] = useState('');
  const [error, setError] = useState(false);
  const { juror } = useJuror();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (data || entryId) {
      const submissionId = entryId || data.split('/').pop();
      Submission.getEvaluations(submissionId).then((evaluations) => {
        const matchingJurorGroup = juror.jurorGroups.find(
          (jg) => jg.jurorGroupId === evaluations[0]?.jurorGroupId
        );

        if (matchingJurorGroup) {
          return navigate(`/juror/entry/${submissionId}`, {
            state: {
              jurorGroupId: matchingJurorGroup.jurorGroupId,
            },
          });
        }
        return navigate(
          `/juror/entry/${submissionId}/${evaluations[0]?.jurorId}`,
          {
            state: {
              jurorGroupId: evaluations[0]?.jurorGroupId,
            },
          }
        );
      });
    }
  }, [data, entryId]);

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{t('submissionScan.title')}</h2>
        <p className={styles.instructions}>
          {t('submissionScan.instructions')}
        </p>
      </div>
      <div className={styles.scannerContainer}>
        {error ? (
          <div className={styles.loadingContainer}>
            <span className={styles.errorMessage}>
              {t('submissionScan.errorMessage')}
            </span>
          </div>
        ) : (
          <>
            {!data && (
              <QrReader
                delay={100}
                onError={() => {
                  setError(true);
                }}
                onScan={(scanData) => {
                  if (scanData) {
                    setData(scanData?.text);
                  }
                }}
                className={combine(
                  styles.scanner,
                  scannerLoaded && styles.active
                )}
                constraints={{
                  audio: false,
                  video: { facingMode: { ideal: 'environment' } },
                }}
                onLoad={() => {
                  setScannerLoaded(true);
                }}
              />
            )}
            {!scannerLoaded && (
              <span className={styles.accessText}>
                {t('submissionScan.cameraAccess')}
              </span>
            )}
            {data && (
              <div className={styles.loadingContainer}>
                <Loading />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QRScanner;
