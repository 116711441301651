import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { isAwardType } from '../../../../helpers/awardTypes';
import { AWARD_TYPES } from '../../../../models/award';
import { useJuror } from '../../../../hooks/useAuth';
import { translateCountryCode } from '../../../../helpers/countryCodes';

const CompanyCardWrapper = ({ children, profileId }) => {
  const { session } = useJuror();

  return !isAwardType(AWARD_TYPES.IF_DTA, session) &&
    !isAwardType(AWARD_TYPES.IF_DA, session) &&
    profileId ? (
    <a
      target="_blank"
      href={`https://ifdesign.com/en/brands-creatives/company/profile/${profileId}`}
      className={styles.companyWrapper}
    >
      {children}
    </a>
  ) : (
    <div className={styles.companyWrapper}>{children}</div>
  );
};

const CompanyCard = ({
  name,
  city,
  countryCode,
  image,
  profileId,
  freeText,
}) => {
  const { session } = useJuror();

  return (
    <CompanyCardWrapper profileId={profileId}>
      {!isAwardType(AWARD_TYPES.IF_DTA, session) &&
        !isAwardType(AWARD_TYPES.IF_DA, session) &&
        image && (
          <img src={image} alt={name} loading="lazy" className={styles.img} />
        )}
      <div className={styles.nameWrapper}>
        {isAwardType(AWARD_TYPES.IF_DTA, session) && freeText && (
          <span className={styles.companyName}>{freeText}</span>
        )}
        <span className={styles.companyName}>{name}</span>
        {(isAwardType(AWARD_TYPES.IF_DTA, session) ||
          isAwardType(AWARD_TYPES.IF_DA, session)) &&
        countryCode ? (
          <span>{`${city}, ${translateCountryCode(countryCode)}`}</span>
        ) : (
          <span>{city}</span>
        )}
      </div>
      {!isAwardType(AWARD_TYPES.IF_DTA, session) &&
        !isAwardType(AWARD_TYPES.IF_DA, session) &&
        profileId && (
          <FontAwesomeIcon
            icon={faArrowRight}
            className={styles.icon}
            size="2x"
          />
        )}
    </CompanyCardWrapper>
  );
};

CompanyCard.propTypes = {
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  profileId: PropTypes.number,
  freeText: PropTypes.string,
  countryCode: PropTypes.string,
};

export default CompanyCard;
