class Account {
  constructor(
    jurorId,
    sessionId,
    firstname,
    lastname,
    jurorGroups,
    profilePictureUrl
  ) {
    this.jurorId = jurorId;
    this.sessionId = sessionId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.jurorGroups = jurorGroups;
    this.profilePictureUrl = profilePictureUrl;
  }

  static from = (account) => {
    return new Account(
      account.jurorId,
      account.sessionId,
      account.firstname,
      account.lastname,
      account.jurorGroups,
      account.profilePictureUrl
    );
  };

  static fetch = async (uuid, password) => {
    const base64Token = btoa(`${uuid}:${password}`);

    return fetch(`/if-api/jury-v2/account`, {
      headers: {
        Authorization: `Basic ${base64Token}`,
      },
    })
      .then((response) => response.json())
      .then((account) => Account.from(account))
      .catch((err) => console.error(err));
  };

  static fetchNoAuth = async () => {
    return fetch(`/if-api/jury-v2/account`)
      .then((response) => response.json())
      .then((account) => Account.from(account))
      .catch((err) => console.error(err));
  };
}

export default Account;
