import React from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../../modules/layout';

const Admin = () => {
  return (
    <Layout type={'admin'}>
      <h3>Admin</h3>
      <Outlet />
    </Layout>
  );
};

export default Admin;
