import { faEnvelope, faGlobe, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import * as React from 'react';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

const ContactBlock = ({ title, description, contacts, className }) => {
  return (
    <div className={combine(styles.contactBlock, className)}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <p>{description}</p>}
      {contacts.map((contact, i) => (
        <div key={contact.name} className={combine("d-flex flex-column", (title || i !== 0) && "mt-3")}>
          <span className={styles.name}>{contact.name}</span>
          <span>
            <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </span>
          <span>
            <FontAwesomeIcon icon={faPhone} className={styles.icon} />
            {contact.phone}
          </span>
          {contact.website && <span>
            <FontAwesomeIcon icon={faGlobe} className={styles.icon} />
            <a href={contact.website}>{contact.website}</a>
          </span>}
        </div>
      ))}
    </div>
  );
};

ContactBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      website: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

ContactBlock.defaultProps = {
  contacts: [],
};

export default ContactBlock;
