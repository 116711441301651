import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPlus as faPlusBold } from '@fortawesome/pro-regular-svg-icons';
import React, { useState, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import InformationMaterialTable from '../../../../components/informationTable';
import InformationMedia from '../../../../models/informationMedia';
import Session from '../../../../models/session';
import Hero from '../../../../modules/hero';
import InformationMaterialDialog from '../../../../modules/informationMaterialDialog';
import styles from './styles.module.scss';

const InformationMaterial = () => {
  const { t } = useTranslation();

  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [informationMaterial, setInformationMaterial] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    Session.fetchAll({ archived: false }).then((sessions) => {
      setSessions(sessions);
    });
  }, [setSessions]);

  useEffect(() => {
    if (!selectedSession) return;
    InformationMedia.adminFetchAllForSession(selectedSession.sessionId).then(
      (items) => {
        setInformationMaterial(items);
      }
    );
  }, [selectedSession, setInformationMaterial]);

  const sessionOptions = useMemo(
    () =>
      sessions.map((session) => ({
        value: session.sessionId,
        text: session.sessionName,
      })),
    [sessions]
  );

  const handleSessionChange = (value) => {
    setSelectedSession(sessions.find((session) => session.sessionId == value));
  };

  const handleCreateInformation = (newInformation) => {
    InformationMedia.create(
      selectedSession.sessionId,
      newInformation.values
    ).then((newItem) => {
      if (newItem) {
        setInformationMaterial((prev) => [...prev, newItem]);
        setShowAddModal(false);
      }
    });
  };

  return (
    <div className={styles.informationMaterial}>
      <Hero
        title={t('sessions.informationMaterial.title', {
          numInformation: informationMaterial.length,
        })}
        rightSection={
          <Dropdown
            label={t('sessions.meetings.sessionDropdown')}
            options={sessionOptions}
            value={selectedSession?.sessionId}
            onChange={handleSessionChange}
          />
        }
      />

      <Container>
        <div className={styles.tableSection}>
          <InformationMaterialTable
            informationMedia={informationMaterial}
            setInformationMedia={setInformationMaterial}
          />
        </div>
        <div className={styles.createNewInformationSection}>
          <Button
            type={ButtonTypes.PRIMARY}
            icon={faPlus}
            iconBold={faPlusBold}
            text={t('sessions.informationMaterial.addButton')}
            onClick={() => setShowAddModal(true)}
          />
        </div>
      </Container>
      {showAddModal && (
        <InformationMaterialDialog
          onClose={() => setShowAddModal(false)}
          onSubmit={handleCreateInformation}
          currentSession={selectedSession}
        />
      )}
    </div>
  );
};

export default InformationMaterial;
