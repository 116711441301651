import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button, { ButtonTypes } from '../../components/button';
import Dialog from '../../components/dialog';
import FileInput from '../../components/fileInput';
import Input from '../../components/input';
import { useTranslation } from 'react-i18next';
import InformationMediaResource from '../../models/informationMediaResource';

const ModalFileUpload = ({ onClose, onSubmit, currentSessionId }) => {
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);

  const isEmpty = !title || !file;

  const { t } = useTranslation();

  const handleUpload = () => {
    InformationMediaResource.upload(currentSessionId, file).then((response) => {
      onSubmit({
        title,
        filename: file.name,
        url: response.url,
      });
    });
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleFileChange = (newFile) => {
    setFile(newFile);
  };

  return (
    <Dialog
      noNav={true}
      title={t('sessions.informationMaterial.modals.uploadFile.title')}
      body={
        <>
          <Row>
            <Col>
              <Input
                required
                label={t(
                  'sessions.informationMaterial.modals.uploadFile.label.title'
                )}
                onChange={handleTitleChange}
                value={title}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FileInput
                label={t(
                  'sessions.informationMaterial.modals.uploadFile.label.file'
                )}
                onChange={handleFileChange}
                required
                file={file}
              />
            </Col>
          </Row>
        </>
      }
      footer={
        <>
          <Button
            text={t(
              'sessions.informationMaterial.modals.uploadFile.buttons.cancel'
            )}
            type={ButtonTypes.SECONDARY}
            onClick={onClose}
          />
          <Button
            disabled={isEmpty}
            text={t(
              'sessions.informationMaterial.modals.uploadFile.buttons.upload'
            )}
            type={ButtonTypes.PRIMARY}
            onClick={handleUpload}
          />
        </>
      }
      onClose={onClose}
    />
  );
};

ModalFileUpload.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

ModalFileUpload.defaultProps = {
  onClose: () => null,
  onSubmit: () => null,
};

export default ModalFileUpload;
