import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../../components/dialog';
import JurorGroup from '../../../../models/jurorGroup';
import GroupsForm from '../../../../modules/groupsForm';
import styles from './styles.module.scss';

const CreateGroup = () => {
  const navigate = useNavigate();

  const createSession = (formData, sessionId) => {
    JurorGroup.create(formData, sessionId).then((d) => {
      navigate('/admin/groups/overview', {
        state: {
          triggerReload: d.jurorGroupId,
        },
      });
    });
  };

  const handleCancel = () => {
    navigate('/admin/groups/overview');
  };

  return (
    <Dialog
      containerClass={styles.dialogContainer}
      xs={12}
      sm={12}
      md={11}
      lg={9}
      xl={8}
      xxl={7}
      title={
        <Trans i18nKey="jurors.create.title" components={{ bold: <b /> }} />
      }
      noNav
      body={
        <div className={styles.formWrapper}>
          <GroupsForm onSubmit={createSession} onCancel={handleCancel} />
        </div>
      }
      onClose={() => navigate('/admin/groups/overview')}
    />
  );
};

export default CreateGroup;
