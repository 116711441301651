class SubmissionGroup {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static from = (submissionGroup) => {
    return new SubmissionGroup(submissionGroup.id, submissionGroup.name);
  };

  static of = (submissionGroups) => {
    return submissionGroups?.map((submissionGroup) =>
      SubmissionGroup.from(submissionGroup)
    );
  };

  static fetchAll = async ({ assigned }) => {
    return fetch(`/if-api/jury-v2/submissionGroups/short?assigned=${assigned}`)
      .then((res) => res.json())
      .then((submissionGroups) => SubmissionGroup.of(submissionGroups))
      .catch((err) => console.error(err));
  };
}

export default SubmissionGroup;
