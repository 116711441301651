import PropTypes from 'prop-types';
import { useRef } from 'react';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import Input from '../input';
import styles from './styles.module.scss';

const FileInput = ({ file, label, onChange, required }) => {
  const fileInputRef = useRef();

  const handleChange = (e) => {
    onChange(e.target.files[0]);
  };

  return (
    <div className={styles.formGroup}>
      <Input
        className={styles.textInput}
        icon={faAngleDown}
        label={label}
        onClick={() => fileInputRef.current.click()}
        required={required}
        value={file?.name}
      />
      <input
        className={styles.fileInput}
        onChange={handleChange}
        ref={fileInputRef}
        type="file"
      />
    </div>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  file: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

FileInput.defaultProps = {
  label: '',
  file: null,
  required: false,
  onChange: null,
};

export default FileInput;
