import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import ThresholdCalcSubmissions from '../../models/thresholdCalculatorSubmissions';
import Button from '../button';
import AverageScoreColumn from '../entriesTable/averageScoreColumn';
import NameColumn from '../entriesTable/nameColumn';
import Input from '../input';
import Loading from '../loading';
import styles from './styles.module.scss';

const EvaluationEntries = ({
  loading,
  session,
  submissions,
  maxPoints,
  onEntryClick,
}) => {
  const { t } = useTranslation();
  const [filteredPoints, setFilteredPoints] = useState(0);
  const [filteredNumOfEntries, setFilteredNumOfEntries] = useState(0);
  const [filteredPercentage, setFilteredPercentage] = useState(0);
  const [filteredEntities, setFilteredEntities] = useState(submissions);
  const [waiting, setWaiting] = useState(false);
  const sortedSubmissions = useMemo(() => {
    return submissions
      .map((submission) => ({
        ...submission,
        averageScore: submission.numDecidedEvaluations
          ? submission.totalPointsFromAllDecidedEvaluations /
            submission.numDecidedEvaluations
          : 0,
      }))
      .sort((a, b) => a.averageScore - b.averageScore);
  }, [submissions]);

  const handlePointsChange = (value) => {
    const thresholdIndex = sortedSubmissions.findIndex(
      (submission) => submission.averageScore >= value
    );

    const slicedSubmissions =
      thresholdIndex === -1 ? [] : sortedSubmissions.slice(thresholdIndex);
    const numOfEntries = slicedSubmissions.length;
    const entriesPercentage =
      (slicedSubmissions.length / sortedSubmissions.length) * 100;
    setFilteredPoints(value);
    setFilteredNumOfEntries(numOfEntries);
    setFilteredPercentage(entriesPercentage.toFixed(2));
    setFilteredEntities(slicedSubmissions);
  };

  const handleAbsoluteNumChange = (value) => {
    if (value === '') {
      setFilteredPoints(1);
      setFilteredNumOfEntries(value);
      setFilteredPercentage(0);
      setFilteredEntities([]);
    }
    if (value < 1 || value > sortedSubmissions.length) return;
    const thresholdIndex = sortedSubmissions.length - value;

    const slicedSubmissions = sortedSubmissions.slice(thresholdIndex);
    const entriesPercentage =
      (slicedSubmissions.length / sortedSubmissions.length) * 100;

    const lastItemScore = slicedSubmissions[0].averageScore.toFixed();

    setFilteredPoints(lastItemScore);
    setFilteredNumOfEntries(value);
    setFilteredPercentage(entriesPercentage.toFixed(2));
    setFilteredEntities(slicedSubmissions);
  };

  const handlePercentageChange = (value) => {
    if (value === '') {
      setFilteredPoints(1);
      setFilteredNumOfEntries(0);
      setFilteredPercentage(value);
      setFilteredEntities([]);
    }
    if (value < 1 || value > 100) return;
    const absoluteNumOfEntries = (sortedSubmissions.length / 100) * value;
    const thresholdIndex = sortedSubmissions.length - absoluteNumOfEntries;

    const slicedSubmissions = sortedSubmissions.slice(thresholdIndex);

    const lastItemScore = slicedSubmissions[0].averageScore.toFixed();

    setFilteredPoints(lastItemScore);
    setFilteredNumOfEntries(Math.ceil(absoluteNumOfEntries));
    setFilteredPercentage(value);
    setFilteredEntities(slicedSubmissions);
  };

  useEffect(() => {
    if (maxPoints && sortedSubmissions.length > 0) {
      handlePointsChange((maxPoints / 2).toFixed());
    }
  }, [sortedSubmissions, maxPoints]);

  const handleSubmitClick = () => {
    setWaiting(true);
    ThresholdCalcSubmissions.setWinnerThreshold(
      session.sessionId,
      filteredPoints
    ).then((response) => {
      if(response.ok) {
        toast(
          `Success. Winners are submitted. Updating the index can take a few minutes`,
          {
            duration: 10000,
            icon: '✅',
          }
        );

      }
      setWaiting(false);
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (!session?.sessionId) {
    return <div>{t('sessions.threshold.noSession')}</div>;
  }

  return (
    <>
      <div className={styles.filter}>
        <span className={styles.title}>
          <Trans
            i18nKey="sessions.threshold.filterTitle"
            components={{ bold: <b /> }}
          />
        </span>
        <div className={styles.inputWrapper}>
          <Input
            label={t('sessions.threshold.entriesPercentage')}
            value={filteredPercentage}
            onChange={(changeEvent) =>
              handlePercentageChange(changeEvent.target.value)
            }
            max={100}
            min={1}
            type="number"
            className={styles.input}
          />
          <div className={styles.arrowRight}></div>
          <div className={styles.sliderCol}>
            <span className={styles.val}>{filteredPoints}</span>
            <input
              type="range"
              value={filteredPoints}
              min={1}
              max={maxPoints}
              onChange={(changeEvent) =>
                handlePointsChange(changeEvent.target.value)
              }
              className={styles.slider}
            />
          </div>
          <div className={styles.arrowRight}></div>
          <Input
            type="number"
            label={t('sessions.threshold.entriesNum')}
            onChange={(e) => handleAbsoluteNumChange(e.target.value)}
            value={filteredNumOfEntries}
            className={styles.input}
          />
        </div>
      </div>
      <div className={styles.resultWrapper}>
        <span className={styles.title}>
          {t('sessions.threshold.total', { amount: filteredEntities.length })}
        </span>
        <Button
          type="tertiary"
          text={t('sessions.threshold.submit')}
          onClick={handleSubmitClick}
          disabled={waiting || filteredEntities.length === 0}
        />
      </div>
      {filteredEntities?.slice(0, 100).map((submission) => (
        <div className={styles.entry} key={submission.submissionId}>
          <NameColumn
            submission={submission}
            onBookmark={() => handleBookmark(row)}
            onEntryClick={onEntryClick}
            readOnly
          />
          <AverageScoreColumn
            submission={submission}
            averageScore={submission?.averageScore}
            setScoreAlertModalData={null}
            readOnly
          />
        </div>
      ))}
    </>
  );
};

EvaluationEntries.propTypes = {
  loading: PropTypes.bool,
  session: PropTypes.object,
  submissions: PropTypes.array,
  onEntryClick: PropTypes.func,
};

EvaluationEntries.defaultProps = {
  loading: false,
  session: null,
  submissions: [],
  onEntryClick: () => {},
};

export default EvaluationEntries;
