import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styles from './styles.module.scss';
import { combine } from '../../helpers/styles';

ChartJS.register(ArcElement);

const options = {
  plugins: {
    datalabels: {
      display: false,
    },
  },
  events: [],
};

const PROGRESS_COLOR = '#009688';
const EMPTY_COLOR = '#efefef';

const ProgressCircle = ({ progress, subtitle }) => {
  const data = {
    labels: [],
    datasets: [
      {
        data: [progress || 0, 100 - (progress || 0)],
        backgroundColor: [PROGRESS_COLOR, EMPTY_COLOR],
        cutout: '83%',
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className={combine(styles.wrapper, subtitle && styles.withSubtitle)}>
      <Doughnut data={data} options={options} />
      <div className={styles.textWrapper}>
        <span className={styles.text}>{`${progress}%`}</span>
      </div>
      {subtitle && (
        <div className={styles.subtitleWrapper}>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      )}
    </div>
  );
};

ProgressCircle.propTypes = {
  progess: PropTypes.number,
  subtitle: PropTypes.string,
};

ProgressCircle.defaultProps = {
  progress: 0,
  subtitle: '',
};

export default ProgressCircle;
