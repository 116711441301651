import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '../button';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import styles from './styles.module.scss';
import MeetingDialog from '../../modules/meetingDialog';
import Dialog from '../dialog';
import Meeting from '../../models/meeting';

const RowActions = ({ meeting, setEditDialog, setDeleteDialog }) => {
  const handleEditClick = () => {
    setEditDialog(meeting);
  };

  const handleDeleteClick = () => {
    setDeleteDialog(meeting);
  };

  return (
    <div className={styles.actions}>
      <FontAwesomeIcon
        onClick={handleEditClick}
        className={styles.action}
        icon={faPen}
      />
      <FontAwesomeIcon
        onClick={handleDeleteClick}
        className={styles.action}
        icon={faTrash}
      />
    </div>
  );
};

const MeetingsTable = ({ meetings, setMeetings, onMeetingEdit, sessionId }) => {
  const [editDialogMeeting, setEditDialogMeeting] = useState(null);
  const [deleteDialogMeeting, setDeleteDialogMeeting] = useState(null);

  const { t } = useTranslation();

  const handleDelete = () => {
    Meeting.delete(sessionId, deleteDialogMeeting.id).then(() => {
      setMeetings((prev) =>
        prev.filter((meeting) => meeting.id !== deleteDialogMeeting.id)
      );
      setDeleteDialogMeeting(null);
    });
  };

  const handleEdit = (meeting) => {
    onMeetingEdit(meeting);
    setEditDialogMeeting(null);
  };

  const columns = [
    {
      dataField: 'startDate',
      text: t('sessions.meetings.table.headings.date'),
      sort: true,
      sortCaret: (order) => {
        return (
          <FontAwesomeIcon
            className={styles.sortIcon}
            icon={order === 'desc' ? faCaretDown : faCaretUp}
          />
        );
      },
      formatter: (cell) => {
        return `${moment.parseZone(cell).format('DD.MM.YYYY')} (CET)`;
      },
    },
    {
      dataField: 'time',
      text: t('sessions.meetings.table.headings.time'),
      formatter: (_, row) => {
        return `${moment.parseZone(row.startDate).format('HH:mm')} to ${moment
          .parseZone(row.endDate)
          .format('HH:mm')}`;
      },
    },
    {
      dataField: 'name',
      text: t('sessions.meetings.table.headings.title'),
      headerStyle: () => {
        return { width: '30%' };
      },
    },
    {
      dataField: 'jurorGroupName',
      text: 'Group',
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'mandatory',
      text: t('sessions.meetings.table.headings.mandatory'),
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return cell ? 'Yes' : 'No';
      },
    },
    {
      dataField: 'actions',
      text: '',
      headerAlign: 'right',
      classes: () => styles.actionsCell,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (_, row) => {
        return (
          <RowActions
            meeting={row}
            setEditDialog={setEditDialogMeeting}
            setDeleteDialog={setDeleteDialogMeeting}
          />
        );
      },
    },
  ];

  return (
    <>
      {editDialogMeeting && (
        <MeetingDialog
          meeting={editDialogMeeting}
          onClose={() => {
            setEditDialogMeeting(null);
          }}
          onSubmit={handleEdit}
          sessionId={sessionId}
        />
      )}
      {deleteDialogMeeting && (
        <Dialog
          noNav
          title={
            <p>
              Delete <b>{`${deleteDialogMeeting.name}`}</b>
            </p>
          }
          body={
            <p>{t('sessions.meetings.table.actions.delete.description')}</p>
          }
          footer={
            <div className={styles.dialogFooter}>
              <Button
                className={styles.button}
                type={ButtonTypes.SECONDARY}
                text={t(
                  'sessions.meetings.table.actions.delete.buttons.cancel'
                )}
                onClick={() => {
                  setDeleteDialogMeeting(null);
                }}
              />
              <Button
                type={ButtonTypes.PRIMARY}
                text={t(
                  'sessions.meetings.table.actions.delete.buttons.delete'
                )}
                onClick={handleDelete}
              />
            </div>
          }
          onClose={() => {
            setDeleteDialogMeeting(null);
          }}
        />
      )}
      <BootstrapTable
        keyField="id"
        data={meetings}
        columns={columns}
        wrapperClasses={styles.meetingsTable}
        noDataIndication={() => (
          <span className={styles.emptyMessage}>
            {t('sessions.meetings.table.noMeetings')}
          </span>
        )}
      />
    </>
  );
};

MeetingsTable.propTypes = {
  meetings: PropTypes.array,
  setMeetings: PropTypes.func,
  onMeetingEdit: PropTypes.func,
  sessionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MeetingsTable.defaultProps = {
  meetings: [],
  setMeetings: () => null,
  onMeetingEdit: () => null,
  sessionId: 0,
};

export default MeetingsTable;
