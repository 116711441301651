import SubmissionPreview from '../submissionPreview';

class Task {
  constructor(
    awardId,
    awardName,
    awardType,
    awardYear,
    jurorGroupId,
    jurorGroupName,
    jurorGroupPhase,
    finishedUntilDiscussionPhase,
    role,
    notDecidedEvaluationCount,
    totalEvaluationCount,
    submissionsPreview
  ) {
    this.awardId = awardId;
    this.awardName = awardName;
    this.awardType = awardType;
    this.awardYear = awardYear;
    this.jurorGroupId = jurorGroupId;
    this.jurorGroupName = jurorGroupName;
    this.jurorGroupPhase = jurorGroupPhase;
    this.finishedUntilDiscussionPhase = finishedUntilDiscussionPhase;
    this.role = role;
    this.notDecidedEvaluationCount = notDecidedEvaluationCount || 0;
    this.totalEvaluationCount = totalEvaluationCount || 0;
    this.submissionsPreview = submissionsPreview;
  }

  static from = (task) => {
    return new Task(
      task.awardId,
      task.awardName,
      task.awardType,
      task.awardYear,
      task.jurorGroupId,
      task.jurorGroupName,
      task.jurorGroupPhase,
      task.finishedUntilDiscussionPhase,
      task.role,
      task.notDecidedEvaluationCount,
      task.totalEvaluationCount,
      SubmissionPreview.of(task.submissionsPreview)
    );
  };

  static fetch = async (jurorGroupId) => {
    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/tasks`)
      .then((response) => response.json())
      .then((task) => Task.from(task))
      .catch((err) => console.error(err));
  };
}

export default Task;
