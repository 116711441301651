import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEmptySet,
  faHand,
  faTriangleExclamation,
  faMessageCode,
  faMedal,
  faCheckCircle,
  faStar,
} from '@fortawesome/pro-light-svg-icons';
import { combine } from '../../../../helpers/styles';
import ProgressCircle from '../../../progressCircle';
import styles from './styles.module.scss';
import { ROLES } from '../../../../config/permissions/roles';
import {
  getAverageScore,
  getProgressPrecentage,
} from '../../../../helpers/statistic';
import { GROUP_ROLE, AWARD_ROLE } from '../../../../models/evaluationStatistic';
import { AWARD_TYPES } from '../../../../models/award';
import { useJuror } from '../../../../hooks/useAuth';
import { isAwardType } from '../../../../helpers/awardTypes';
import { SESSION_TYPES } from '../../../../models/session';
import iFGoldAwardLogo from '../../../../assets/images/iF-Gold.svg';

const MainStatistic = ({ isLoading, statistic, filter, bigStats }) => {
  const { t } = useTranslation();
  const { session } = useJuror();

  const mappedStatistics = useMemo(() => {
    if (!filter || !statistic) return [];

    if (
      filter === 'group' &&
      !isAwardType(AWARD_TYPES.IF_SIP, session) &&
      !isAwardType(AWARD_TYPES.IF_DTA, session)
    ) {
      const chairpersonStat = statistic.jurors.find(
        (statisticItem) => statisticItem.role === ROLES.chairPerson
      );
      const groupStat = statistic.jurors.find(
        (statisticItem) => statisticItem.role === GROUP_ROLE
      );
      const awardStat = statistic.jurors.find(
        (statisticItem) => statisticItem.role === AWARD_ROLE
      );

      const stats = [
        {
          icon: faHand,
          label: t('jurorDashboard.statistic.group.decideByChairperson'),
          type: 'toBeDecidedByChairperson',
          value: chairpersonStat.totalEvaluationCount,
        },
        {
          type: 'progressPercentage',
          value: getProgressPrecentage(
            groupStat.decidedEvaluationCount,
            groupStat.totalEvaluationCount
          ),
          awardValue: bigStats
            ? getProgressPrecentage(
                awardStat.decidedEvaluationCount,
                awardStat.totalEvaluationCount
              )
            : 0,
        },
        {
          icon: faEmptySet,
          label: t('jurorDashboard.statistic.group.averageScore'),
          type: 'averageScore',
          value: getAverageScore(
            groupStat.decidedEvaluationCount,
            groupStat.awardedPoints
          ),
          awardValue: bigStats
            ? getAverageScore(
                awardStat.decidedEvaluationCount,
                awardStat.awardedPoints
              )
            : 0,
        },
        {
          icon: faTriangleExclamation,
          label: t('jurorDashboard.statistic.group.scoreAlerts'),
          type: 'scoreAlerts',
          value: statistic.numScoreAlerts,
        },
        {
          icon: faMessageCode,
          label: t('jurorDashboard.statistic.group.comments'),
          type: 'numComments',
          value: statistic.numEntriesWithNotDiscussedComment,
        },
      ];

      if (isAwardType(AWARD_TYPES.IF_DA, session)) {
        if (session?.sessionType === SESSION_TYPES.PRESELECTION) {
          stats.push({
            icon: faStar,
            label: t('jurorDashboard.statistic.group.nominated'),
            type: 'suggestedForNomination',
            value: statistic.numSubmissionsWithSuggestedForNomination,
          });
        }

        if (session?.sessionType === SESSION_TYPES.FINAL_JURY) {
          stats.push({
            img: iFGoldAwardLogo,
            label: t('jurorDashboard.statistic.group.goldDecided'),
            type: 'nominated',
            value: statistic.numSubmissionsWithNomination,
          });
        }
      } else {
        stats.push({
          icon: faMedal,
          label: t('jurorDashboard.statistic.group.specialAwards'),
          type: 'suggestedForNomination',
          value: statistic.numSubmissionsWithSuggestedForNomination,
        });
      }

      return stats;
    } else {
      const stat = statistic.jurors.find(
        (jurorStatistic) => jurorStatistic.jurorId === filter
      );

      if (!stat) return [];

      return [
        {
          icon: faCheckCircle,
          label: t('jurorDashboard.statistic.main.entries.label'),
          type: 'decided',
          value: t('jurorDashboard.statistic.main.entries.value', {
            numDecided: stat?.decidedEvaluationCount,
            total: stat?.totalEvaluationCount,
          }),
        },
        {
          label: t('jurorDashboard.statistic.main.progress.label'),
          type: 'progressPercentage',
          value: getProgressPrecentage(
            stat.decidedEvaluationCount,
            stat.totalEvaluationCount
          ),
        },
        {
          icon: faEmptySet,
          label: t('jurorDashboard.statistic.main.score.label'),
          type: 'averageScore',
          value: getAverageScore(
            stat.decidedEvaluationCount,
            stat.awardedPoints
          ),
        },
      ];
    }
  }, [filter, statistic, session]);

  return (
    <div
      className={combine(
        styles.statisticContainer,
        session?.sessionType === SESSION_TYPES.FINAL_JURY && styles.finalJury,
        mappedStatistics.length > 3 && styles.doubleRows,
        bigStats && styles.bigStats
      )}
    >
      {mappedStatistics.map((stat) => (
        <div
          key={stat.type}
          className={combine(styles.column, 'placeholder-glow')}
        >
          {isLoading ? (
            <span className={combine(styles.placeholder, 'placeholder')}></span>
          ) : (
            <>
              {stat.type === 'progressPercentage' ? (
                <>
                  <div className={styles.valueContainer}>
                    <ProgressCircle progress={stat.value} />
                    <span className={styles.label}>
                      {t('jurorDashboard.statistic.main.progress.label')}
                    </span>
                  </div>
                  {bigStats && (
                    <div className={styles.awardAverage}>
                      <span>
                        {session.awardName}: <b>{stat.awardValue}%</b>
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={styles.valueContainer}>
                    {stat.icon ? (
                      <FontAwesomeIcon
                        className={styles.icon}
                        icon={stat.icon}
                        size="2x"
                      />
                    ) : (
                      <img src={iFGoldAwardLogo} className={styles.icon} />
                    )}
                    <span className={styles.value}>{stat.value}</span>
                    <span className={styles.label}>{stat.label}</span>
                  </div>
                  {bigStats && stat.type === 'averageScore' && (
                    <div className={styles.awardAverage}>
                      <span>
                        {session.awardName}: <b>{stat.awardValue}</b>
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

MainStatistic.propTypes = {
  isLoading: PropTypes.bool,
  statistic: PropTypes.shape({
    numScoreAlerts: PropTypes.number,
    numSubmissionsWithSuggestedForNomination: PropTypes.number,
    numEvaluationsWithCount: PropTypes.number,
    jurors: PropTypes.array,
  }),
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MainStatistic.defaultProps = {
  isLoading: false,
  statistic: null,
  filter: '',
};

export default MainStatistic;
