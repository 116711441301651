import PropTypes from 'prop-types';
import Dialog from '../../components/dialog';
import MeetingDetailForm from './meetingDetailForm';
import styles from './styles.module.scss';

const MeetingDialog = ({ meeting, sessionId, onClose, onSubmit }) => {
  return (
    <Dialog
      xxl={6}
      xl={7}
      lg={9}
      md={10}
      sm={11}
      xs={12}
      body={
        <div className={styles.formWrapper}>
          <MeetingDetailForm
            onCancel={onClose}
            onSubmit={onSubmit}
            meeting={meeting}
            sessionId={sessionId}
          />
        </div>
      }
      onClose={onClose}
      noHeader
      noNav
    />
  );
};

MeetingDialog.propTypes = {
  meeting: PropTypes.object,
  sessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

MeetingDialog.defaultProps = {
  meeting: null,
  sessionId: '',
  onClose: () => null,
  onSubmit: () => null,
};

export default MeetingDialog;
