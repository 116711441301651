import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import JurorGroup, { JUROR_GROUP_PHASES } from '../../models/jurorGroup';
import GroupsForm from '../../modules/groupsForm';
import Dialog from '../dialog';
import Loading from '../loading';
import { ROLES } from '../../config/permissions/roles';
import Dropdown from '../dropdown';
import styles from './styles.module.scss';

const groupPhaseOptions = Object.values(JUROR_GROUP_PHASES).map((phase) => ({
  id: phase,
  text: phase,
}));

const rowActions = (group, setEditDialog, handleModeChange, t) => {
  const onModeChange = (mode) => {
    handleModeChange(group, mode);
  };

  const edit = () => {
    setEditDialog(group);
  };

  return (
    <div className={styles.actionCell}>
      <Dropdown
        name="jurorGroupPhase"
        label={t('jurors.overview.table.groupPhase')}
        options={groupPhaseOptions}
        value={group?.jurorGroupPhase}
        onChange={onModeChange}
      />
      <FontAwesomeIcon
        onClick={edit}
        disabled
        className={styles.action}
        icon={faPen}
      />
    </div>
  );
};

const groupFormatter = (cell, row, t) => (
  <div className={styles.groupCell}>
    <div>
      <img
        src={
          row.primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup ||
          row.primaryMediaUrlOfLowestSubmissionIdInGroup
        }
        alt=""
        loading="lazy"
        className={styles.groupImage}
      />
    </div>
    <div className="d-flex flex-column">
      <div className="d-flex flex-column">
        <span className={styles.label}>
          {t('jurors.overview.table.packageName')}:
        </span>
        <span className={styles.bigValue}>{row?.jurorGroupName}</span>
      </div>
      <div className="d-flex flex-column mt-4">
        <span className={styles.label}>
          {t('jurors.overview.table.entries')}:
        </span>
        <span>
          <b>{row?.submissionCount}</b>
        </span>
      </div>
    </div>
  </div>
);

const jurorLink = (_, row, handleCookies) => {
  return (
    <div className="d-flex flex-column">
      {row?.jurors?.map((juror) => (
        <Link
          className={styles.multiValuedCell}
          key={juror.uuid}
          to={`/juror/${juror.uuid}`}
          onClick={handleCookies}
        >
          {`${juror.firstname} ${juror.lastname.toUpperCase()}`}
        </Link>
      ))}
    </div>
  );
};

const jurorRole = (_, row) => {
  const transformRoleToInitials = (role) => {
    switch (role) {
      case ROLES.juror:
        return 'J';
      case ROLES.groupLeader:
        return 'GL';
      case ROLES.chairPerson:
        return 'CP';
      case ROLES.staff:
        return 'IF';
      default:
        return '';
    }
  };
  return (
    <div className="d-flex flex-column">
      {row?.jurors?.map((juror) => (
        <span className={styles.multiValuedCell} key={juror.uuid}>
          {transformRoleToInitials(juror.role)}
        </span>
      ))}
    </div>
  );
};

const jurorPoints = (_, row) => {
  return (
    <div className="d-flex flex-column">
      {row?.jurors?.map((juror) => (
        <span className={styles.multiValuedCell} key={juror.uuid}>
          {juror.decidedEvaluationCount
            ? Math.floor(
                juror.totalPointsFromAllDecidedEvaluations /
                  juror.decidedEvaluationCount
              )
            : 0}
        </span>
      ))}
    </div>
  );
};

const meetingsFormatter = (_, row, t) => {
  return (
    <span className={styles.meetingsCell}>
      <span className={styles.label}>Planned</span>
      <span className={styles.bigValue}>
        {row.numMandatoryMeetings + row.numOptionalMeetings}
      </span>
    </span>
  );
};

const GroupsTable = ({ groups, loading, sessionId, fetchJurorGroups }) => {
  const [jurorGroups, setJurorGroups] = useState([]);
  const [editDialog, setEditDialog] = useState(null);
  const { t } = useTranslation();
  const { setSessionCookie } = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(['JSESSIONID']);

  useEffect(() => {
    setJurorGroups(groups);
  }, [groups]);

  const handleEdit = async (values) => {
    await JurorGroup.update(values, editDialog.jurorGroupId);
    fetchJurorGroups();
    setEditDialog(null);
  };

  const handleModeChange = async (jurorGroup, mode) => {
    if (mode === JUROR_GROUP_PHASES.editPhase) {
      await JurorGroup.setEditPhase(jurorGroup?.jurorGroupId);
    }

    if (mode === JUROR_GROUP_PHASES.discussionPhase) {
      await JurorGroup.setDiscussionPhase(jurorGroup?.jurorGroupId);
    }

    if (mode === JUROR_GROUP_PHASES.completed) {
      await JurorGroup.setCompleted(jurorGroup?.jurorGroupId);
    }

    fetchJurorGroups();
  };

  const handleCookies = () => {
    setSessionCookie(cookies['JSESSIONID']);
    removeCookie('JSESSIONID', { path: '/' });
    setCookie('isJurorPreview', true, { path: '/' });
  };

  const columns = [
    {
      dataField: 'name',
      text: t('jurors.overview.table.headers.groups'),
      headerStyle: () => {
        return { width: '45%' };
      },
      formatter: (cell, row) => groupFormatter(cell, row, t),
    },
    {
      dataField: 'meetings',
      text: t('jurors.overview.table.headers.events'),
      headerStyle: () => {
        return { width: '5%' };
      },
      classes: '',
      formatter: (_, row) => meetingsFormatter(_, row, t),
    },
    {
      dataField: 'jurorLink',
      isDummyField: true,
      text: t('jurors.overview.table.headers.jury'),
      classes: () => styles.multiValuedCell,
      headerStyle: () => {
        return { width: '20%' };
      },
      formatter: (_, row) => jurorLink(_, row, handleCookies),
    },
    {
      dataField: 'jurorRole',
      isDummyField: true,
      text: t('jurors.overview.table.headers.role'),
      classes: () => styles.multiValuedCell,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_, row) => jurorRole(_, row),
    },
    {
      dataField: 'jurorPoints',
      isDummyField: true,
      text: t('jurors.overview.table.headers.score'),
      classes: () => styles.multiValuedCell,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_, row) => jurorPoints(_, row),
      headerFormatter: (column) => {
        return (
          <span>
            <FontAwesomeIcon icon={faEmptySet} className={styles.averageIcon} />
            {column.text}
          </span>
        );
      },
    },
    {
      dataField: 'actions',
      headerAlign: 'right',
      text: '',
      classes: () => styles.actions,
      headerStyle: () => {
        return { width: '20%' };
      },
      formatter: (cell, row, rowIndex, formatExtraData) =>
        rowActions(row, setEditDialog, handleModeChange, t),
    },
  ];

  return (
    <>
      {editDialog && (
        <Dialog
          containerClass={styles.dialogContainer}
          xs={12}
          sm={12}
          md={11}
          lg={9}
          xl={8}
          xxl={7}
          title={
            <Trans
              i18nKey="jurors.edit.title"
              components={{ bold: <b /> }}
              values={{
                groupName: editDialog.jurorGroupName,
              }}
            />
          }
          noNav
          body={
            <div className={styles.formWrapper}>
              <GroupsForm
                group={editDialog}
                sessionId={sessionId}
                type="edit"
                onSubmit={handleEdit}
                onCancel={() => setEditDialog(null)}
              />
            </div>
          }
          onClose={() => setEditDialog(null)}
        />
      )}
      <BootstrapTable
        keyField="id"
        data={jurorGroups}
        columns={columns}
        wrapperClasses={styles.groupsTable}
        noDataIndication={() => (
          <>
            {loading ? (
              <Loading />
            ) : (
              <span className={styles.emptyMessage}>
                {t('jurors.overview.table.noJurorGroups')}
              </span>
            )}
          </>
        )}
      />
    </>
  );
};

GroupsTable.propTypes = {
  groups: PropTypes.array,
  loading: PropTypes.bool,
  sessionId: PropTypes.number,
  fetchJurorGroups: PropTypes.func,
};

GroupsTable.defaultProps = {
  groups: [],
  loading: false,
  sessionId: 0,
  fetchJurorGroups: () => null,
};

export default GroupsTable;
