import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

const SortDropdown = ({ activeOptions, options, onChange }) => (
  <div className={styles.dropdown}>
    <Dropdown align="end" className={styles.sortDropdown} onSelect={onChange}>
      <span className={styles.label}>Filter</span>
      <Dropdown.Toggle className={styles.button}>
        <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownContainer}>
        {options.map((item) => {
          if (item.childs) {
            return (
              <div
                key={item.key}
                className={combine(
                  styles.item,
                  activeOptions.some((option) => option.includes(item.key)) &&
                    styles.active,
                  item?.disabled && styles.disabled
                )}
                role="button"
              >
                <div className={styles.itemContent}>
                  <span className={styles.text}>{item.text}</span>

                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faChevronRight}
                  />

                  <div className={styles.childDropdownContainer}>
                    {item.childs.map((child) => (
                      <Dropdown.Item
                        key={child.text}
                        eventKey={`${item.key}__${child.key}`}
                        className={styles.item}
                        active={activeOptions.includes(
                          `${item.key}__${child.key}`
                        )}
                      >
                        {child.text}
                      </Dropdown.Item>
                    ))}
                  </div>
                </div>
              </div>
            );
          }
          return (
            <Dropdown.Item
              key={item.key}
              eventKey={item.key}
              className={styles.item}
              active={activeOptions.includes(item.key)}
            >
              <div className={styles.itemContent}>
                <span className={styles.text}>{item.text}</span>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

SortDropdown.propTypes = {
  activeOptions: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

SortDropdown.defaultProps = {
  activeOptions: [],
  options: [],
  onChange: () => null,
};

export default SortDropdown;
