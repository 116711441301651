import PropTypes from 'prop-types';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

const Dropdown = ({
  label,
  required,
  options,
  className,
  value,
  onChange,
  onRawChange,
  name,
  readOnly,
  whiteBackground,
}) => {
  const [visited, setVisited] = React.useState(false);

  const handleChange = (e) => {
    onRawChange(e);
    onChange(e.target.value);
  };

  const EMPTY_OPTION = {
    value: '',
    text: '',
  };

  return (
    <div className={combine(styles.formGroup, className)}>
      <Form.Select
        name={name}
        onChange={handleChange}
        className={combine(
          styles.formControl,
          visited && styles.visited,
          whiteBackground && styles.whiteBackground
        )}
        required={required}
        onBlur={() => setVisited(true)}
        aria-label={label}
        value={value}
        disabled={readOnly}
      >
        {[EMPTY_OPTION, ...options].map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </Form.Select>

      <label className={styles.label} htmlFor="Dropdown">
        {required ? `* ${label}` : label}
      </label>
    </div>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onRawChange: PropTypes.func,
  readOnly: PropTypes.bool,
  whiteBackground: PropTypes.bool,
};

Dropdown.defaultProps = {
  name: '',
  label: '',
  type: 'text',
  options: [],
  value: '',
  required: false,
  onChange: () => null,
  onRawChange: () => null,
  readOnly: false,
  whiteBackground: false,
};

export default Dropdown;
