import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AUTH_STATES, useJuror } from '../../../hooks/useAuth';
import Loading from '../../../components/loading';
import styles from './styles.module.scss';

const Login = () => {
  const navigate = useNavigate();
  const { isLoading, authState } = useJuror();
  const { t } = useTranslation();

  useEffect(() => {
    if (authState === AUTH_STATES.INIT) {
      navigate('/juror');
    }
  }, [authState]);

  return (
    <div className={styles.logout}>
      {isLoading && <Loading />}
      {!isLoading && authState === AUTH_STATES.NOT_AUTHENTICATED && (
        <h2 className={styles.message}>{t('jurorLogout.success')}</h2>
      )}
    </div>
  );
};

export default Login;
