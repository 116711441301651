import pt from 'prop-types';

const IFDTABadge = ({ year, className }) => {
  return (
    <svg
      viewBox="0 0 398.47 141.7"
      className={className}
      dangerouslySetInnerHTML={{
        __html: `
          <<?xml version="1.0" encoding="utf-8"?>
          <!-- Generator: Adobe Illustrator 25.4.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
          <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 398.47 141.73" style="enable-background:new 0 0 398.47 141.73;" xml:space="preserve">
          <style type="text/css">
            .st0{fill:#FFFFFF;}
            .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#EC1D26;}
            .text{font-size:19.8px;font-family:SuisseIntl;font-weight:400;fill:#FFFFFF;stroke:#FFFFFF}
          </style>
          <rect x="-0.17" class="st0" width="398.02" height="141.73"/>
          <rect x="6.57" y="6.75" class="st1" width="128.23" height="128.24"/>
          <g>
            <path class="st0" d="M33.61,107.99h20.25V60.74H33.61V107.99z M43.69,32.4c-6.34,0-11.47,5.14-11.47,11.47
              c0,6.34,5.14,11.47,11.47,11.47c6.34,0,11.47-5.14,11.47-11.47C55.17,37.53,50.03,32.4,43.69,32.4z M87.57,33.74v20.25h20.25V33.74
              H87.57z M60.57,107.99h20.25V33.74H60.57V107.99z M87.57,80.99h20.25V60.74H87.57V80.99z"/>
          </g>
          <rect x="141.56" y="6.75" class="st1" width="248.31" height="128.24"/>
          <g>
            <path class="st0" d="M164.38,33.9c6.14,0,10.71,2.58,10.71,9.66c0,6.69-4.71,9.49-10.71,9.49h-4.97V33.9H164.38z M163.07,50.11
              h0.96c4.03,0,7.25-1.67,7.25-6.58c0-5.16-3.22-6.61-7.25-6.61h-0.96V50.11z"/>
            <path class="st0" d="M178.52,33.9h10.74v3.18h-7.12v4.61h6.55v3.1h-6.55v5.05h7.2v3.21h-10.82V33.9z"/>
            <path class="st0" d="M202.75,37.38c-1.43-0.6-2.57-0.82-3.69-0.82c-1.92,0-3.15,0.85-3.15,2.17c0,1.18,0.6,1.92,3.28,2.94
              c3.38,1.26,4.81,2.91,4.81,5.73c0,3.68-2.65,5.98-6.84,5.98c-1.74,0-3.3-0.27-4.81-0.82l0.34-3.37c1.56,0.8,2.99,1.1,4.26,1.1
              c1.92,0,3.3-0.85,3.3-2.39c0-1.45-0.75-2.22-3.51-3.29c-3.22-1.23-4.6-2.77-4.6-5.49c0-3.4,2.63-5.54,6.5-5.54
              c1.61,0,2.96,0.19,4.39,0.69L202.75,37.38z"/>
            <path class="st0" d="M207.33,33.9h3.67v19.15h-3.67V33.9z"/>
            <path class="st0" d="M229.48,37.87c-1.12-0.6-2.94-1.07-4.81-1.07c-3.85,0-6.34,2.55-6.34,6.61c0,3.98,2.26,6.64,6.27,6.64
              c0.75,0,1.56-0.08,2.13-0.27v-4.77h-3.87v-3.07h7.36v10.29c-1.95,0.71-4.03,1.02-6.11,1.02c-5.56,0-9.7-3.73-9.7-9.85
              c0-5.95,4-9.68,10.3-9.68c1.79,0,3.56,0.19,5.04,0.74L229.48,37.87z"/>
            <path class="st0" d="M246.17,33.9h3.3v19.15h-4.63l-4.65-9.38c-0.94-1.89-2.13-4.64-2.63-5.9h-0.05c0.08,1.18,0.13,4.69,0.13,6.34
              v8.94h-3.3V33.9h4.89l4.58,9.46c0.68,1.37,1.92,4.25,2.47,5.6h0.05c-0.08-1.4-0.16-5.1-0.16-7.02V33.9z"/>
          </g>
          <g>
            <path class="st0" d="M169.13,65.22c-1.43-0.6-2.57-0.82-3.69-0.82c-1.92,0-3.15,0.85-3.15,2.17c0,1.18,0.6,1.92,3.28,2.94
              c3.38,1.26,4.81,2.91,4.81,5.73c0,3.68-2.65,5.98-6.84,5.98c-1.74,0-3.3-0.27-4.81-0.82l0.34-3.37c1.56,0.79,2.99,1.1,4.26,1.1
              c1.92,0,3.3-0.85,3.3-2.39c0-1.45-0.75-2.22-3.51-3.29c-3.22-1.23-4.6-2.77-4.6-5.49c0-3.4,2.63-5.54,6.5-5.54
              c1.61,0,2.96,0.19,4.39,0.69L169.13,65.22z"/>
            <path class="st0" d="M185.77,64.98l-5.07-0.08v15.99h-3.72V64.89l-4.97,0.08v-3.24h13.75V64.98z"/>
            <path class="st0" d="M192.06,61.74v11.27c0,3.57,1.17,5.1,3.67,5.1c2.7,0,3.9-1.54,3.9-5.1V61.74h3.51v11.55
              c0,5.24-2.55,7.93-7.44,7.93c-5.2,0-7.33-2.69-7.33-7.93V61.74H192.06z"/>
            <path class="st0" d="M212.4,61.74c6.14,0,10.71,2.58,10.71,9.66c0,6.69-4.71,9.49-10.71,9.49h-4.97V61.74H212.4z M211.1,77.95h0.96
              c4.03,0,7.25-1.67,7.25-6.58c0-5.16-3.22-6.61-7.25-6.61h-0.96V77.95z"/>
            <path class="st0" d="M226.54,61.74h10.74v3.18h-7.12v4.61h6.55v3.1h-6.55v5.05h7.2v3.21h-10.82V61.74z"/>
            <path class="st0" d="M252.98,61.74h3.3v19.15h-4.63L247,71.51c-0.94-1.89-2.13-4.64-2.63-5.9h-0.05c0.08,1.18,0.13,4.69,0.13,6.34
              v8.94h-3.3V61.74h4.89l4.58,9.46c0.68,1.37,1.92,4.25,2.47,5.6h0.05c-0.08-1.4-0.16-5.1-0.16-7.02V61.74z"/>
            <path class="st0" d="M272.72,64.98l-5.07-0.08v15.99h-3.72V64.89l-4.97,0.08v-3.24h13.75V64.98z"/>
            <path class="st0" d="M298.33,80.89h-3.98l-1.59-4.42h-7.57l-1.59,4.42h-3.77l7.12-19.15h4.13L298.33,80.89z M290.27,69.09
              c-0.39-1.15-1.01-3.35-1.25-4.33h-0.05c-0.26,0.99-0.94,3.18-1.33,4.33l-1.51,4.47h5.64L290.27,69.09z"/>
            <path class="st0" d="M311.64,68.08c-0.18-0.88-0.55-2.55-0.68-3.59h-0.05c-0.13,1.04-0.42,2.69-0.6,3.59l-2.7,12.81h-4.63
              l-5.02-19.15h3.93l2.63,11.47c0.13,0.63,0.7,3.4,0.86,4.69h0.05c0.18-1.29,0.7-4.06,0.83-4.69l2.37-11.47h4.86l2.5,11.47
              c0.16,0.66,0.65,3.43,0.83,4.69h0.05c0.18-1.26,0.7-3.98,0.86-4.69l2.6-11.47h3.64l-4.86,19.15h-4.6L311.64,68.08z"/>
            <path class="st0" d="M342.19,80.89h-3.98l-1.59-4.42h-7.57l-1.59,4.42h-3.77l7.12-19.15h4.13L342.19,80.89z M334.13,69.09
              c-0.39-1.15-1.01-3.35-1.25-4.33h-0.05c-0.26,0.99-0.94,3.18-1.33,4.33l-1.51,4.47h5.64L334.13,69.09z"/>
            <path class="st0" d="M344.76,61.74h5.25c4.47,0,7.44,1.01,7.44,5.02c0,2.52-1.43,4.03-3.8,4.53v0.05c1.27,0.36,1.74,1.12,2.31,2.55
              l2.81,7h-4.03l-2.03-5.43c-0.75-2-1.09-2.52-3.33-2.52h-0.96v7.96h-3.67V61.74z M348.43,69.97h1.07c2.89,0,4.19-0.77,4.19-2.69
              c0-2.08-1.56-2.52-4.19-2.52h-1.07V69.97z"/>
            <path class="st0" d="M366.16,61.74c6.14,0,10.71,2.58,10.71,9.66c0,6.69-4.71,9.49-10.71,9.49h-4.97V61.74H366.16z M364.86,77.95
              h0.96c4.03,0,7.25-1.67,7.25-6.58c0-5.16-3.22-6.61-7.25-6.61h-0.96V77.95z"/>
          </g>
          <g>
            <text class="text" transform="translate(159 104.2)">${year}</text>
          </g>
          </svg>
          
        `,
      }}
    />
  );
};

IFDTABadge.propTypes = {
  year: pt.number,
  className: pt.string,
};

IFDTABadge.defaultProps = {
  className: '',
  year: 2023,
};

export default IFDTABadge;
