import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '../../button';
import MeetingsList from '../../meetingsList';
import Dialog from '../../dialog';
import Meeting from '../../../models/meeting';
import styles from './styles.module.scss';
import { useJuror } from '../../../hooks/useAuth';
import moment from 'moment';

const NUM_DISPLAYED_MEETINGS = 3;

const MeetingsWidget = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [meetings, setMeetings] = useState([]);

  const { t } = useTranslation();
  const { jurorGroup } = useJuror();

  useEffect(() => {
    if (jurorGroup) {
      setIsLoading(true);
      Meeting.fetchAllForJurorGroup(jurorGroup.jurorGroupId).then(
        (meetingsData) => {
          setMeetings(meetingsData);
          setIsLoading(false);
        }
      );
    }
  }, [setIsLoading, setMeetings, jurorGroup]);

  const widgetMeetings = useMemo(() => {
    if (isLoading) {
      return new Array(3).fill(null);
    }
    let filteredMeetings = meetings
      ?.filter((meeting) =>
        moment(meeting.startDate).isAfter(moment().startOf('day'))
      )
      .slice(0, NUM_DISPLAYED_MEETINGS);
    if (filteredMeetings.length === 1) {
      filteredMeetings = filteredMeetings.concat([null, null]);
    }
    if (filteredMeetings.length === 2) {
      filteredMeetings = filteredMeetings.concat([null]);
    }
    return filteredMeetings;
  }, [isLoading, meetings]);

  const showAllButton =
    !isLoading &&
    (meetings.length > NUM_DISPLAYED_MEETINGS ||
      meetings?.some((meeting) =>
        moment(meeting.startDate).isBefore(moment().startOf('day'))
      ));

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.meetingsContainer}>
      <MeetingsList meetings={widgetMeetings} isLoading={isLoading} />
      {showAllButton && (
        <div className={styles.buttonContainer} onClick={handleButtonClick}>
          <Button
            className={styles.button}
            text={t('jurorDashboard.meetings.allEvents')}
            type={ButtonTypes.SECONDARY}
          />
        </div>
      )}
      {showModal && (
        <Dialog
          title={t('jurorDashboard.meetings.modal.title')}
          onClose={handleModalClose}
          body={
            <div className={styles.dialogListContainer}>
              <MeetingsList
                meetings={meetings}
                className={styles.meetingsList}
              />
            </div>
          }
          footer={
            <div className={styles.dialogFooterContainer}>
              <Button
                text={t('jurorDashboard.meetings.modal.close')}
                type={ButtonTypes.SECONDARY}
                onClick={handleModalClose}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default MeetingsWidget;
