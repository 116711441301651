import { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../context/config.context';
import DEFAULT_CONFIG from '../config/renderConfig';
import { AWARD_TYPES } from '../models/award';
import { useJuror } from './useAuth';

export const useConfig = () => {
  return useContext(ConfigContext);
};

const FALLBACK_CONFIG = DEFAULT_CONFIG[AWARD_TYPES.IF_DA];

export const ConfigProvider = ({ children }) => {
  const [sessionConfig, setSessionConfig] = useState(FALLBACK_CONFIG);
  const { session } = useJuror();

  useEffect(() => {
    setSessionConfig(DEFAULT_CONFIG[session?.awardType] || FALLBACK_CONFIG);
  }, [session, setSessionConfig]);

  return (
    <ConfigContext.Provider value={sessionConfig}>
      {children}
    </ConfigContext.Provider>
  );
};
