import * as React from 'react';
import { Container } from 'react-bootstrap';
import Hero from '../../../modules/hero';

const Dashboard = () => {
  return (
    <>
      <Hero title={'Dashboard'} />
      <Container></Container>
    </>
  );
};

export default Dashboard;
