import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import {
  faCircle as faCircleLight,
  faCircleCheck as faCircleCheckLight,
  faShieldExclamation as faShieldExclamationLight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ROLES } from '../../../config/permissions/roles';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import { JUROR_GROUP_PHASES } from '../../../models/jurorGroup';
import { EVALUATION_STATUS } from '../../../models/submission';
import { matchRoles } from '../../../helpers/roles';
import styles from './styles.module.scss';

const StatusColumn = ({ submission, isAdminView, onNameClick, className }) => {
  const [score, setScore] = useState(0);
  const { role, juror, jurorGroup } = useJuror();
  const currentJurorEvaluation = submission.evaluations.find(
    (evaluation) => evaluation.jurorId === juror?.jurorId
  );

  useEffect(() => {
    const total = submission.evaluations
      .map((score) => score.totalPoints)
      .reduce((acc, curr) => acc + curr, 0);
    setScore(total);
  }, [submission]);

  const renderIcon = (score, status, hasZeroValues) => {
    switch (true) {
      case status === EVALUATION_STATUS.toBeDecidedByChairperson:
        return (
          <FontAwesomeIcon
            icon={faShieldExclamationLight}
            className={combine(styles.icon, styles.red)}
          />
        );
      case status === EVALUATION_STATUS.decidedByChairperson:
        return (
          <FontAwesomeIcon
            icon={faShieldExclamationLight}
            className={combine(styles.icon, styles.green)}
          />
        );
      case isNaN(score) || status === EVALUATION_STATUS.toBeDecided:
        return (
          <FontAwesomeIcon
            icon={faCircle}
            className={combine(styles.icon, styles.red)}
          />
        );
      case score >= 0:
        return (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className={combine(
              styles.icon,
              styles.green,
              role === ROLES.staff && hasZeroValues && styles.red
            )}
          />
        );
      default:
        return null;
    }
  };

  const renderJurorIcon = (score, status) => {
    switch (true) {
      case status === EVALUATION_STATUS.toBeDecidedByChairperson:
        return (
          <div className={combine(styles.iconContainer, styles.grey)}>
            <FontAwesomeIcon
              icon={faShieldExclamationLight}
              className={styles.icon}
            />
          </div>
        );
      case status === EVALUATION_STATUS.decidedByChairperson:
        return (
          <div className={combine(styles.iconContainer, styles.petrol)}>
            <FontAwesomeIcon
              icon={faShieldExclamationLight}
              className={styles.icon}
            />
          </div>
        );
      case status === EVALUATION_STATUS.toBeDecided:
        return (
          <div className={combine(styles.iconContainer, styles.blue)}>
            <FontAwesomeIcon icon={faCircleLight} className={styles.icon} />
          </div>
        );
      case score >= 0:
        return (
          <div className={combine(styles.iconContainer, styles.petrol)}>
            <FontAwesomeIcon
              icon={faCircleCheckLight}
              className={styles.icon}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (
    role === ROLES.juror &&
    jurorGroup?.jurorGroupPhase !== JUROR_GROUP_PHASES.discussionPhase
  ) {
    return (
      <div className={styles.statusContainer}>
        {renderJurorIcon(
          currentJurorEvaluation?.totalPoints,
          currentJurorEvaluation?.status
        )}
      </div>
    );
  }

  const handleNameClick = (e, jurorId) => {
    e.stopPropagation();
    onNameClick(
      `../entry/${submission.submissionId}${jurorId ? `/${jurorId}` : ''} `,
      submission
    );
  };

  const renderName = (juror) => {
    return matchRoles(role, [ROLES.chairPerson, ROLES.staff]) ? (
      <span
        role="button"
        className={styles.link}
        onClick={(e) => {
          handleNameClick(e, juror.jurorId);
        }}
      >
        {juror.firstname} {juror.lastname}
      </span>
    ) : (
      <>
        <span>
          {juror.firstname} {juror.lastname}
        </span>
      </>
    );
  };

  return (
    <div className={combine(styles.statusContainer, className)}>
      {submission.evaluations
        .filter(
          (evaluation) =>
            !matchRoles(evaluation.role, [ROLES.chairPerson, ROLES.staff])
        )
        .map((juror) => (
          <div
            key={juror.jurorId}
            className={combine(
              styles.statusItem,
              submission?.jurorId == juror?.jurorId &&
                styles.highlightImpersonation
            )}
          >
            <div className={styles.rightAligned}>
              {renderIcon(score, juror.status, juror.hasZeroValues)}
              {renderName(juror)}
            </div>
            <span>{(!isAdminView && juror?.totalPoints) || 0}</span>
          </div>
        ))}
    </div>
  );
};

StatusColumn.propTypes = {
  submission: PropTypes.object,
  isAdminView: PropTypes.bool,
  onNameClick: PropTypes.func,
};

StatusColumn.defaultProps = {
  submission: {},
  isAdminView: false,
  onNameClick: () => {},
};

export default StatusColumn;
