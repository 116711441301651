import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  faFile,
  faPen,
  faTrash,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useTranslation } from 'react-i18next';
import InformationMedia from '../../models/informationMedia';
import InformationMaterialDialog from '../../modules/informationMaterialDialog';
import Button, { ButtonTypes } from '../button';
import Dialog from '../dialog';
import styles from './styles.module.scss';

const RowActions = ({ item, setEditDialog, setDeleteDialog }) => {
  const editItem = () => {
    setEditDialog(item);
  };

  const deleteItem = () => {
    setDeleteDialog(item);
  };

  return (
    <div className={styles.actions}>
      <FontAwesomeIcon
        onClick={editItem}
        className={styles.action}
        icon={faPen}
      />
      <FontAwesomeIcon
        onClick={deleteItem}
        className={styles.action}
        icon={faTrash}
      />
    </div>
  );
};

const InformationMaterialTable = ({
  informationMedia,
  setInformationMedia,
}) => {
  const [editDialog, setEditDialog] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(null);

  const { t } = useTranslation();

  const handleEdit = (editedInformationMedia) => {
    InformationMedia.update(
      editedInformationMedia.sessionId,
      editedInformationMedia.values
    ).then((updatedInformationMedia) => {
      setInformationMedia((prev) =>
        prev.map((i) =>
          i.id === updatedInformationMedia.id ? updatedInformationMedia : i
        )
      );
      setEditDialog(null);
    });
  };

  const handleDelete = () => {
    InformationMedia.delete(deleteDialog.sessionId, deleteDialog.id).then(
      () => {
        setInformationMedia((prev) =>
          prev.filter((item) => item.id !== deleteDialog.id)
        );
      }
    );
    setDeleteDialog(null);
  };

  const handleOrderChange = (direction, changeItem) => {
    const reorderedMedia = [...informationMedia];

    const changeItemIndex = reorderedMedia.findIndex(
      (item) => item.id === changeItem.id
    );

    if (reorderedMedia.length === 1) return;
    if (direction === 'up' && changeItemIndex === 0) return;
    if (direction === 'down' && changeItemIndex === reorderedMedia.length - 1)
      return;

    let newIndex =
      direction === 'up' ? changeItemIndex - 1 : changeItemIndex + 1;

    reorderedMedia[changeItemIndex] = reorderedMedia[newIndex];
    reorderedMedia[newIndex] = changeItem;

    InformationMedia.reorder(changeItem.sessionId, reorderedMedia).then(
      (items) => {
        setInformationMedia(items);
      }
    );
  };

  const columns = [
    {
      dataField: 'title',
      text: t('sessions.informationMaterial.table.title'),
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'attachment',
      text: t('sessions.informationMaterial.table.attachment'),
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (_, row) => {
        const hasPdfs = row.pdfs.length > 0;
        const hasVideos = row.videos.length > 0;

        return (
          <div className={styles.attachmentIcons}>
            {hasPdfs && <FontAwesomeIcon icon={faFile} />}
            {hasVideos && <FontAwesomeIcon icon={faVideo} />}
          </div>
        );
      },
    },
    {
      dataField: 'published',
      text: t('sessions.informationMaterial.table.status.title'),
      headerStyle: () => {
        return { width: '35%' };
      },
      formatter: (cell) => {
        return t(
          `sessions.informationMaterial.table.status.${
            cell ? 'published' : 'inactive'
          }`
        );
      },
    },
    {
      dataField: 'order',
      text: t('sessions.informationMaterial.table.order'),
      classes: () => styles.orderCell,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (_, row) => {
        return (
          <div className={styles.orderButtons}>
            <button
              onClick={() => {
                handleOrderChange('up', row);
              }}
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </button>
            <button
              onClick={() => {
                handleOrderChange('down', row);
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
          </div>
        );
      },
    },
    {
      dataField: 'actions',
      headerAlign: 'right',
      classes: () => styles.actionsCell,
      text: '',
      formatter: (_, row) => {
        return (
          <RowActions
            item={row}
            setEditDialog={setEditDialog}
            setDeleteDialog={setDeleteDialog}
          />
        );
      },
    },
  ];

  return (
    <>
      {editDialog && (
        <InformationMaterialDialog
          onClose={() => {
            setEditDialog(null);
          }}
          onSubmit={handleEdit}
          informationMaterial={editDialog}
          refetchItem
        />
      )}
      {deleteDialog && (
        <Dialog
          noNav
          title={
            <p>
              Delete <b>{`${deleteDialog.title}`}</b>
            </p>
          }
          body={
            <p>
              {t(
                'sessions.informationMaterial.table.actions.delete.description'
              )}
            </p>
          }
          footer={
            <div className={styles.dialogFooter}>
              <Button
                className={styles.button}
                type={ButtonTypes.SECONDARY}
                text={t(
                  'sessions.informationMaterial.table.actions.delete.buttons.cancel'
                )}
                onClick={() => {
                  setDeleteDialog(null);
                }}
              />
              <Button
                type={ButtonTypes.PRIMARY}
                text={t(
                  'sessions.informationMaterial.table.actions.delete.buttons.delete'
                )}
                onClick={handleDelete}
              />
            </div>
          }
          onClose={() => {
            setDeleteDialog(null);
          }}
        />
      )}
      <BootstrapTable
        keyField="id"
        data={informationMedia}
        columns={columns}
        wrapperClasses={styles.informationTable}
        rowClasses={(row) => (!row.published ? styles.inactive : '')}
        noDataIndication={() => (
          <span className={styles.emptyMessage}>
            {t('sessions.informationMaterial.table.noInformationMaterial')}
          </span>
        )}
      />
    </>
  );
};

InformationMaterialTable.propTypes = {
  informationMedia: PropTypes.array,
  setInformationMedia: PropTypes.func,
};

InformationMaterialTable.defaultProps = {
  informationMaterial: [],
  setInformationMedia: () => null,
};

export default InformationMaterialTable;
