import { faArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonTypes } from '../../../../components/button';
import EntryDetailFooter from '../../../../components/entryDetail/entryDetailFooter';
import EntryDetailHeader from '../../../../components/entryDetail/entryDetailHeader';
import JurorEvaluation from '../../../../components/entryDetail/entryEvaluation';
import EntryNavigation from '../../../../components/entryDetail/entryNavigation';
import Loading from '../../../../components/loading';
import { ROLES } from '../../../../config/permissions/roles';
import { isAwardType } from '../../../../helpers/awardTypes';
import { useJuror } from '../../../../hooks/useAuth';
import { AWARD_TYPES } from '../../../../models/award';
import { SESSION_TYPES } from '../../../../models/session';
import Submission from '../../../../models/submission';
import JurorGroupJuror from '../../../../models/jurorGroupJuror';
import { matchRoles } from '../../../../helpers/roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

const EntryDetail = () => {
  const { entryId, jurorId: impersonatingJurorId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { juror, jurorGroup, session, role, isPreview } = useJuror();
  const [entryLoading, setEntryLoading] = useState(true);
  const [submission, setSubmission] = useState(null);
  const [juryStatement, setJuryStatement] = useState('');
  const [savedJuryStatement, setSavedJuryStatement] = useState('');
  const [showJurorStatement, setShowJurorStatement] = useState(false);
  const [impersonatedJuror, setImpersonatedJuror] = useState(null);

  const jurorGroupId = state?.jurorGroupId ?? jurorGroup?.jurorGroupId;

  const isReadOnly =
    (state?.jurorGroupId &&
      !juror?.jurorGroups?.some((jg) => jg.jurorGroupId === jurorGroupId)) ||
    (matchRoles(role, [ROLES.groupLeader, ROLES.juror]) &&
      impersonatingJurorId &&
      impersonatingJurorId !== juror?.jurorId);

  useEffect(() => {
    if (entryId && juror && jurorGroup && session) {
      updateEntry();
    }
  }, [entryId, juror, jurorGroup, impersonatingJurorId, session]);

  useEffect(() => {
    setShowJurorStatement(false);
  }, [entryId]);

  const updateEntry = async () => {
    setEntryLoading(true);

    let data = await Submission.fetch(
      jurorGroupId,
      entryId,
      impersonatingJurorId
    );

    if (!data) {
      return navigate(`/juror/entries`);
    }

    // Fetch info for currently impersonated juror
    if (
      matchRoles(role, [ROLES.chairPerson, ROLES.staff]) &&
      impersonatingJurorId
    ) {
      JurorGroupJuror.fetchAll(jurorGroupId).then((jurors) => {
        const juror = jurors.find((j) => j.jurorId == impersonatingJurorId);
        setImpersonatedJuror(juror);
      });
    }

    if (
      (isAwardType(AWARD_TYPES.IF_DA, session) &&
        session?.sessionType === SESSION_TYPES.FINAL_JURY &&
        role === ROLES.chairPerson) ||
      (isAwardType(AWARD_TYPES.IF_DTA, session) && role === ROLES.staff)
    ) {
      const allEvaluations = await Submission.getEvaluations(entryId);
      await Promise.all(
        allEvaluations.map((evaluation) =>
          Submission.fetch(jurorGroupId, entryId, evaluation.jurorId).then(
            (res) => ({ ...res, jurorId: evaluation.jurorId })
          )
        )
      ).then((values) => {
        const submissionWithExternalNomination = values?.find(
          (submission) =>
            submission.suggestedForNomination &&
            submission.jurorId !== juror?.jurorId
        );
        data = {
          ...data,
          suggestedForNominationExternal: !!submissionWithExternalNomination,
          suggestedForNominationExternalJurorId:
            submissionWithExternalNomination?.jurorId,
        };
      });
    }

    const statement = await Submission.fetchJuryStatement(
      jurorGroupId,
      entryId
    );
    setJuryStatement(statement || '');
    setSavedJuryStatement(statement || '');

    setSubmission(data);
    setEntryLoading(false);
  };

  const handleSubmissionUpdate = async (updatedSubmission) => {
    if (
      submission.suggestedForNomination !==
      updatedSubmission.suggestedForNomination
    ) {
      if (updatedSubmission.suggestedForNomination) {
        const statement = await Submission.fetchJuryStatement(
          jurorGroupId,
          entryId
        );
        setJuryStatement(statement);
      } else {
        setJuryStatement('');
      }
    }

    setSubmission({
      ...updatedSubmission,
    });
  };

  const handleEvaluationChange = (points, criterionId) => {
    setSubmission((prev) => {
      return {
        ...prev,
        points: prev.points.map((item) => {
          if (item.criterion.id === criterionId) {
            return {
              ...item,
              points,
            };
          }
          return item;
        }),
      };
    });
  };

  const onJurorStatementChange = async (statement) => {
    if (statement) {
      await Submission.setJuryStatement({
        juryStatement: statement,
        jurorGroupId: jurorGroupId,
        submissionId: entryId,
      });
      setSavedJuryStatement(statement);
    } else {
      await Submission.deleteJuryStatement({
        jurorGroupId: jurorGroupId,
        submissionId: entryId,
      });
      setSavedJuryStatement('');
    }
  };

  const debounceFn = useCallback(debounce(onJurorStatementChange, 300), [
    entryId,
  ]);

  function handleJurorStatementChange(event) {
    setJuryStatement(event?.target?.value);
    debounceFn(event.target.value);
  }

  return (
    <div className={styles.entry}>
      <EntryNavigation
        entryLoading={entryLoading}
        submission={submission}
        submissionsList={state?.submissionsList}
      />
      {entryLoading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          {isAwardType(AWARD_TYPES.IF_DA, session) &&
            session.sessionType == SESSION_TYPES.FINAL_JURY &&
            submission?.nominated && (
              <Container className={styles.goldAwardedContainer}>
                <Row>
                  <Col>
                    <h1>{t('evaluation.goldDecided')}</h1>
                    {!juryStatement && !showJurorStatement && (
                      <Button
                        className={styles.juryStatementButton}
                        type={ButtonTypes.SECONDARY}
                        onClick={() => setShowJurorStatement(true)}
                        icon={faArrowRight}
                        iconBold={faArrowRight}
                        text={t('evaluation.writeAJuryStatement')}
                        htmlType="button"
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          {isAwardType(AWARD_TYPES.IF_DA, session) &&
            session.sessionType === SESSION_TYPES.FINAL_JURY &&
            (juryStatement || showJurorStatement) && (
              <div className={styles.juryStatementWrapper}>
                <Container>
                  <Row>
                    <Col className={styles.juryStatement}>
                      <h3>{t('evaluation.juryStatement')}</h3>
                      <textarea
                        readOnly={
                          session?.sessionType !== SESSION_TYPES.FINAL_JURY ||
                          isPreview
                        }
                        onChange={handleJurorStatementChange}
                      >
                        {juryStatement}
                      </textarea>
                      {!!juryStatement && (
                        <div className={styles.statusContainer}>
                          {juryStatement !== savedJuryStatement ? (
                            <Loading />
                          ) : (
                            <FontAwesomeIcon icon={faCheck} />
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          <div className={styles.contentWrapper}>
            <EntryDetailHeader
              submission={submission}
              handleSubmissionUpdate={handleSubmissionUpdate}
            />
          </div>
          <JurorEvaluation
            onEvaluationChange={handleEvaluationChange}
            submission={submission}
            updateSubmissions={handleSubmissionUpdate}
            isReadOnly={isReadOnly}
            impersonatedJuror={impersonatedJuror}
          />
          <div className={styles.contentWrapper}>
            <EntryDetailFooter submission={submission} />
          </div>
          <EntryNavigation
            entryLoading={entryLoading}
            submission={submission}
            submissionsList={state?.submissionsList}
          />
        </>
      )}
    </div>
  );
};

export default EntryDetail;
