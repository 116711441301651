import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../components/dropdown';
import EntriesTable from '../../../../components/entriesTable';
import Session from '../../../../models/session';
import Hero from '../../../../modules/hero';
import SubmissionOverviewModel from '../../../../models/submissionOverview';
import styles from './styles.module.scss';
import { Container } from 'react-bootstrap';
import Pill from '../../../../components/pill';
import SearchField from '../../../../components/searchField';
import SortDropdown from '../../../../components/sortDropdown';
import useSubmissionsFilter from '../../../../hooks/useSubmissionsFilter';
import { AWARD_TYPES } from '../../../../models/award';
import { EVALUATION_STATUS } from '../../../../models/submission';

const filterDecidedEvaluations = (evaluation) =>
  evaluation.status === EVALUATION_STATUS.decided ||
  evaluation.status === EVALUATION_STATUS.decidedByChairperson;

const SubmissionOverview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState(0);
  const [sessions, setSessions] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [visibleSubmissions, setVisibleSubmissions] = useState([]);
  const { t } = useTranslation();
  const {
    filterParams,
    filterOptions,
    handleSortChange,
    handleFilterReset,
    onFulltextSearch,
    onSort,
  } = useSubmissionsFilter({
    submissions,
    setVisibleSubmissions,
    isAdminView: true,
  });
  const selectedAwardType = useMemo(
    () => sessions.find((s) => s.value == session)?.awardType,
    [sessions, session]
  );

  useEffect(async () => {
    const sessionData = await Session.fetchAll({ archived: false }).then(
      (data) =>
        data.map((session) => ({
          value: session.sessionId,
          text: session.sessionName,
          awardType: session.awardType,
        }))
    );

    setSessions(sessionData);
  }, []);

  useEffect(() => {
    if (!session || selectedAwardType !== AWARD_TYPES.IF_SIP) return;
    setIsLoading(true);
    SubmissionOverviewModel.fetchAllSubmissionsForSession({
      sessionId: session,
    }).then((submissions) => {
      const mappedSubmission = submissions.map((submission) => {
        const decided = submission.evaluations.filter(filterDecidedEvaluations);
        return {
          ...submission,
          averageScore: decided.length
            ? decided.reduce((prev, cur) => prev + cur.totalPoints, 0) /
              decided.length
            : 0,
        };
      });
      setSubmissions(mappedSubmission);
      setIsLoading(false);
    });
  }, [session, setSubmissions]);

  const handleSessionChange = (val) => {
    setSession(val);
  };

  const handleSubmissionUpdate = (updatedSubmission) => {
    setSubmissions((prev) =>
      prev.map((submission) =>
        submission.submissionId === updatedSubmission.submissionId
          ? updatedSubmission
          : submission
      )
    );
  };

  return (
    <div>
      <Hero
        title={t('sessions.submissionOverview.title')}
        rightSection={
          <Dropdown
            options={sessions}
            value={session}
            onChange={handleSessionChange}
            label={t('jurors.overview.dropdownLabel')}
            className={'w-100'}
          />
        }
      />
      {selectedAwardType !== AWARD_TYPES.IF_SIP ? (
        <div className={styles.notAvailableContainer}>
          <h2>{t('sessions.submissionOverview.notAvailable')}</h2>
        </div>
      ) : (
        <Container>
          <div className={styles.toolbar}>
            <div className={styles.filterPills}>
              {filterParams.data.map((filterParam) => (
                <Pill
                  key={filterParam}
                  handleClick={() => handleFilterReset('filterBy')}
                  text={
                    filterParam.split('__')[1] ||
                    filterOptions.find((item) => item.key === filterParam)?.text
                  }
                />
              ))}
              {filterParams.order && (
                <Pill
                  handleClick={() => handleFilterReset('order')}
                  text={t(`jurorEntries.filter.order.${filterParams.order}`)}
                />
              )}
            </div>
            <SearchField
              value={filterParams?.search}
              onChange={onFulltextSearch}
            />
            <SortDropdown
              activeOption={filterParams}
              options={filterOptions}
              onChange={handleSortChange}
            />
          </div>
          <EntriesTable
            submissions={visibleSubmissions}
            updateSubmission={handleSubmissionUpdate}
            loading={isLoading}
            isAdminView
            onSort={onSort}
            awardType={sessions.find((s) => s.value == session)?.awardType}
          />
        </Container>
      )}
    </div>
  );
};

export default SubmissionOverview;
