import moment from "moment";

class Meeting {
  constructor(
    id,
    name,
    url,
    startDate,
    endDate,
    mandatory,
    jurorGroupId,
    jurorGroupName
  ) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.startDate = startDate;
    this.endDate = endDate;
    this.mandatory = mandatory;
    this.jurorGroupId = jurorGroupId;
    this.jurorGroupName = jurorGroupName;
  }

  static from = (meeting) => {
    return new Meeting(
      meeting.id,
      meeting.name,
      meeting.url ? meeting.url : '',
      moment(meeting.startDate).parseZone(),
      meeting.endDate,
      meeting.mandatory,
      meeting.jurorGroupId,
      meeting.jurorGroupName
    );
  };

  static of = (meetings) => {
    return meetings.map((meeting) => Meeting.from(meeting));
  };

  static transformForAPI = (meeting) => ({
    name: meeting.name,
    url: meeting.url ? meeting.url : '',
    startDate: meeting.startDate,
    endDate: meeting.endDate,
    mandatory: meeting.mandatory,
    jurorGroupId: meeting.jurorGroupId,
    jurorGroupName: meeting.jurorGroupName,
  });

  static fetchAllForSession = async (sessionId) => {
    if (!sessionId) return [];

    return fetch(`/if-api/jury-v2/session/${sessionId}/meetings`)
      .then((response) => response.json())
      .then((meetings) => Meeting.of(meetings))
      .catch((err) => {
        console.error(err);
      });
  };

  static fetchAllForJurorGroup = async (jurorGroupId) => {
    if (!jurorGroupId) {
      return null;
    }

    return fetch(`/if-api/jury-v2/jurorGroup/${jurorGroupId}/meetings`)
      .then((response) => response.json())
      .then((meetings) => Meeting.of(meetings))
      .catch((err) => {
        console.error(err);
      });
  };

  static create = async (sessionId, meeting) => {
    const newMeeting = Meeting.transformForAPI(meeting);

    return fetch(`/if-api/jury-v2/session/${sessionId}/meetings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newMeeting),
    })
      .then((response) => response.json())
      .then((meeting) => Meeting.from(meeting))
      .catch((err) => console.error(err));
  };

  static update = async (sessionId, meeting) => {
    const updatedMeeting = Meeting.transformForAPI(meeting);

    return fetch(`/if-api/jury-v2/session/${sessionId}/meeting/${meeting.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedMeeting),
    })
      .then((response) => response.json())
      .then((meeting) => Meeting.from(meeting))
      .catch((err) => console.error(err));
  };

  static delete = async (sessionId, meetingId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/meeting/${meetingId}`, {
      method: 'DELETE',
    }).catch((err) => console.error(err));
  };
}

export default Meeting;

const mock = {
  id: 123,
  name: 'Very important meeting',
  link: 'Some Teams links, which will probably not work',
  start: '2017-07-21T17:30:00Z',
  end: '2017-07-21T18:00:00Z',
  description: 'This meeting is about playing jury',
  mandatory: true,
  jurorGroups: [66],
};

export const mockMeeting = Meeting.from(mock);
