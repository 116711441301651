import {
  faFolderClosed,
  faPen,
  faPlay,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Session from '../../models/session';
import Button, { ButtonTypes } from '../button';
import Dialog from '../dialog';
import Loading from '../loading';
import styles from './styles.module.scss';

export const TABLE_TYPES = {
  overview: 'overview',
  archive: 'archive',
};

const initialDialogState = { isActive: false, session: null, type: '' };

const RowActions = ({ session, setDialog, type, sessionsCount }) => {
  const isArchive = type === TABLE_TYPES.archive;
  const navigate = useNavigate();
  const edit = () =>
    navigate(`/admin/session/${session.sessionId}`, {
      state: { sessionsCount, type },
    });

  const archive = () => {
    setDialog({
      isActive: true,
      session,
      type: 'archive',
    });
  };

  const unarchive = () => {
    setDialog({
      isActive: true,
      session,
      type: 'unarchive',
    });
  };

  const deleteSession = () => {
    setDialog({ isActive: true, session, type: 'delete' });
  };

  return (
    <div className={styles.actions}>
      <div className={styles.actionContainer}>
        <FontAwesomeIcon
          className={styles.action}
          icon={faPen}
          onClick={edit}
        />
      </div>
      {isArchive && (
        <div className={styles.actionContainer}>
          <FontAwesomeIcon
            className={styles.action}
            icon={faPlay}
            onClick={unarchive}
          />
        </div>
      )}
      <div className={styles.actionContainer}>
        {isArchive ? (
          <FontAwesomeIcon
            className={styles.action}
            icon={faTrash}
            onClick={deleteSession}
          />
        ) : (
          <FontAwesomeIcon
            className={styles.action}
            icon={faFolderClosed}
            onClick={archive}
          />
        )}
      </div>
    </div>
  );
};

const SessionsTable = ({ sessions, loading, setSessions, type }) => {
  const [dialog, setDialog] = useState(initialDialogState);

  const { t } = useTranslation();

  const archive = (sessionId) => {
    Session.archive(sessionId).then(() => {
      setSessions((prev) =>
        prev.filter((session) => session.sessionId !== sessionId)
      );
      setDialog(initialDialogState);
    });
  };

  const unarchive = (sessionId) => {
    Session.unarchive(sessionId).then(() => {
      setSessions((prev) =>
        prev.filter((session) => session.sessionId !== sessionId)
      );
      setDialog(initialDialogState);
    });
  };

  const deleteSession = (sessionId) => {
    Session.delete(sessionId).then(() => {
      setSessions((prev) =>
        prev.filter((session) => session.sessionId !== sessionId)
      );
      setDialog(initialDialogState);
    });
  };

  const triggerAction = () => {
    if (dialog.type === 'archive') {
      archive(dialog.session.sessionId);
    }
    if (dialog.type === 'unarchive') {
      unarchive(dialog.session.sessionId);
    }
    if (dialog.type === 'delete') {
      deleteSession(dialog.session.sessionId);
    }
  };

  const getSessionStatus = (session) => {
    if (!session) return '';

    if (moment(session.startDate).isAfter(moment())) {
      return t('sessions.overview.table.columns.status.coming');
    }

    if (moment(session.endDate).isAfter(moment())) {
      return t('sessions.overview.table.columns.status.active');
    }

    return t('sessions.overview.table.columns.status.closed');
  };

  const getMaxPointsForSession = (session) => {
    return session.maxPointsPerCriterion * session.criteria.length;
  };

  const getSessionIsClosed = (session) =>
    moment(session.endDate).isBefore(moment());

  const columns = [
    {
      dataField: 'sessionName',
      text: 'Award',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      sortCaret: (order) => {
        return (
          <FontAwesomeIcon
            className={styles.sortIcon}
            icon={order === 'desc' ? faCaretDown : faCaretUp}
          />
        );
      },
    },
    {
      dataField: 'sessionType',
      text: 'Phase',
      classes: () => styles.phaseCell,
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (cell, row) => {
        return (
          <>
            <span className={styles.phase}>
              {cell ? t(`sessionTypes.${cell}`) : ''}
            </span>
            {!getSessionIsClosed(row) && (
              <span className={styles.maxPoints}>
                {getMaxPointsForSession(row)}
              </span>
            )}
          </>
        );
      },
    },
    {
      dataField: 'startDate',
      text: 'From',
      headerStyle: () => {
        return { width: '10%' };
      },
      type: 'date',
      formatter: (cell) => {
        return moment(cell).format('DD.MM.YYYY');
      },
    },
    {
      dataField: 'endDate',
      text: 'To',
      headerStyle: () => {
        return { width: '15%' };
      },
      type: 'date',
      formatter: (cell) => {
        if (!cell) return '-';
        return moment(cell).format('DD.MM.YYYY');
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      classes: (cell) => '',
      headerStyle: () => {
        return { width: '15%' };
      },
      formatter: (_, row) => getSessionStatus(row),
    },
    {
      dataField: 'actions',
      headerAlign: 'right',
      classes: () => styles.actionsCell,
      text: '',
      formatter: (_, row) => {
        return (
          <RowActions
            session={row}
            setDialog={setDialog}
            type={type}
            sessionsCount={sessions?.length}
          />
        );
      },
    },
  ];

  return (
    <>
      {dialog.isActive && (
        <Dialog
          title={
            <Trans
              i18nKey={`sessions.overview.table.actions.${dialog.type}.title`}
              components={{ bold: <b /> }}
              values={{
                sessionName: dialog.session.sessionName,
              }}
            />
          }
          onClose={() => {
            setDialog(initialDialogState);
          }}
          noNav
          body={
            <p className={styles.text}>
              <Trans
                i18nKey={`sessions.overview.table.actions.${dialog.type}.description`}
                components={{ bold: <b /> }}
              />
            </p>
          }
          footer={
            <div className={styles.dialogFooter}>
              <Button
                type={ButtonTypes.SECONDARY}
                text={t('sessions.overview.table.actions.cancel')}
                onClick={() => {
                  setDialog(initialDialogState);
                }}
              />
              <Button
                className={styles.button}
                type={ButtonTypes.PRIMARY}
                text={t(
                  `sessions.overview.table.actions.${dialog.type}.button`
                )}
                onClick={triggerAction}
              />
            </div>
          }
        />
      )}
      <BootstrapTable
        keyField="sessionId"
        data={sessions}
        columns={columns}
        wrapperClasses={styles.sessionsTable}
        rowClasses={(row) =>
          moment(row.endDate).isBefore(moment()) || type === TABLE_TYPES.archive
            ? styles.closed
            : ''
        }
        noDataIndication={() => (
          <>
            {loading ? (
              <Loading />
            ) : (
              <span className={styles.emptyMessage}>
                {t('sessions.overview.table.noSessions')}
              </span>
            )}
          </>
        )}
      />
    </>
  );
};

SessionsTable.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  setSessions: PropTypes.func,
  type: PropTypes.string,
};

SessionsTable.defaultProps = {
  sessions: [],
  loading: false,
  setSessions: () => null,
  type: '',
};

export default SessionsTable;
