class Auth {
  constructor(jurorId, sessionId, loginUuid) {
    this.jurorId = jurorId;
    this.sessionId = sessionId;
    this.loginUuid = loginUuid;
  }

  static from = (auth) => {
    return new Auth(auth.jurorId, auth.sessionId, auth.loginUuid);
  };

  static fetch = async () => {
    return fetch(`/if-api/jury-v2/auth`)
      .then((response) => response.json())
      .then((auth) => Auth.from(auth))
      .catch((err) => console.error(err));
  };

  static logout = async () => {
    return fetch('/if-api/jury-v2/logout')
      .then((response) => response.ok)
      .catch((err) => console.error(err));
  };
}

export default Auth;
