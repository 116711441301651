import { faFolderClosed, faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  faFolderClosed as faFolderClosedBold,
  faPlus as faPlusBold,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonTypes } from '../../../../components/button';
import SessionsTable, {
  TABLE_TYPES,
} from '../../../../components/sessionsTable';
import Session from '../../../../models/session';
import Hero from '../../../../modules/hero';
import styles from './styles.module.scss';

const SessionOverview = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    Session.fetchAll({ archived: false }).then((sessions) => {
      setSessions(sessions);
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.sessionsOverview}>
      <Hero
        title={
          <Trans
            i18nKey="sessions.overview.title"
            components={{ bold: <b /> }}
          />
        }
      />
      <Container className={'py-4'}>
        <div className={styles.topButtonContainer}>
          <Button
            text={'Archive'}
            type={ButtonTypes.SECONDARY}
            icon={faFolderClosed}
            iconBold={faFolderClosedBold}
            onClick={() => navigate('../archived')}
          />
        </div>
        <SessionsTable
          sessions={sessions}
          loading={loading}
          setSessions={setSessions}
          type={TABLE_TYPES.overview}
        />
        <Button
          text={t('sessions.overview.createSession')}
          type={ButtonTypes.PRIMARY}
          icon={faPlus}
          iconBold={faPlusBold}
          onClick={() => navigate('/admin/sessions/overview/create')}
        />
      </Container>
    </div>
  );
};

export default SessionOverview;
