import pt from 'prop-types';

const SIPBadge = ({ year, className }) => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 226.32 66.71"
      className={className}
      dangerouslySetInnerHTML={{
        __html: `
          <defs>
            <style>
              ${`.st0{fill:#FFFFFF;}
              .st1{fill:#E7000E;}
              .st2{clip-path:url(#SVGID_00000178910334590350638940000016630696800536616890_);fill:#FFFFFF;}
              .st3{fill:none;stroke:#E7000E;stroke-width:1.5;}
              .st4{clip-path:url(#SVGID_00000021835171458437146080000004107673754784914839_);fill:#FFFFFF;}
              .text{font-size:18.8px;font-family:SuisseIntl;font-weight:400;fill:#E7000E;stroke:#E7000E}`}
            </style>
            <rect id="SVGID_1_" x="0.18" y="0.03" width="224.93" height="66.34"/>
          </defs>
          <rect x="0.01" y="0.09" class="st0" width="226.3" height="66.35"/>
          <rect x="3.54" y="3.3" class="st1" width="59.87" height="59.81"/>
          <rect x="16.16" y="28.31" class="st0" width="9.45" height="22.06"/>
          <g>
            <defs>
              <rect id="SVGID_1_" x="0.18" y="0.03" width="224.93" height="66.34"/>
            </defs>
            <clipPath id="SVGID_00000003801386935335925420000016917130283818736831_">
              <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
            </clipPath>
            <path style="clip-path:url(#SVGID_00000003801386935335925420000016917130283818736831_);fill:#FFFFFF;" d="M15.52,20.43
              c0-2.96,2.4-5.36,5.36-5.36c2.96,0,5.36,2.4,5.36,5.36c0,2.96-2.4,5.36-5.36,5.36C17.91,25.78,15.52,23.39,15.52,20.43"/>
          </g>
          <rect x="41.35" y="15.7" class="st0" width="9.45" height="9.45"/>
          <rect x="28.75" y="15.7" class="st0" width="9.45" height="34.66"/>
          <rect x="41.35" y="28.31" class="st0" width="9.45" height="9.45"/>
          <rect x="67.75" y="4.04" class="st0" width="152.91" height="58.31"/>
          <rect x="67.75" y="4.04" class="st3" width="154.05" height="58.31"/>
          <g>
            <path class="st1" d="M73.36,19.03c0-2.92,2.91-4.2,5.43-4.2c2.82,0,5.23,1.76,5.36,4.54h-2.59c-0.21-1.7-1.6-2.26-2.88-2.26
              c-1.24,0-2.63,0.5-2.63,1.8c0,1.02,0.81,1.58,1.9,1.8l1.79,0.34c2.22,0.42,4.66,1.36,4.66,4.36c0,2.98-2.78,4.44-5.53,4.44
              c-3.35,0-5.56-1.92-5.81-5.04h2.59c0.3,2.12,1.69,2.76,3.31,2.76c1.2,0,2.73-0.46,2.73-2.02c0-1.24-1.07-1.7-2.52-1.98l-1.73-0.34
              C75.22,22.77,73.36,21.55,73.36,19.03z"/>
            <path class="st1" d="M85.58,22.33c0-4.5,2.61-7.5,6.67-7.5s6.67,3,6.67,7.5s-2.61,7.52-6.67,7.52S85.58,26.83,85.58,22.33z
               M96.14,22.33c0-3.4-1.77-5.22-3.89-5.22c-2.12,0-3.89,1.82-3.89,5.22c0,3.4,1.77,5.24,3.89,5.24
              C94.37,27.57,96.14,25.73,96.14,22.33z"/>
            <path class="st1" d="M106.89,29.85c-4.04,0-6.37-2.96-6.37-7.58c0-4.58,2.48-7.44,6.45-7.44c3.2,0,5.38,1.94,5.83,4.96h-2.56
              c-0.51-2.02-1.79-2.68-3.29-2.68c-2.07,0-3.65,1.8-3.65,5.16c0,3.46,1.58,5.3,3.55,5.3c1.62,0,2.95-0.78,3.38-2.74h2.56
              C112.44,27.75,110.17,29.85,106.89,29.85z"/>
            <path class="st1" d="M114.51,29.59v-14.5h2.69v14.5H114.51z"/>
            <path class="st1" d="M126.01,15.09l5.09,14.5h-2.91l-0.96-2.94h-5.3l-0.96,2.94h-2.74l5.08-14.5H126.01z M126.47,24.31l-1.88-5.72
              l-1.88,5.72H126.47z"/>
            <path class="st1" d="M141.66,27.27v2.32h-9.32v-14.5h2.69v12.18H141.66z"/>
            <path class="st1" d="M147.13,29.59v-14.5h2.69v14.5H147.13z"/>
            <path class="st1" d="M154.76,18.69v10.9h-2.5v-14.5h3.91l3.31,11.3l3.21-11.3h3.95v14.5h-2.5v-10.9l-3.35,10.9h-2.67L154.76,18.69z
              "/>
            <path class="st1" d="M179.74,19.89c0,3.7-2.78,4.6-5.92,4.6h-2.05v5.1h-2.69v-14.5h4.66C176.55,15.09,179.74,15.51,179.74,19.89z
               M171.77,22.27h2.22c1.67,0,2.99-0.44,2.99-2.36c0-2.38-1.71-2.58-3.18-2.58h-2.03V22.27z"/>
            <path class="st1" d="M186.25,15.09l5.09,14.5h-2.91l-0.96-2.94h-5.3l-0.96,2.94h-2.75l5.08-14.5H186.25z M186.7,24.31l-1.88-5.72
              l-1.88,5.72H186.7z"/>
            <path class="st1" d="M197.81,29.85c-4.04,0-6.37-2.96-6.37-7.58c0-4.58,2.48-7.44,6.45-7.44c3.2,0,5.38,1.94,5.83,4.96h-2.56
              c-0.51-2.02-1.79-2.68-3.29-2.68c-2.07,0-3.65,1.8-3.65,5.16c0,3.46,1.58,5.3,3.55,5.3c1.62,0,2.95-0.78,3.38-2.74h2.56
              C203.36,27.75,201.08,29.85,197.81,29.85z"/>
            <path class="st1" d="M204.11,17.41v-2.32h11.09v2.32h-4.21v12.18h-2.69V17.41H204.11z"/>
            <path class="st1" d="M84.47,41.89c0,3.7-2.78,4.6-5.92,4.6H76.5v5.1h-2.69v-14.5h4.66C81.27,37.09,84.47,37.51,84.47,41.89z
               M76.5,44.27h2.22c1.67,0,2.99-0.44,2.99-2.36c0-2.38-1.71-2.58-3.18-2.58H76.5V44.27z"/>
            <path class="st1" d="M88.51,45.91v5.68h-2.69v-14.5h4.47c4.49,0,6.2,1.36,6.2,4.4c0,1.66-0.77,3.06-2.46,3.82l3.03,6.28h-2.99
              l-2.71-5.68H88.51z M88.51,43.69h2.22c2.09,0,3.01-0.76,3.01-2.18c0-1.68-1.32-2.18-3.14-2.18h-2.09V43.69z"/>
            <path class="st1" d="M98.55,51.59v-14.5h2.69v14.5H98.55z"/>
            <path class="st1" d="M106.16,49.15h7.54v2.44h-10.88v-2.24l7.37-9.84h-6.79v-2.42h10.09v2.24L106.16,49.15z"/>
            <path class="st1" d="M125.3,37.09v2.32h-7.24v3.6h6.84v2.32h-6.84v3.94h7.24v2.32h-9.93v-14.5H125.3z"/>
              <text class="text" transform="translate(130 51.2)">${year}</text>
          </g>
        `,
      }}
    />
  );
};

SIPBadge.propTypes = {
  year: pt.number,
  className: pt.string,
};

SIPBadge.defaultProps = {
  className: '',
  year: 2024,
};

export default SIPBadge;
