import { ROLES } from '../../config/permissions/roles';

class Juror {
  constructor(jurorId, firstname, lastname, numDecidedEvaluation, uuid, role, totalPointsFromAllDecidedEvaluations, totalEvaluationCount, decidedEvaluationCount) {
    this.jurorId = jurorId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.name = `${firstname} ${lastname}`;
    this.numDecidedEvaluation = numDecidedEvaluation;
    this.uuid = uuid;
    this.role = role;
    this.totalPointsFromAllDecidedEvaluations = totalPointsFromAllDecidedEvaluations;
    this.totalEvaluationCount = totalEvaluationCount;
    this.decidedEvaluationCount = decidedEvaluationCount;
  }

  // TODO: only for debugging
  updateRole = (role) => {
    this.role = role;

    return this;
  };

  static from = (juror) => {
    return new Juror(
      juror.jurorId,
      juror.firstname,
      juror.lastname,
      juror.numDecidedEvaluation,
      juror.uuid,
      juror.role || ROLES.juror,
      juror.totalPointsFromAllDecidedEvaluations,
      juror.totalEvaluationCount,
      juror.decidedEvaluationCount
    );
  };

  static of = (jurors) => {
    if (!jurors) return [];

    return jurors.map((juror) => Juror.from(juror));
  };
}

export default Juror;
