class JurorListJuror {
  constructor(
    jurorId,
    firstname,
    lastname,
    email,
    profilePictureUrl,
    countryIsoCode
  ) {
    this.jurorId = jurorId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.profilePictureUrl = profilePictureUrl;
    this.email = email;
    this.countryIsoCode = countryIsoCode;
  }

  static from = (juror) => {
    return new JurorListJuror(
      juror.jurorId,
      juror.firstname,
      juror.lastname,
      juror.email,
      juror.profilePictureUrl,
      juror.countryIsoCode
    );
  };

  static of = (jurors) => {
    if (!jurors) return [];

    return jurors.map((juror) => JurorListJuror.from(juror));
  };
}

class JurorList {
  constructor(jurors, offset, total, query) {
    this.jurors = jurors;
    this.offset = offset;
    this.total = total;
    this.limit = 100;
    this.query = query;
   }

  static from = (jurorList) => {
    const jurors = JurorListJuror.of(jurorList?.JurorFullData);

    return new JurorList(
      jurors,
      jurorList.currentOffset,
      jurorList.totalResult
    );
  };

  fetchNextPage = async () => {
    return fetch(
      `/if-api/jury-v2/jurors?limit=${this.limit}&offset=${
        this.jurors?.length
      }${this.query ? `&query=${this.query}` : ''}`
    )
      .then((response) => response.json())
      .then(({ JurorFullData: jurors }) => {
        this.jurors = [...this.jurors, ...jurors];

        return this;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  static fetchAll = async () => {
    return fetch(`/if-api/jury-v2/jurors?limit=100`)
      .then((response) => response.json())
      .then((jurorList) => JurorList.from(jurorList))
      .catch((err) => {
        console.error(err);
      });
  };

  static fetchQuery = async (searchQuery = '', limit = 100) => {
    return fetch(
      `/if-api/jury-v2/jurors?limit=${limit}&query=${searchQuery}&fields=firstname&fields=lastname&fields=email&fields=country`
    )
      .then((response) => response.json())
      .then((jurorList) => {
        const jl = JurorList.from(jurorList);
        jl.query = searchQuery;

        return jl;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  static of = (jurors) => {
    if (!jurors) return [];

    return jurors.map((juror) => JurorList.from(juror));
  };
}

export default JurorList;
