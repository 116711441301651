import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPlus as faPlusBold } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonTypes } from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import MeetingsTable from '../../../../components/meetingsTable';
import Meeting from '../../../../models/meeting';
import Session from '../../../../models/session';
import Hero from '../../../../modules/hero';
import styles from './styles.module.scss';

const Meetings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [sessionOptions, setSessionOptions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(-1);
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    Session.fetchAll({ archived: false }).then((sessions) => {
      const mappedSessions = sessions.map((session) => ({
        value: session.sessionId,
        text: session.sessionName,
      }));
      setSessionOptions(mappedSessions);
    });
  }, [setSessionOptions, setSelectedSessionId]);

  useEffect(() => {
    if (selectedSessionId < 0) return;
    Meeting.fetchAllForSession(selectedSessionId).then((meetings) =>
      setMeetings(meetings)
    );
  }, [selectedSessionId, setMeetings, state]);

  const handleSessionChange = (value) => {
    setSelectedSessionId(value);
  };

  const handleMeetingEdit = (meeting) => {
    Meeting.update(selectedSessionId, meeting).then((updatedMeeting) => {
      setMeetings((prev) =>
        prev.map((m) => (m.id === meeting.id ? updatedMeeting : m))
      );
    });
  };

  return (
    <>
      <div className={styles.meetings}>
        <Hero
          title={t('sessions.meetings.title')}
          rightSection={
            <Dropdown
              label={t('sessions.meetings.sessionDropdown')}
              options={sessionOptions}
              value={selectedSessionId}
              onChange={handleSessionChange}
            />
          }
        />

        <Container>
          <div className={styles.tableSection}>
            <MeetingsTable
              meetings={meetings}
              setMeetings={setMeetings}
              onMeetingEdit={handleMeetingEdit}
              sessionId={selectedSessionId}
            />
          </div>
          <div className={styles.createNewInformationSection}>
            <Button
              type={ButtonTypes.PRIMARY}
              icon={faPlus}
              iconBold={faPlusBold}
              onClick={() => {
                navigate('/admin/sessions/meetings/create', {
                  state: { sessionId: selectedSessionId },
                });
              }}
              text={t('sessions.meetings.buttons.createNew')}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Meetings;
