import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../components/accordion';
import ContactBlock from '../../../components/contactBlock';
import { useJuror } from '../../../hooks/useAuth';
import { useConfig } from '../../../hooks/useConfig';
import InformationMedia from '../../../models/informationMedia';
import Hero from '../../../modules/hero';
import { CONTACTS as ALL_CONTACTS } from '../../../config/contacts';

const Help = () => {
  const { t } = useTranslation();
  const { session, jurorGroup } = useJuror();
  const [informationMaterial, setInformationMaterial] = useState([]);
  const { HELP_CONTACT_INFO } = useConfig();

  const contacts = useMemo(() => {
    const { CONTACTS } = HELP_CONTACT_INFO;

    return Object.entries(ALL_CONTACTS).filter(([key]) => CONTACTS.includes(key)).map(([key, val]) => val);
  }, [HELP_CONTACT_INFO])

  const { renderDescription, renderContactBlock } =
  useMemo(() => {
    const { FIELDS } = HELP_CONTACT_INFO;
    return {
      renderDescription: FIELDS.includes('description'),
      renderContactBlock: FIELDS.includes('contactBlock'),
    };
  }, [HELP_CONTACT_INFO]);

  useEffect(() => {
    if (jurorGroup) {
      InformationMedia.jurorFetchAllForGroup(jurorGroup?.jurorGroupId).then(
        (infoMaterial) => {
          setInformationMaterial(infoMaterial);
        }
      );
    }
  }, [setInformationMaterial, jurorGroup]);

  return (
    <div>
      <Hero title={t('jurors.help.title')} />
      <Container className={'py-5'}>
        <Accordion content={informationMaterial} />
        <br />
        {renderDescription && <p>{t(`jurors.help.description.${session?.awardType}`)}</p>}
        {renderContactBlock && <ContactBlock
          title={t(`jurors.help.contactBlockTitle.${session?.awardType}`)}
          description={t(`jurors.help.description2.${session?.awardType}`)}
          contacts={contacts}
          className="mb-5 mt-4"
        />}
      </Container>
    </div>
  );
};

export default Help;
